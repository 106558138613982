import { Alert, Button, Form, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rejectionsActions } from "../../../config/actions/rejections/rejections.actions";
import rejectionsThunks from "../../../config/thunks/rejections/rejections.thunks";

const AddRejections = () => {
  const {
    modalOpen: visible,
    success,
    error,
    message: successMessage,
    loading,
    data,
  } = useSelector((state) => state.rejectionsState.addRejection);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //functions
  const closeModal = () => {
    dispatch(rejectionsActions.toggleRejectionsModal(false));
  };
  const onFinish = (values) => {
    dispatch(rejectionsThunks.addRejection({ ...values, ...data }));
  };
  //useEffects
  useEffect(() => {
    if (success && successMessage) {
      message.success(successMessage);
      form.resetFields();
    }
  }, [success, error, successMessage, form]);
  return (
    <div className="z-index-modal">
      <Modal
        zIndex={1000}
        footer={null}
        onCancel={closeModal}
        title="REJECTIONS"
        visible={visible}
      >
        {!success && error && <Alert showIcon type="error" message={error} />}
        <Form layout="vertical" onFinish={onFinish} validateTrigger="onBlur">
          <Form.Item
            name="reason"
            label="Reason"
            rules={[{ required: true, message: "Please add a reason" }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddRejections;
