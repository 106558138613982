import apiConfig from "../../../config.json";
import { getAuthToken } from "../../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;
const errorResponseObject = {
  success: false,
  error: "Sorry there seems to be an error",
};

const budgetApiRequests = {
  getBudgets: async () => {
    try {
      const res = await fetch(`${apiConfig.server_api_url}/finance/budgets/`, {
        headers: { authorization },
      });
      return res.json();
    } catch (error) {
      console.error("Fetch chart of accounts error", error);
      return errorResponseObject;
    }
  },
  getApprovedBudget: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/approvedBudget/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Approved budget request error", error);
      return errorResponseObject;
    }
  },
  getBudgetMonitoringStatement: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/budgetMonitoringStatement/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Approved budget request error", error);
      return errorResponseObject;
    }
  },
  addBudget: async (body) => {
    try {
      const res = await fetch(`${apiConfig.server_api_url}/finance/budgets`, {
        method: "POST",
        headers: { authorization, "content-type": "application/json" },
        body: JSON.stringify(body),
      });
      return res.json();
    } catch (error) {
      console.error("Add budget error: ", error);
      return errorResponseObject;
    }
  },
  addBudgetLine: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/budgetLines`,
        {
          method: "POST",
          headers: { authorization, "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Add budget line error:", error);
      return errorResponseObject;
    }
  },
  addBudgetActivity: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/budgetActivities`,
        {
          method: "POST",
          headers: { authorization, "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Add budget line error:", error);
      return errorResponseObject;
    }
  },
  editBudgetLine: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/budgetLines/${body.id}`,
        {
          method: "PUT",
          headers: { authorization, "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Edit Budget line error", error);
      return errorResponseObject;
    }
  },
  editBudgetActivity: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/budgetActivities/${body.id}`,
        {
          method: "PUT",
          headers: { authorization, "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Edit Budget activity error", error);
      return errorResponseObject;
    }
  },
  deleteBudget: async (id, project) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/${project}/${id}`,
        {
          method: "DELETE",
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  deleteBudgetLine: async (id, budget) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/budgetLines/${budget}/${id}`,
        {
          method: "DELETE",
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  deleteBudgetActivity: async (id, budget) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/budgetActivities/${budget}/${id}`,
        {
          method: "DELETE",
          headers: { authorization },
        }
      );

      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  approveBudget: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/approve/${id}`,
        {
          method: "PATCH",
          headers: { authorization, "content-type": "application/json" },
          body: JSON.stringify({ approvedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  checkBudget: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/check/${id}`,
        {
          method: "PATCH",
          headers: { authorization, "content-type": "application/json" },
          body: JSON.stringify({ checkedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  submitBudget: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/submit/${id}`,
        {
          method: "PATCH",
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  metaData: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/budgets/metaData`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  getDetails: async (path) => {
    try {
      const res = await fetch(`${apiConfig.server_api_url}/finance/${path}`, {
        headers: { authorization },
      });
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
};

export default budgetApiRequests;
