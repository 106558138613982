// services/reportGenerator.js

import jsPDF from "jspdf";
import "jspdf-autotable";
import { numberWithCommas } from "../helpers/app/numberFormater";
import image from "../../assets/logo/authLogo.png";
import { centrePdfText } from "../helpers/app/pdf";
import appConfig from "../config.json";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const generateLocalPurchaseOrder = (data, currency) => {
  const doc = new jsPDF();
  doc.setFontSize(10);
  doc.addImage(image, "PNG", 60, 15, 80, 25); //base64 image, format, x-coordinate, y-coordinate, width, height
  centrePdfText(doc, "PO Box 11406, Kampala (U)", 40);
  centrePdfText(
    doc,
    "Administration and Partnership Office- Plot 9A Ntinda Crescent View Road, Kampala ",
    45
  );
  centrePdfText(
    doc,
    "Arua Field Office- Arua Youth One Stop Centre, Enyau Road",
    50
  );
  centrePdfText(
    doc,
    "Maracha Field Office- Driver's Corner Building Maracha Town Council. ",

    55
  );
  centrePdfText(
    doc,
    "Tel: +256 794728054/ +256 703154084 | Mob: +256 782206830",
    60
  );
  centrePdfText(
    doc,
    "Web: www.amaniinitiative.org  | Email: info.amaniinitiative@gmail.com  ",
    65
  );
  centrePdfText(doc, "AMANI INITIATIVE LOCAL PURCHASE ORDER", 75);
  doc.text(`DATE: ${new Date(data.created_at).toDateString()} `, 14, 85);
  doc.text(`REQUEST FOR QUOTATION: ${data?.rfqDetails.rfqno} `, 80, 85);
  doc.text(`LOCAL PURCHASE ORDER NO. : ${data.id} `, 14, 90);
  const pdfColumns = ["Particulars", "Units", "Unit Cost", "Total Amount"];
  let pdfRows = [];
  let total = 0;
  data.lpoItems?.forEach((activity) => {
    total += parseInt(activity.units) * parseInt(activity.unit_cost);
    pdfRows.push([
      activity.name,
      numberWithCommas(parseInt(activity.units)),
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        parseInt(activity.unit_cost)
      )}`,
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        parseInt(activity.units) * parseInt(activity.unit_cost)
      )}`,
    ]);
  });
  const discountedAmount = total - (data.discount / 100) * total;
  pdfRows.push([
    "Total",
    "",
    "",
    `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(total)}`,
  ]);
  pdfRows.push(["", "Discount", "", `${data.discount} %`]);
  pdfRows.push([
    "",
    "Net Total",
    "",
    `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
      Math.round(discountedAmount)
    )}`,
  ]);
  doc.autoTable(pdfColumns, pdfRows, { startY: 100 });
  let { finalY } = doc.lastAutoTable;
  doc.text(
    `Prepared By: ${data?.creator?.first_name} ${data?.creator?.other_names} `,
    14,
    finalY + 10
  );
  doc.text(`Role: ${data?.creator?.role} `, 14, finalY + 20);
  doc.text(
    `Date Prepared: ${new Date(data.created_at).toDateString()}  `,
    117,
    finalY + 10
  );
  doc.text(`Signature: `, 125, finalY + 20);
  if (data?.creator?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.creator?.signature}`,
      "PNG",
      150,
      finalY + 12,
      30,
      15
    );
  }
  doc.text(
    `Checked By: ${data?.checker?.first_name} ${data?.checker?.other_names}`,
    14,
    finalY + 35
  );
  doc.text(`Role: ${data?.checker?.role}`, 14, finalY + 45);
  doc.text(
    `Date Checked: ${new Date(data.checked_at).toDateString()}  `,
    117,
    finalY + 35
  );
  doc.text(`Signature: `, 125, finalY + 45);
  if (data?.checker?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.checker?.signature}`,
      "PNG",
      150,
      finalY + 37,
      30,
      15
    );
  }
  doc.text(
    `Approved By: ${data?.approver?.first_name} ${data?.approver?.other_names}`,
    14,
    finalY + 60
  );
  doc.text(`Role: ${data?.approver?.role}`, 14, finalY + 70);
  doc.text(
    `Date Approved: ${new Date(data.approved_at).toDateString()}  `,
    117,
    finalY + 60
  );
  doc.text(`Signature: `, 125, finalY + 70);
  if (data?.approver?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.approver?.signature}`,
      "PNG",
      150,
      finalY + 63,
      30,
      15
    );
  }
  doc.save("AMANI INITIATIVE LOCAL PURCHASE ORDER ");
};

export default generateLocalPurchaseOrder;
