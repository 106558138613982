import { Button, Form, Modal, Upload } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { InboxOutlined } from "@ant-design/icons";
import { FcHighPriority } from "react-icons/fc";

const ChangeProfilePictureForm = ({ handleSubmit }) => {
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.staffRecords.profilePictureModal
  );
  const { loading, success, error } = useSelector(
    (state) => state.staffRecordsState.updateProfilePicture
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleChangeProfilePictureModal(false));
  };
  const onFinish = (values) => {
    const formData = new FormData();
    formData.append("image", values.image.file.originFileObj);
    handleSubmit(formData);
  };

  return (
    <div>
      <Modal
        title="CHANGE PROFILE PICTURE"
        visible={visible}
        footer={null}
        onCancel={closeModal}
      >
        {!success && error && (
          <div className="alert alert-danger">
            <FcHighPriority /> &nbsp; {error}
          </div>
        )}
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            rules={[{ required: true, message: "Please upload a file" }]}
            name="image"
          >
            <Upload.Dragger name="file" listType="picture" maxCount={1}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              className="w-100"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ChangeProfilePictureForm;
