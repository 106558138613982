import ConfirmAccount from "../../containers/auth/ConfirmAccount";
import Login from "../../containers/auth/Login";
import ResetPassword from "../../containers/auth/ResetPassword";
import Roles from "../../containers/settings/Roles";
import LogDetails from "../../containers/settings/LogDetails";
import Permissions from "../../containers/settings/Permissions";
import SettingsLayout from "../../containers/settings/SettingsLayout";
import AdministrationLayout from "../../containers/administration/AdministrationLayout";
import Assets from "../../containers/administration/assetRegister/Assets";
import Profile from "../../containers/settings/Profile";
import StaffRecords from "../../containers/administration/staffRecords/StaffRecords";
import StoresRegister from "../../containers/administration/StoresRegister/StoresRegister";
import ManualsAndPolicies from "../../containers/administration/ManualsAndPolicies/ManualsAndPolicies";
import StaffDetails from "../../containers/administration/staffRecords/StaffDetails";
import BusinessDevAndPartnerShipsLayout from "../../containers/businessDevelopmentAndPartnerships/BusinessDevAndPartnerShipsLayout";
import Projects from "../../containers/businessDevelopmentAndPartnerships/Projects/Projects";
import ProjectDetails from "../../containers/businessDevelopmentAndPartnerships/Projects/ProjectDetails";
import Donors from "../../containers/businessDevelopmentAndPartnerships/donors/Donors";
import DonorsDetails from "../../containers/businessDevelopmentAndPartnerships/donors/DonorsDetails";
import FinanceLayout from "../../containers/finance/FinanceLayout";
import ChartOfAccounts from "../../containers/finance/chartOfAccounts/ChartOfAccounts";
import Budget from "../../containers/finance/budget/Budget";
import BudgetDetails from "../../containers/finance/budget/BudgetDetails";
import MyProjects from "../../containers/myProjects/MyProjects";
import MyProjectDetails from "../../containers/myProjects/MyProjectDetails";
import RequisitionMemo from "../../containers/finance/requisitionMemos/RequisitionMemo";
import PaymentVoucher from "../../containers/finance/paymentVoucher/PaymentVoucher";
import AdvanceAccountabilityForm from "../../containers/finance/advanceAccountabilityForm/AdvanceAccountabilityForm";
import Procument from "../../containers/procurement/ProcumentLayout";
import Rfq from "../../containers/procurement/rfqs/Rfq";
import RecordOfBidders from "../../containers/procurement/recordOfBidders/RecordOfBidders";
import BidEvaluation from "../../containers/procurement/bidEvaluation/BidEvaluation";
import BidEvaluationForm from "../../containers/procurement/bidEvaluation/BidEvaluationForm";
import NoticeOfBestBidder from "../../containers/procurement/noticeOfBestBidder/NoticeOfBestBidder";
import LocalPurchaseOrder from "../../containers/procurement/LocalPurchaseOrder/LocalPurchaseOrder";
import Dashboard from "../../containers/home/Dashboard";

const routes = {
  authentication: {
    login: {
      path: "/login",
      title: "AUTH | LOGIN",
      component: Login,
    },
    resetPassword: {
      path: "/resetPassword/:resetToken",
      title: "AUTH | RESET PASSWORD",
      component: ResetPassword,
    },
    confirmAccount: {
      path: "/confirmAccount/:confirmToken",
      title: "AUTH | CONFIRM ACCOUNT",
      component: ConfirmAccount,
    },
  },
  home: {
    dashboard: {
      path: "/",
      title: "Home",
      component: Dashboard,
    },
  },
  settings: {
    title: "SETTTINGS",
    path: "/settings",
    component: SettingsLayout,
    //child routes
    roles: {
      path: "/settings/roles",
      title: "SETTINGS | ROLES",
      component: Roles,
    },
    permissions: {
      path: "/settings/permisions",
      title: "SETTINGS | PERMISSIONS",
      component: Permissions,
    },
    logDetails: {
      path: "/settings/logDetails",
      title: "SETTINGS | LOG DETAILS",
      component: LogDetails,
    },
    profile: {
      path: "/settings/profile",
      title: "SETTINGS | PROFILE",
      component: Profile,
    },
  },
  administration: {
    path: "/administration",
    title: "ADMINISTRATION",
    component: AdministrationLayout,
    //child routes
    assetRegister: {
      assets: {
        path: "/administration/assets",
        title: "ADMINISTRATION | ASSET REGISTER",
        component: Assets,
      },
    },
    staffRecords: {
      path: "/administration/staffRecords",
      title: "ADMINISTRATION | STAFF RECORDS",
      component: StaffRecords,
      //childRoutes
      staffDetails: {
        path: "/administration/staffRecords/:id",
        title: "ADMINISTRATION | STAFF RECORDS DETAILS",
        component: StaffDetails,
      },
    },
    storesRegister: {
      path: "/administration/storesRegister",
      title: "ADMINISTRATION | STORES REGISTER",
      component: StoresRegister,
    },
    manualsAndPolicies: {
      path: "/administration/manualsAndPolicies",
      title: "ADMINISTRATION | MANUALS AND POLICIES",
      component: ManualsAndPolicies,
    },
  },
  businesDevelopmentAndPartnerships: {
    path: "/businessDevelopmentAndPartnerships",
    title: "BUSINESS DEVELOPMENT AND PARTNERSHIPS",
    component: BusinessDevAndPartnerShipsLayout,
    //child Routes
    projects: {
      path: "/businessDevelopmentAndPartnerships/projects",
      title: "PROJECTS",
      component: Projects,
      //child Routes
      projectDetails: {
        path: "/businessDevelopmentAndPartnerships/projects/:projectId",
        title: "PROJECT DETAILS",
        component: ProjectDetails,
      },
    },
    donors: {
      path: "/businessDevelopmentAndPartnerships/donors",
      title: "DONORS",
      component: Donors,
      //child routes
      donorDetails: {
        path: "/businessDevelopmentAndPartnerships/donors/:donorId",
        title: "DONORS DETAILS",
        component: DonorsDetails,
      },
    },
  },
  finance: {
    path: "/finance",
    title: "FINANCE",
    component: FinanceLayout,
    //child routes
    chartOfAccounts: {
      path: "/finance/chartOfAccounts",
      title: "FINANCE | CHART OF ACCOUNTS",
      component: ChartOfAccounts,
    },
    budget: {
      path: "/finance/budget",
      title: "FINANCE | BUDGET",
      component: Budget,
      budgetDetails: {
        path: "/finance/budget/:budgetId",
        title: "FINANCE | BUDGET DETAILS",
        component: BudgetDetails,
      },
    },
    requistionMemos: {
      path: "/finance/requisitionMemos/",
      title: "FINANCE | REQUISITION FORM",
      component: RequisitionMemo,
    },
    paymentVoucher: {
      path: "/finance/paymentVoucher",
      title: "FINANCE | FUNDS ADVANCE/WITHDRAW FORM",
      component: PaymentVoucher,
    },
    advanceAccountabilityForm: {
      path: "/finance/advanceAccountabilityForm",
      title: "FINANCE | PAYMENT VOUCHER",
      component: AdvanceAccountabilityForm,
    },
  },
  myProjects: {
    path: "/myProjects",
    title: "MY PROJECTS",
    component: MyProjects,
    //my project details,
    myProjectDetails: {
      title: "MY PROJECT DETAILS",
      path: "/myProjects/:id",
      component: MyProjectDetails,
    },
  },
  procurement: {
    path: "/procurement",
    title: "PROCUMENT",
    component: Procument,
    //rfq
    rfq: {
      path: "/procurement/rfq",
      title: "PROCUREMENT | RFQ",
      component: Rfq,
    },
    // record of bidders
    recordOfBidders: {
      path: "/procurement/recordOfBidders",
      title: "PROCUREMENT | RECORD OF BIDDERS",
      component: RecordOfBidders,
    },
    //bidEvalutaion
    bidEvaluation: {
      path: "/procurement/bidEvaluation",
      title: "PROCUREMENT | BID EVALUATION",
      component: BidEvaluation,
      //bidEvaluaitionForm
      bidEvaluationForm: {
        path: "/procurement/bidEvaluation/:id",
        title: "PROCUREMENT | BID EVALUATION FORM",
        component: BidEvaluationForm,
      },
    },
    noticeOfBestBidder: {
      path: "/procurement/noticeOfBestBidder/",
      title: "PROCUREMENT | NOTICE OF BEST BIDDER",
      component: NoticeOfBestBidder,
    },
    //local purchase order
    localPurchaseOrder: {
      path: "/procurement/localPuchaseOrder",
      title: "PROCUREMENT | LOCAL PURCHASE ORDER",
      component: LocalPurchaseOrder,
    },
  },
};

export default routes;
