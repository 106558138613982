import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import storesRegisterThunks from "../../../config/thunks/administration/storesRegister/storesRegister.thunks";
import routes from "../../../config/routes/routes";
import StoresRegisterTable from "../../../components/administration/StoresRegister/Tables/StoresRegisterTable";
import AddReceiptForm from "../../../components/administration/StoresRegister/Forms/AddReceiptForm";
import EditReceiptForm from "../../../components/administration/StoresRegister/Forms/EditReceiptForm";
import ReceiptDetails from "../../../components/administration/StoresRegister/Details/ReceiptDetails";
import AddIssueForm from "../../../components/administration/StoresRegister/Forms/AddIssueForm";
import EditIssueForm from "../../../components/administration/StoresRegister/Forms/EditIssueForm";
import IssueDetails from "../../../components/administration/StoresRegister/Details/IssueDetails";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import assetRegisterThunk from "../../../config/thunks/administration/assetsRegister/assetsRegister.thunks";

const StoresRegister = () => {
  const [item, setItem] = useState("");
  const {
    success: storesRegisterSuccess,
    error: storesRegisterError,
    data: storesRegisterArray,
  } = useSelector((state) => state.storesRegisterState.storesRegister);
  const { success: addReceiptSuccess, message: addReceiptMessage } =
    useSelector((state) => state.storesRegisterState.addStoresRegisterReceipt);
  const { success: addIssueSuccess, message: addIssueMessage } = useSelector(
    (state) => state.storesRegisterState.addStoresRegisterIssue
  );
  const { success: editReceiptSuccess, message: editReceiptMessage } =
    useSelector(
      (state) => state.storesRegisterState.updateStoresRegisterReceipt
    );
  const { success: editIssueSuccess, message: editIssueMessage } = useSelector(
    (state) => state.storesRegisterState.updateStoresRegisterIssue
  );
  const {
    success: deleteReceiptSuccess,
    message: deleteReceiptMessage,
    error: deleteReceiptError,
  } = useSelector(
    (state) => state.storesRegisterState.deleteStoresRegisterReceipt
  );
  const {
    success: deleteIssueSuccess,
    message: deleteIssueMessage,
    error: deleteIssueError,
  } = useSelector(
    (state) => state.storesRegisterState.deleteStoresRegisterIssue
  );
  const {
    success: approveReceiptSuccess,
    message: approveReceiptMessage,
    error: approveReceiptError,
  } = useSelector(
    (state) => state.storesRegisterState.approveStoresRegisterReceipt
  );
  const {
    approveStoresRegisterIssueMessage,
    approveStoresRegisterIssueSuccess,
    approveStoresRegisterIssueError,
  } = useSelector((state) => state.storesRegisterState);
  const {
    success: checkReceiptSuccess,
    message: checkReceiptMessage,
    error: checkReceiptError,
  } = useSelector(
    (state) => state.storesRegisterState.checkStoresRegisterReceipt
  );
  const {
    success: checkIssueSuccess,
    message: checkIssueMessage,
    error: checkIssueError,
  } = useSelector(
    (state) => state.storesRegisterState.checkStoresRegisterIssue
  );
  const dispatch = useDispatch();
  /** Functions */
  const refreshTable = () => {
    dispatch(storesRegisterThunks.getStoresRegister());
    dispatch(assetRegisterThunk.getMetaData());
    if (!storesRegisterSuccess && storesRegisterError)
      message.error(storesRegisterError);
  };
  const addStoresRegisterReciept = (values) =>
    dispatch(storesRegisterThunks.addStoresRegisterReceipt(values));
  const addStoresRegisterIssue = (values) =>
    dispatch(storesRegisterThunks.addStoresRegisterIssuess(values));
  const deleteReceipt = (id) => {
    const storesRegister = storesRegisterArray.filter((e) => e.id !== id);
    dispatch(
      storesRegisterThunks.deleteStoresRegisterReceipt(id, storesRegister)
    );
  };
  const editIssue = (values) =>
    dispatch(storesRegisterThunks.updateStoresRegisterIssues(values));
  const deleteIssue = (id) =>
    dispatch(storesRegisterThunks.deleteStoresRegisterIssues(id));
  const checkReceipt = (id) =>
    dispatch(storesRegisterThunks.checkStoresRegisterReceipt(id));
  const checkIssue = (id) =>
    dispatch(storesRegisterThunks.checkStoresRegisterIssue(id));
  const approveIssue = (id) =>
    dispatch(storesRegisterThunks.approveStoresRegisterIssues(id));
  const approveReceipt = (id) =>
    dispatch(storesRegisterThunks.approveStoresRegisterReceipt(id));
  const editReciept = (values) => {
    const storesRegister = storesRegisterArray.filter(
      (e) => e.id !== values.id
    );
    dispatch(
      storesRegisterThunks.updateStoresRegisterReceipt(
        values,
        storesRegister,
        values.issues
      )
    );
  };
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Stores Register",
        records: "storesRegister",
      })
    );
  };

  /** use effect */
  useEffect(() => {
    document.title = routes.administration.storesRegister.title;
    //get stores Register
    if (!storesRegisterSuccess) {
      dispatch(storesRegisterThunks.getStoresRegister());
      dispatch(assetRegisterThunk.getMetaData());
    }
  }, [storesRegisterSuccess, dispatch, storesRegisterError]);
  //add useEffect
  useEffect(() => {
    //add Receipt and issue Success
    if (addReceiptSuccess && addReceiptMessage)
      message.success(addReceiptMessage);
    if (addIssueSuccess && addIssueMessage) message.success(addIssueMessage);
  }, [addIssueSuccess, addIssueMessage, addReceiptSuccess, addReceiptMessage]);
  //delete use Effect
  useEffect(() => {
    //delete receipt
    if (deleteReceiptSuccess && deleteReceiptMessage) {
      message.success(deleteReceiptMessage);
    } else if (deleteReceiptError && !deleteReceiptSuccess) {
      message.error(deleteReceiptError);
    }
    //delete issue
    if (deleteIssueSuccess && deleteIssueMessage) {
      message.success(deleteIssueMessage);
    } else if (!deleteIssueSuccess && deleteIssueError) {
      message.error(deleteIssueError);
    }
  }, [
    deleteIssueError,
    deleteIssueMessage,
    deleteIssueSuccess,
    deleteReceiptError,
    deleteReceiptMessage,
    deleteReceiptSuccess,
  ]);
  //edit issue
  useEffect(() => {
    //edit stores register receipt
    if (editReceiptSuccess && editReceiptMessage)
      message.success(editReceiptMessage);
    //edit stores issue receipt
    if (editIssueSuccess && editIssueMessage) message.success(editIssueMessage);
  }, [
    editIssueMessage,
    editIssueSuccess,
    editReceiptMessage,
    editReceiptSuccess,
  ]);
  //check record
  useEffect(() => {
    //check receipt
    if (checkReceiptMessage && checkReceiptSuccess)
      message.success(checkReceiptMessage);
    else if (checkReceiptError && !checkReceiptSuccess)
      message.error(checkReceiptError);
    //check issue
    if (checkIssueSuccess && checkIssueMessage)
      message.success(checkIssueMessage);
    else if (checkIssueError && !checkIssueSuccess)
      message.error(checkIssueError);
  }, [
    checkIssueError,
    checkIssueMessage,
    checkIssueSuccess,
    checkReceiptError,
    checkReceiptMessage,
    checkReceiptSuccess,
  ]);

  //approveRecords
  useEffect(() => {
    //approve receipt
    if (approveReceiptSuccess && approveReceiptMessage)
      message.success(approveReceiptMessage);
    else if (approveReceiptError && !approveReceiptSuccess)
      message.error(approveReceiptError);
    //aprove issue
    if (approveStoresRegisterIssueSuccess && approveStoresRegisterIssueMessage)
      message.success(approveStoresRegisterIssueMessage);
    else if (
      approveStoresRegisterIssueError &&
      !approveStoresRegisterIssueSuccess
    )
      message.error(approveStoresRegisterIssueError);
  }, [
    approveStoresRegisterIssueMessage,
    approveStoresRegisterIssueSuccess,
    approveStoresRegisterIssueError,
    approveReceiptError,
    approveReceiptMessage,
    approveReceiptSuccess,
  ]);
  return (
    <div className="container-fluid p-2">
      <h3>Stores Register</h3>

      <StoresRegisterTable
        setItem={setItem}
        handleDeleteStoresRegisterIssue={deleteIssue}
        handleDeleteStoresReceipt={deleteReceipt}
        handleRefreshTable={refreshTable}
        handleExportRecords={exportRecords}
      />

      <AddReceiptForm handleAddRecord={addStoresRegisterReciept} />
      <EditReceiptForm handleEditRecord={editReciept} />
      <AddIssueForm handleAddRecord={addStoresRegisterIssue} item={item} />
      <EditIssueForm item={item} handleEditRecord={editIssue} />
      <ReceiptDetails
        setItemId={setItem}
        handleCheck={checkReceipt}
        handleApprove={approveReceipt}
      />
      <AddRejections />
      <IssueDetails handleCheck={checkIssue} handleApprove={approveIssue} />
    </div>
  );
};

export default StoresRegister;
