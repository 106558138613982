import { Modal, Form, Select, Button, message, Alert, Input } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
const { Option } = Select;

const AddAdvanceAccountabilityForm = ({
  visible,
  handleCloseModal,
  handleAddRecord,
}) => {
  const {
    addAdvanceAccountabilityFormsLoading,
    addAdvanceAccountabilityFormsSuccess,
    addAdvanceAccountabilityFormsError,
    addAdvanceAccountabilityFormsMessage,
    metaData,
    metaDataLoading,
    advanceAccountabilityForms,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const { projectsDetails } = useSelector((state) => state.projectsState);
  const [form] = Form.useForm();
  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    values.project = projectsDetails.id;
    handleAddRecord(values, advanceAccountabilityForms);
  };
  useEffect(() => {
    if (
      addAdvanceAccountabilityFormsSuccess &&
      addAdvanceAccountabilityFormsMessage
    ) {
      message.success(addAdvanceAccountabilityFormsMessage);
      form.resetFields();
    }
  }, [
    addAdvanceAccountabilityFormsMessage,
    addAdvanceAccountabilityFormsSuccess,
    form,
  ]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        visible={visible}
        footer={null}
        title="CREATE PAYMENT VOUCHER"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {!addAdvanceAccountabilityFormsSuccess &&
            addAdvanceAccountabilityFormsError && (
              <Alert
                type="error"
                showIcon
                message={addAdvanceAccountabilityFormsError}
              />
            )}
          <Form.Item
            rules={[
              { required: true, message: "Please select a requsition memo" },
            ]}
            label="Requisition Form"
            name="requisition_memo"
          >
            <Select loading={metaDataLoading}>
              {metaData?.requisitionMemos?.map((memo) => (
                <Option key={memo.memo_number} value={memo.memo_number}>
                  {memo.memo_number}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Details"
            name="details"
            rules={[{ required: true, message: "Please add details" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              loading={addAdvanceAccountabilityFormsLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAdvanceAccountabilityForm;
