import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchChartOfAccounts from "../../../components/administration/StoresRegister/Search/SearchChartOfAccounts";
import AccountDetails from "../../../components/Finance/chartOfAccounts/Details/AccountDetails";
import AccountTypeDetails from "../../../components/Finance/chartOfAccounts/Details/AccountTypeDetails";
import AddAccount from "../../../components/Finance/chartOfAccounts/Forms/AddAccount";
import AddAccountTypeModal from "../../../components/Finance/chartOfAccounts/Forms/AddAccountType";
import EditAccount from "../../../components/Finance/chartOfAccounts/Forms/EditAccount";
import EditAccountType from "../../../components/Finance/chartOfAccounts/Forms/EditAccountType";
import ChartOfAccountsActions from "../../../components/Finance/chartOfAccounts/Tables/ChartOfAccountsActions";
import ChartOfAccountsTable from "../../../components/Finance/chartOfAccounts/Tables/ChartOfAccountsTable";
import ChartOfAccountsTree from "../../../components/Finance/chartOfAccounts/Tree/ChartOfAccountsTree";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import { appUiActions } from "../../../config/actions/app/appUi.actions";
import routes from "../../../config/routes/routes";
import chartOfAccountsThunks from "../../../config/thunks/finance/chartOfAccounts/chartOfAccounts.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const ChartOfAccounts = () => {
  const { chartOfAccountsSuccess, chartOfAccountsError } = useSelector(
    (state) => state.chartOfAccountsState
  );
  const dispatch = useDispatch();

  const deleteAccountType = (id, name) =>
    dispatch(chartOfAccountsThunks.deleteAccountType(id, name));
  const deleteAccount = (id, name) =>
    dispatch(chartOfAccountsThunks.deleteAccount(id, name));
  const refreshTable = () => {
    dispatch(chartOfAccountsThunks.getChartOfAccounts());
    dispatch(chartOfAccountsThunks.getMetaData());
    if (chartOfAccountsSuccess && chartOfAccountsError)
      message.error(chartOfAccountsError);
  };
  const openAddAccountTypesModal = () => {
    dispatch(appUiActions.toggleAddChartOfAccountsAccountTypeModal(true));
  };
  const openAddAccountModal = () => {
    dispatch(appUiActions.toggleAddChartOfAccountsAccountModal(true));
  };
  const openSearchModal = () =>
    dispatch(appUiActions.toggleSearchChartOfAccountsModal(true));
  const addAccountType = (values) =>
    dispatch(chartOfAccountsThunks.addAccountType(values));
  const addAccount = (values) =>
    dispatch(chartOfAccountsThunks.addAccount(values));
  const editAccountType = (values) =>
    dispatch(chartOfAccountsThunks.editAccountType(values));
  const editAccount = (values) =>
    dispatch(chartOfAccountsThunks.editAccount(values));
  const accountTypeDetails = (id) =>
    dispatch(chartOfAccountsThunks.getAccountTypeDetails(id));
  const accountDetails = (id) =>
    dispatch(chartOfAccountsThunks.getAccountDetails(id));
  const approveAccountType = (id) =>
    dispatch(chartOfAccountsThunks.approveAccountType(id));
  const approveAccount = (id) =>
    dispatch(chartOfAccountsThunks.approveAccount(id));
  const checkAccount = (id) => dispatch(chartOfAccountsThunks.checkAccount(id));
  const checkAccountType = (id) =>
    dispatch(chartOfAccountsThunks.checkAccountType(id));
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Chart Of Accounts",
        records: "chartOfAccounts",
      })
    );
  };
  /* Use effect */
  useEffect(() => {
    document.title = routes.finance.chartOfAccounts.title;
    if (!chartOfAccountsSuccess) {
      dispatch(chartOfAccountsThunks.getChartOfAccounts());
      dispatch(chartOfAccountsThunks.getMetaData());
    }
  }, [chartOfAccountsSuccess, dispatch]);
  return (
    <div className="w-100">
      <h3 className="m-4">Chart Of Accounts</h3>
      <div className="d-flex container-fluid">
        <ChartOfAccountsTree
          handleViewAccountDetails={accountDetails}
          handleViewAccountTypeDetails={accountTypeDetails}
        />
        <div className="w-100">
          <ChartOfAccountsActions
            openSearchModal={openSearchModal}
            handleExportRecords={exportRecords}
            openAddAccounts={openAddAccountModal}
            openAddAccountTypes={openAddAccountTypesModal}
            refreshTable={refreshTable}
          />
          <ChartOfAccountsTable
            handleViewAccountDetails={accountDetails}
            handleDeleteAccount={deleteAccount}
            handleViewAccountTypeDetails={accountTypeDetails}
            handleDeleteAccountType={deleteAccountType}
          />
          <AddAccountTypeModal handleAddRecord={addAccountType} />
          <AccountTypeDetails
            handleApproveRecord={approveAccountType}
            handleCheckRecord={checkAccountType}
          />
          <AccountDetails
            handleCheckRecord={checkAccount}
            handleApproveRecord={approveAccount}
          />
          <AddAccount handleAddRecord={addAccount} />
          <EditAccountType handleEditRecord={editAccountType} />
          <EditAccount handleEditAccount={editAccount} />
          <SearchChartOfAccounts
            handleViewAccountDetails={accountDetails}
            handleDeleteAccount={deleteAccount}
            handleViewAccountTypeDetails={accountTypeDetails}
            handleDeleteAccountType={deleteAccountType}
          />
          <AddRejections />
        </div>
      </div>
    </div>
  );
};

export default ChartOfAccounts;
