import React, { useState } from "react";
import { Menu } from "antd";
import { FcGoodDecision, FcOrgUnit } from "react-icons/fc";
import { Link } from "react-router-dom";
import routes from "../../../config/routes/routes";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const BusinessDevMenu = () => {
  const [collapsed, setcollapsed] = useState(true);
  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);

  const toggleCollapsed = () => {
    setcollapsed(!collapsed);
  };
  return (
    <div className="d-none d-md-block">
      <Menu
        className="py-2"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="0" className="text-start" onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Menu.Item>
        {viewPermissions?.Donors && (
          <Menu.Item key="1" icon={<FcGoodDecision />}>
            <Link to={routes.businesDevelopmentAndPartnerships.donors.path}>
              Donors
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Projects && (
          <Menu.Item key="3" icon={<FcOrgUnit />}>
            <Link to={routes.businesDevelopmentAndPartnerships.projects.path}>
              Projects
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default BusinessDevMenu;
