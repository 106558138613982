import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { FcHighPriority } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { manualsAndPoliciesActions } from "../../../../config/actions/administration/manualsAndPolicies/manualsAndPolicies.actions";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const EditManualsAndPolicies = ({ handleEditManualsAndPolicies }) => {
  const dispatch = useDispatch();
  const { error, success, loading, data } = useSelector(
    (state) => state.manualsAndPoliciesState.updateManualsAndPolicies
  );
  const onFinish = (values) => {
    values.updatedAt = new Date();
    values.id = data.id;
    dispatch(manualsAndPoliciesActions.setManualsAndPoliciesUpdateData(values));
    handleEditManualsAndPolicies(values);
  };
  const visible = useSelector(
    (state) =>
      state.AppUi.admisnistration.manualsAndPolicies.editManualsAndPoliciesModal
  );

  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleEditManualsAndPoliciesModal(false));
  };
  const fields = [{ name: "name", value: data.name }];
  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        visible={visible}
        title="EDIT FILE NAME"
        footer={null}
      >
        <Form
          fields={fields}
          validateTrigger="onBlur"
          layout="vertical"
          onFinish={onFinish}
        >
          {!success && error && (
            <div className="alert alert-danger">
              <FcHighPriority /> &nbsp; {error}
            </div>
          )}
          <Form.Item
            name="name"
            label="File Name"
            rules={[{ required: true, message: "Please add a file name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              className={"w-100"}
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditManualsAndPolicies;
