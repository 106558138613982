import { Alert, Button, message, Space, Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getIdFromPath } from "../../../config/helpers/app/url";
import projectThunks from "../../../config/thunks/businessDevAndPartnerships/projects/projects.thunks";
import appConfig from "../../../config/config.json";
import { rejectionsActions } from "../../../config/actions/rejections/rejections.actions";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import ProjectInfoDetails from "../../../components/BusinessDevelopmentAndPartnerships/projects/Tables/ProjectInfoDetails";
import BudgetMonitoringStatement from "../../../components/Finance/Budget/Tables/BudgetMonitoringStatement";
import budgetThunks from "../../../config/thunks/finance/budgets/budgets.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";
import { FcRefresh } from "react-icons/fc";
import { AiOutlineArrowLeft } from "react-icons/ai";

const { TabPane } = Tabs;

const ProjectDetails = () => {
  //state,variable, decalrations and initialization
  const {
    projectsDetails: data,
    projectsDetailsSuccess,
    projectsDetailsLoading,
    projectsDetailsError,
    checkProjectsLoading,
    checkProjectsSuccess,
    checkProjectMessage,
    checkProjectsError,
    approveProjectsLoading,
    approveProjectsError,
    approveProjectsSuccess,
    approveProjectsMessage,
  } = useSelector((state) => state.projectsState);
  const dispatch = useDispatch();
  const history = useHistory();
  const id = getIdFromPath(history.location.pathname);

  // functions
  const approveRecord = () => dispatch(projectThunks.approveProject(id));
  const checkRecord = () => dispatch(projectThunks.checkProject(id));
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: data?.created_by,
          item_application: "Donors",
          item_id: data?.id,
          itemName: data.name,
        },
      })
    );
  };
  const refreshApprovedBudget = () =>
    dispatch(budgetThunks.getBudgetMonitoringStatement(data.projectBudget));
  const exportBudgetMonitoringStatement = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported ${data.name} Budget Monitoring Statement`,
        records: "budgetMonitoringStatement",
      })
    );
  };
  const reload = () => dispatch(projectThunks.getProjectDetails(id));

  /** useEffects */
  useEffect(() => {
    dispatch(projectThunks.getProjectDetails(id));
    dispatch(budgetThunks.getBudgetMonitoringStatement(data.projectBudget));
    //check donor
    if (checkProjectsSuccess && checkProjectMessage)
      message.success(checkProjectMessage);
    else if (!checkProjectsSuccess && checkProjectsError)
      message.error(checkProjectsError);
    //approve donor
    if (approveProjectsSuccess && approveProjectsMessage)
      message.success(approveProjectsMessage);
    else if (!approveProjectsSuccess && approveProjectsError)
      message.error(approveProjectsError);
  }, [
    approveProjectsError,
    approveProjectsMessage,
    approveProjectsSuccess,
    checkProjectMessage,
    checkProjectsError,
    checkProjectsSuccess,
    data.projectBudget,
    dispatch,
    id,
  ]);
  return (
    <div className="container-fluid p-2">
      {projectsDetailsLoading ? (
        <div className="m-5 d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {!projectsDetailsSuccess ? (
            <Alert type="error" showIcon message={projectsDetailsError} />
          ) : (
            <div>
              <h3>{data?.name}</h3>
              <Space>
                {data.status !== appConfig.status.approved && (
                  <Space className="my-2">
                    <Button
                      onClick={approveRecord}
                      loading={approveProjectsLoading}
                      type="primary"
                    >
                      Approve
                    </Button>
                    {data.status !== appConfig.status.checked && (
                      <Button
                        onClick={checkRecord}
                        loading={checkProjectsLoading}
                      >
                        Check
                      </Button>
                    )}
                    <Button onClick={reject} danger>
                      Reject
                    </Button>
                  </Space>
                )}
                <Button onClick={reload}>
                  <Space>
                    <FcRefresh /> <span>Reload </span>
                  </Space>
                </Button>
                <Button
                  className="d-none d-md-block"
                  onClick={() => history.goBack()}
                  danger
                >
                  <Space>
                    <AiOutlineArrowLeft /> <span>Return </span>
                  </Space>
                </Button>
              </Space>
              <Tabs size="large" defaultActiveKey="1">
                <TabPane tab="Project Details" key="1">
                  <ProjectInfoDetails data={data} />
                </TabPane>
                <TabPane tab="Budget Monitoring Statement" key="2">
                  <BudgetMonitoringStatement
                    handleRefreshTable={refreshApprovedBudget}
                    handleExportRecords={exportBudgetMonitoringStatement}
                  />
                </TabPane>
              </Tabs>
              <AddRejections />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
