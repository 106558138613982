import React, { useEffect } from "react";
import ProcumentMenu from "../../components/Procurement/Menus/ProcumentMenu";
import { Switch, Route } from "react-router-dom";
import routes from "../../config/routes/routes";
import PageNotFound from "../App/404";

const Procument = () => {
  useEffect(() => {
    document.title = routes.procurement.title;
  }, []);
  return (
    <div className="d-flex">
      <ProcumentMenu />
      <Switch>
        <Route
          exact
          path={routes.procurement.rfq.path}
          component={routes.procurement.rfq.component}
        />
        <Route
          exact
          path={routes.procurement.recordOfBidders.path}
          component={routes.procurement.recordOfBidders.component}
        />
        <Route
          exact
          path={routes.procurement.bidEvaluation.path}
          component={routes.procurement.bidEvaluation.component}
        />
        <Route
          exact
          path={routes.procurement.bidEvaluation.bidEvaluationForm.path}
          component={
            routes.procurement.bidEvaluation.bidEvaluationForm.component
          }
        />
        <Route
          exact
          path={routes.procurement.noticeOfBestBidder.path}
          component={routes.procurement.noticeOfBestBidder.component}
        />
        <Route
          exact
          path={routes.procurement.localPurchaseOrder.path}
          component={routes.procurement.localPurchaseOrder.component}
        />
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default Procument;
