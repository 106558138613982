import apiConfig from "../../../config.json";
import { getAuthToken } from "../../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;
const errorResponseObject = {
  success: false,
  error: "Sorry there seems to be an error",
};

const donorsApiRequest = {
  fetchDonors: async () => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch donors error", error);
      return errorResponseObject;
    }
  },
  fetchDonorDetails: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch donors details error", error);
      return errorResponseObject;
    }
  },
  addDonor: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors`,
        {
          method: "POST",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Add donor error", error);
      return errorResponseObject;
    }
  },
  updateDonor: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors/${body.id}`,
        {
          method: "PUT",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Update donor error", error);
      return errorResponseObject;
    }
  },
  deleteDonor: async (id, name) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors/${name}/${id}`,
        {
          method: "DELETE",
          headers: {
            authorization,
          },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Delete donor error", error);
      return errorResponseObject;
    }
  },
  checkDonor: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors/check/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ checkedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Check donor error", error);
      return errorResponseObject;
    }
  },
  approveDonor: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors/approve/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ approvedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Approve donor error", error);
      return errorResponseObject;
    }
  },
  setPersonIncharge: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors/addPersonIncharge/${body.id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  submitProposalStatus: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/donors/proposalStatus/${body.id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
};

export default donorsApiRequest;
