import React, { useState } from "react";
import { Menu } from "antd";
import {
  FcBriefcase,
  FcBusiness,
  FcOrgUnit,
  FcRules,
  FcTodoList,
} from "react-icons/fc";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import routes from "../../../config/routes/routes";

const MyProjectsMenu = ({ handleSetActiveKey }) => {
  const [collapsed, setcollapsed] = useState(true);

  const toggleCollapsed = () => setcollapsed(!collapsed);

  return (
    <div className="">
      <Menu
        className="py-2 m-n5"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="0" className="text-start" onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Menu.Item>
        <Menu.Item
          onClick={() => handleSetActiveKey("1")}
          key="1"
          icon={<FcOrgUnit />}
        >
          Project Details
        </Menu.Item>
        <Menu.Item
          onClick={() => handleSetActiveKey("2")}
          key="2"
          icon={<FcBusiness />}
        >
          Approved Budget
        </Menu.Item>
        <Menu.Item
          onClick={() => handleSetActiveKey("3")}
          key="3"
          icon={<FcBriefcase />}
        >
          Budget Monitoring Statement
        </Menu.Item>
        <Menu.Item
          onClick={() => handleSetActiveKey("4")}
          key="4"
          icon={<FcRules />}
        >
          Requisition Forms
        </Menu.Item>
        <Menu.Item
          onClick={() => handleSetActiveKey("5")}
          key="5"
          icon={<FcTodoList />}
        >
          Payment Vouchers
        </Menu.Item>
        <Menu.Item icon={<AiOutlineArrowLeft />} key="6">
          <Link to={routes.myProjects.path}>Return</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MyProjectsMenu;
