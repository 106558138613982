import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentVoucherDetails from "../../../components/Finance/paymentVouchers/Details/PaymentVoucherDetails";
import AddPaymentVoucher from "../../../components/Finance/paymentVouchers/Forms/AddPaymentVoucher";
import EditPaymentVoucher from "../../../components/Finance/paymentVouchers/Forms/EditPaymentVoucher";
import PaymentVouchersTable from "../../../components/Finance/paymentVouchers/Tables/PaymentVouchersTable";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import routes from "../../../config/routes/routes";
import paymentVoucherThunks from "../../../config/thunks/finance/paymentVoucher/paymentVoucher.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const PaymentVoucher = () => {
  //variables , state and initiailization
  const { paymentVouchersSuccess } = useSelector(
    (state) => state.paymentVouchersState
  );
  const [addPaymentVoucherModalVisible, setAddPaymentVoucherVisible] =
    useState(false);
  const [editPaymentVoucherModalVisible, setEditPaymentVoucherVisible] =
    useState(false);
  const [
    paymentVoucherDetailsModalVisible,
    setPaymentVoucherDetailsModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  //ui
  const toggleAddPaymentVoucherModalVisible = (value) =>
    setAddPaymentVoucherVisible(value);
  const togglePaymentVoucherDetailsModalVisible = (value) =>
    setPaymentVoucherDetailsModalVisible(value);
  //thunks
  const refreshData = () => {
    dispatch(paymentVoucherThunks.getPaymentVouchers());
    dispatch(paymentVoucherThunks.paymentVouchersMetaData());
  };
  const addPaymentVoucher = (values, paymentVouchers) =>
    dispatch(paymentVoucherThunks.addPaymentVouchers(values, paymentVouchers));
  const editPaymentVouchers = (values) =>
    dispatch(paymentVoucherThunks.editPaymentVouchers(values));
  const deletePaymentVoucher = (values, paymentVouchers) =>
    dispatch(
      paymentVoucherThunks.deletePaymentVouchers(values, paymentVouchers)
    );
  const getPaymentVoucherDetails = (id) => {
    setPaymentVoucherDetailsModalVisible(true);
    dispatch(paymentVoucherThunks.getPaymentVouchersDetails(id));
  };
  const checkPaymentVoucher = (id) =>
    dispatch(paymentVoucherThunks.checkPaymentVouchers(id));
  const approvePaymentVoucher = (id) =>
    dispatch(paymentVoucherThunks.approvePaymentVouchers(id));
  const exportPaymentVouchers = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported  Funds Advance/Withdraw Forms`,
        records: "paymentVouchers",
      })
    );
  };
  //use effect
  useEffect(() => {
    document.title = routes.finance.paymentVoucher.title;
    if (!paymentVouchersSuccess) {
      dispatch(paymentVoucherThunks.getPaymentVouchers());
      dispatch(paymentVoucherThunks.paymentVouchersMetaData());
    }
  }, [paymentVouchersSuccess, dispatch]);
  return (
    <div className="container-fluid p-2">
      <h3>Funds Advance/Withdraw Forms</h3>
      <PaymentVouchersTable
        handOpenEditModal={setEditPaymentVoucherVisible}
        handleExportRecords={exportPaymentVouchers}
        handleViewDetails={getPaymentVoucherDetails}
        handleDelete={deletePaymentVoucher}
        handleOpenAddModal={toggleAddPaymentVoucherModalVisible}
        handleRefreshTable={refreshData}
      />
      <AddPaymentVoucher
        handleAddRecord={addPaymentVoucher}
        visible={addPaymentVoucherModalVisible}
        handleCloseModal={toggleAddPaymentVoucherModalVisible}
      />
      <PaymentVoucherDetails
        handleApprove={approvePaymentVoucher}
        handleCheck={checkPaymentVoucher}
        handleCloseModal={togglePaymentVoucherDetailsModalVisible}
        visible={paymentVoucherDetailsModalVisible}
      />
      <EditPaymentVoucher
        handleCloseModal={setEditPaymentVoucherVisible}
        visible={editPaymentVoucherModalVisible}
        handleEditRecord={editPaymentVouchers}
      />
      <AddRejections />
    </div>
  );
};

export default PaymentVoucher;
