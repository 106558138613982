import { Modal, Form, Button, message, Alert, Select, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddLocalPurchaseOrderForm = ({
  visible,
  handleAddRecord,
  handleCloseModal,
}) => {
  const {
    addLocalPurchaseOrdersLoading,
    addLocalPurchaseOrdersMessage,
    addLocalPurchaseOrdersSuccess,
    addLocalPurchaseOrdersError,
    localPurchaseOrders,
    localPurchaseOrdersMetaData,
    localPurchaseOrdersMetaDataLoading,
  } = useSelector((state) => state.localPurchaseOrdersState);
  const [form] = Form.useForm();

  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values, localPurchaseOrders);
  };

  useEffect(() => {
    if (addLocalPurchaseOrdersSuccess && addLocalPurchaseOrdersMessage) {
      message.success(addLocalPurchaseOrdersMessage);
      form.resetFields();
    }
  }, [form, addLocalPurchaseOrdersSuccess, addLocalPurchaseOrdersMessage]);

  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="GENERATE LOCAL PURCHASE ORDER"
        visible={visible}
      >
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {addLocalPurchaseOrdersError && !addLocalPurchaseOrdersSuccess && (
            <Alert
              type="error"
              message={addLocalPurchaseOrdersError}
              showIcon
              className="m-2"
            />
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a Request For Qoutation No.",
              },
            ]}
            label="Rfq No."
            name="rfq"
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={localPurchaseOrdersMetaDataLoading}
            >
              {localPurchaseOrdersMetaData?.rfqs?.map((rfq) => (
                <Option key={rfq.id} value={rfq.id}>
                  {rfq.rfqno}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="discount"
            label="Discount %"
            rules={[
              { required: true, message: "Please Select Date Displayed" },
            ]}
          >
            <InputNumber min={0} max={100} className="w-100" />
          </Form.Item>
          <Form.Item
            name="goods_recieved_note_no"
            label="Goods Received Notes No."
            rules={[
              { required: true, message: "Please Select Date Displayed" },
            ]}
          >
            <InputNumber precision={0} className="w-100" />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 10 }}>
            <Button
              loading={addLocalPurchaseOrdersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddLocalPurchaseOrderForm;
