import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import AppDrawer from "../../components/shared/Drawers/AppDrawer";
import FileViewer from "../../components/shared/Files/FileViewer";
import Navbar from "../../components/shared/navbar/Navbar";
import {
  getAuthToken,
  removeAuthToken,
} from "../../config/helpers/auth/authToken";
import routes from "../../config/routes/routes";
import authThunks from "../../config/thunks/auth/auth.thunk";
import PageNotFound from "../App/404";

const MainLayout = () => {
  const { authenticated, authenticateUser } = useSelector(
    (state) => state.authState
  );
  const history = useHistory();
  const authToken = getAuthToken();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!navigator.onLine) {
      message.error("There semes to be a connection problem");
    }
    dispatch(authThunks.authenticateUser());
  }, [dispatch]);
  const { path } = routes.authentication.login;
  useEffect(() => {
    if (!authToken) history.push(path);
    else if (!authenticated && authenticateUser.error) {
      message.error(authenticateUser.error);
      removeAuthToken();
      history.push("/login");
    }
  }, [authenticated, authenticateUser.error, authToken, history, path]);

  return (
    <div className="bg-light min-vh-100">
      <Navbar />
      <AppDrawer />
      <FileViewer />
      <Switch>
        <Route
          exact
          path={routes.home.dashboard.path}
          component={routes.home.dashboard.component}
        />
        <Route
          path={routes.settings.path}
          component={routes.settings.component}
        />
        <Route
          path={routes.administration.path}
          component={routes.administration.component}
        />
        <Route
          path={routes.businesDevelopmentAndPartnerships.path}
          component={routes.businesDevelopmentAndPartnerships.component}
        />
        <Route
          path={routes.finance.path}
          component={routes.finance.component}
        />
        <Route
          path={routes.procurement.path}
          component={routes.procurement.component}
        />
        <Route
          exact
          path={routes.myProjects.path}
          component={routes.myProjects.component}
        />
        <Route
          exact
          path={routes.myProjects.myProjectDetails.path}
          component={routes.myProjects.myProjectDetails.component}
        />
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default MainLayout;
