import { Alert, Button, message, Modal, Space, Spin, Tag } from "antd";
import React, { useEffect, useRef } from "react";
import {
  AiFillCheckCircle,
  AiFillExclamationCircle,
  AiFillIeCircle,
  AiFillMinusCircle,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";
import appConfig from "../../../../config/config.json";
import PrintNoticeOfBestBidder from "../../../../containers/procurement/noticeOfBestBidder/PrintNoticeOfBestBidder";
import { useReactToPrint } from "react-to-print";

const NoticeOfBestBidderDetails = ({
  visible,
  handleCloseModal,
  handleApprove,
  handleCheck,
  handleRejection,
}) => {
  const {
    noticeOfBestBiddersDetails: data,
    noticeOfBestBiddersDetailsSuccess,
    noticeOfBestBiddersDetailsLoading,
    noticeOfBestBiddersDetailsError,
    approveNoticeOfBestBiddersLoading,
    approveNoticeOfBestBiddersError,
    approveNoticeOfBestBiddersSuccess,
    approveNoticeOfBestBiddersMessage,
    checkNoticeOfBestBiddersLoading,
    checkNoticeOfBestBiddersSuccess,
    checkNoticeOfBestBiddersMessage,
    checkNoticeOfBestBiddersError,
  } = useSelector((state) => state.noticeOfBestBidderState);
  const dispatch = useDispatch();

  const closeModal = () => handleCloseModal(false);
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);
  const reject = () => {
    handleRejection(true);
    dispatch(
      rejectionsActions.addProcurementRejectionsData({
        user_to_id: data?.created_by,
        item_application: "Notice Of Best Bidder",
        item_id: data?.id,
        itemName: `Notice Of Best Bidder ${data.id}`,
        applicationPath: "noticeOfBestBidders",
      })
    );
  };

  useEffect(() => {
    //approve
    if (approveNoticeOfBestBiddersSuccess && approveNoticeOfBestBiddersMessage)
      message.success(approveNoticeOfBestBiddersMessage);
    else if (
      !approveNoticeOfBestBiddersSuccess &&
      approveNoticeOfBestBiddersError
    )
      message.error(approveNoticeOfBestBiddersError);
    //check
    if (checkNoticeOfBestBiddersSuccess && checkNoticeOfBestBiddersMessage)
      message.success(checkNoticeOfBestBiddersMessage);
    else if (!checkNoticeOfBestBiddersSuccess && checkNoticeOfBestBiddersError)
      message.error(checkNoticeOfBestBiddersError);
  }, [
    approveNoticeOfBestBiddersError,
    approveNoticeOfBestBiddersMessage,
    approveNoticeOfBestBiddersSuccess,
    checkNoticeOfBestBiddersError,
    checkNoticeOfBestBiddersMessage,
    checkNoticeOfBestBiddersSuccess,
  ]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <div className="d-none">
        <div className="p-2" ref={componentRef}>
          <PrintNoticeOfBestBidder data={data} />
        </div>
      </div>
      <Modal
        title="NOTICE OF BEST BIDDER FORM"
        onCancel={closeModal}
        visible={visible}
        footer={[
          data.status !== appConfig.status.rejected &&
            data.status !== appConfig.status.approved &&
            noticeOfBestBiddersDetailsSuccess && (
              <Space>
                <Button
                  onClick={approveRecord}
                  loading={approveNoticeOfBestBiddersLoading}
                  type="primary"
                >
                  Approve
                </Button>
                {data.status !== appConfig.status.checked && (
                  <Button
                    onClick={checkRecord}
                    loading={checkNoticeOfBestBiddersLoading}
                  >
                    Check
                  </Button>
                )}
                <Button onClick={reject} danger>
                  Reject
                </Button>
              </Space>
            ),
          data.status === appConfig.status.approved && (
            <Button onClick={handlePrint}>Print</Button>
          ),
        ]}
      >
        {noticeOfBestBiddersDetailsLoading ? (
          <div classname="d-flex m-5 justify-content-center ">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {noticeOfBestBiddersDetailsSuccess ? (
              <div className="details_div">
                <table
                  id="paymentVoucherDetails"
                  className="table  table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Record Of Bidders Form No.</td>
                      <td>{data.id}</td>
                    </tr>
                    <tr>
                      <td>Rfq No.</td>
                      <td>{data.rfq}</td>
                    </tr>
                    <tr>
                      <td>Best Bidder.</td>
                      <td>{data.bestBidder?.name}</td>
                    </tr>
                    <tr>
                      <td>Date Displayed</td>
                      <td>{new Date(data.date_displayed).toDateString()}</td>
                    </tr>
                    <tr>
                      <td>Date Removed</td>
                      <td>{new Date(data.date_displayed).toDateString()}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {data.status === appConfig.status.approved && (
                          <Tag icon={<AiFillCheckCircle />} color="success">
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.checked && (
                          <Tag icon={<AiFillMinusCircle />} color="processing">
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.created && (
                          <Tag
                            icon={<AiFillExclamationCircle />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                        {data.status === appConfig.status.pending && (
                          <Tag
                            icon={<AiFillExclamationCircle />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                        {data.status === appConfig.status.rejected && (
                          <Tag icon={<AiFillIeCircle />} color="error">
                            Rejected
                          </Tag>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Unsuccessfull bidders</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Bidder</td>
                      <td>Reason</td>
                    </tr>
                    {data?.bidders?.map((bidder) => {
                      return (
                        <tr>
                          <td>{bidder.name}</td>
                          <td>
                            {bidder.reason ? bidder.reason : "Had High Price"}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked By</td>
                      <td>
                        {data.checker &&
                          `${data?.checker?.first_name} ${data?.checker?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked At</td>
                      <td>
                        {data.checked_at &&
                          `${new Date(
                            data.checked_at
                          ).toDateString()} ${new Date(
                            data.checked_at
                          ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved By</td>
                      <td>
                        {data.approver &&
                          `${data?.approver?.first_name} ${data?.approver?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved At</td>
                      <td>
                        {data.approved_at &&
                          `${new Date(
                            data.approved_at
                          ).toDateString()} ${new Date(
                            data.approved_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <Alert
                message={noticeOfBestBiddersDetailsError}
                type="error"
                showIcon
              />
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default NoticeOfBestBidderDetails;
