import React, { useState } from "react";
import { Menu } from "antd";
import {
  FcConferenceCall,
  FcDocument,
  FcFilingCabinet,
  FcFolder,
} from "react-icons/fc";
import { Link } from "react-router-dom";
import routes from "../../../config/routes/routes";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const AdministrationMenu = () => {
  const [collapsed, setcollapsed] = useState(true);

  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);

  const toggleCollapsed = () => {
    setcollapsed(!collapsed);
  };
  return (
    <div className="d-none d-lg-block">
      <Menu
        className="py-2"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="0" className="text-start" onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Menu.Item>
        {viewPermissions?.Staff_Records && (
          <Menu.Item key="3" icon={<FcConferenceCall />}>
            <Link to={routes.administration.staffRecords.path}>
              Staff Records
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Asset_register && (
          <Menu.Item key="1" icon={<FcFilingCabinet />}>
            <Link to={routes.administration.assetRegister.assets.path}>
              Asset Register
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Stores_Register && (
          <Menu.Item key="4" icon={<FcFolder />}>
            <Link to={routes.administration.storesRegister.path}>
              Stores Register
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Manuals_and_policies && (
          <Menu.Item key="5" icon={<FcDocument />}>
            <Link to={routes.administration.manualsAndPolicies.path}>
              Manuals And Policies
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default AdministrationMenu;
