import { Alert, Button, Form, message, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const AddBudget = ({ handleAddRecord }) => {
  const {
    addBudgetSuccess,
    addBudgetLoading,
    addBudgetError,
    addBudgetMessage,
    metaData,
    metaDataLoading,
  } = useSelector((state) => state.budgetState);
  const { addBudgetModal: visible } = useSelector((state) => state.AppUi);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const closeModal = () => {
    dispatch(appUiActions.toggleAddBudgetModal(false));
  };
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values);
  };
  useEffect(() => {
    if (addBudgetSuccess && addBudgetMessage) {
      message.success(addBudgetMessage);
      form.resetFields();
    }
  }, [addBudgetMessage, addBudgetSuccess, form]);
  return (
    <div>
      <Modal
        visible={visible}
        onCancel={closeModal}
        footer={null}
        title="ADD PROJECT BUDGET"
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          {!addBudgetSuccess && addBudgetError && (
            <Alert
              className="my-2"
              showIcon
              type="error"
              message={addBudgetError}
            />
          )}
          <Form.Item
            name="project"
            label="Project"
            rules={[
              { required: true, message: "Please select a person incharge" },
            ]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={metaDataLoading}
            >
              {metaData?.projects?.map((project, id) => {
                return (
                  <Select.Option value={project.id} key={project.id}>
                    {`${project.name}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button loading={addBudgetLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddBudget;
