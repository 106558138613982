import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RolesDetails from "../../components/settings/Roles/Details/RolesDetails";
import AddNewRole from "../../components/settings/Roles/Forms/AddNewRole";
import EditRole from "../../components/settings/Roles/Forms/EditRole";
import RolesTable from "../../components/settings/Roles/Tables/RolesTable";
import routes from "../../config/routes/routes";
import rolesThunks from "../../config/thunks/settings/roles.thunks";

const Roles = () => {
  const {
    error: getRolesError,
    success: getRolesSuccess,
    data: rolesArray,
  } = useSelector((state) => state.rolesState.getRoles);
  const { error: deleteRoleError, success: deleteRoleSuccess } = useSelector(
    (state) => state.rolesState.deleteRole
  );
  const { success: checkRoleSuccess, message: checkRolesMessage } = useSelector(
    (state) => state.rolesState.checkRoles
  );
  const { success: approveRoleSuccess, message: approveRoleMessage } =
    useSelector((state) => state.rolesState.approveRoles);
  const approveRole = (id) => {
    dispatch(rolesThunks.approveRoles(id));
  };
  const checkRoles = (id) => {
    dispatch(rolesThunks.checkRoles(id));
  };

  const addNewRole = (values) => {
    dispatch(rolesThunks.addRole(values));
  };
  const deleteRole = (id) => {
    const newRolesArray = rolesArray.filter((e) => e.id !== id);
    dispatch(rolesThunks.deleteRole(id, newRolesArray));
  };

  const updateRole = (values) => {
    const filteredArray = rolesArray.filter((e) => e.id !== values.id);
    const updatedArray = [values, ...filteredArray];
    dispatch(rolesThunks.updateRole(values, values.id, updatedArray));
  };
  const dispatch = useDispatch();
  //getRoles
  useEffect(() => {
    document.title = routes.settings.roles.title;
    if (!getRolesSuccess) dispatch(rolesThunks.getAllRoles());
    if (!getRolesSuccess && getRolesError) message.error(getRolesError);
  }, [dispatch, getRolesSuccess, getRolesError]);
  //deleteRole
  useEffect(() => {
    if (deleteRoleError && !deleteRoleSuccess) message.error(deleteRoleError);
    else if (deleteRoleSuccess) message.success("Role has been deleted");
  }, [deleteRoleError, deleteRoleSuccess]);
  //check and approve roles
  useEffect(() => {
    if (approveRoleSuccess) message.success(approveRoleMessage);
    if (checkRoleSuccess) message.success(checkRolesMessage);
  }, [
    approveRoleSuccess,
    approveRoleMessage,
    checkRoleSuccess,
    checkRolesMessage,
  ]);

  return (
    <div className="container-fluid p-2">
      <h3>System Roles </h3>
      <RolesTable handleDeleteRole={deleteRole} />
      <AddNewRole handledAddRole={addNewRole} />
      <EditRole handleEditRole={updateRole} />
      <RolesDetails handleApprove={approveRole} handleCheck={checkRoles} />
    </div>
  );
};

export default Roles;
