import appConfig from "../../../config.json";
import { getAuthToken } from "../../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;

const storesRegisterApiRequests = {
  fetchStoresRegister: async () => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  addStoresRegisterReceipt: async (body) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/receipts`,
        {
          method: "POST",
          headers: {
            authorization,
            accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  addStoresRegisterIssue: async (body) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/issues`,
        {
          method: "POST",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  updateStoresRegisterReceipt: async (body, id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/receipts/${id}`,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            "Content-type": "application/json",
            authorization,
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  updateStoresRegisterIssue: async (body, id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/issues/${id}`,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            "Content-type": "application/json",
            authorization,
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  deleteStoresRegisterReceipt: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/receipts/${id}`,
        {
          method: "DELETE",
          headers: {
            authorization,
          },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  deleteStoresRegisterIssues: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/issues/${id}`,
        {
          method: "DELETE",
          headers: {
            authorization,
          },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  approveStoresRegisterReceipt: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/receipts/approve/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ approvedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  approveStoresRegisterIssue: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/issues/approve/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ approvedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  checkStoresRegisterIssue: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/issues/check/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ checkedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  checkStoresRegisterReceipt: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/receipts/check/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ checkedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  storesRegisterReceiptsDetails: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/receipts/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  storesRegisterIssuesDetails: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/administration/storesRegister/issues/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
};

export default storesRegisterApiRequests;
