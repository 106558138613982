import React from "react";
import { Route, Switch } from "react-router-dom";
import BusinessDevMenu from "../../components/BusinessDevelopmentAndPartnerships/menus/BusinessDevMenu";
import routes from "../../config/routes/routes";
import PageNotFound from "../App/404";

const BusinessDevAndPartnerShipsLayout = () => {
  return (
    <div className="d-flex">
      <BusinessDevMenu />
      <Switch>
        <Route
          exact
          path={routes.businesDevelopmentAndPartnerships.donors.path}
          component={routes.businesDevelopmentAndPartnerships.donors.component}
        />
        <Route
          exact
          path={
            routes.businesDevelopmentAndPartnerships.donors.donorDetails.path
          }
          component={
            routes.businesDevelopmentAndPartnerships.donors.donorDetails
              .component
          }
        />
        <Route
          exact
          path={routes.businesDevelopmentAndPartnerships.projects.path}
          component={
            routes.businesDevelopmentAndPartnerships.projects.component
          }
        />
        <Route
          exact
          path={
            routes.businesDevelopmentAndPartnerships.projects.projectDetails
              .path
          }
          component={
            routes.businesDevelopmentAndPartnerships.projects.projectDetails
              .component
          }
        />
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default BusinessDevAndPartnerShipsLayout;
