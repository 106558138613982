import { Modal, Form, Button, Alert, Input, DatePicker, message } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const EditRfq = ({ visible, handleCloseModal, handleEditRecord }) => {
  const {
    editRfqsLoading,
    editRfqsMessage,
    editRfqsSuccess,
    editRfqsError,
    editRfqsData,
    rfqs,
  } = useSelector((state) => state.rfqsState);
  const closeModal = () => handleCloseModal(false);
  const [form] = Form.useForm();
  const fields = [
    { name: "rfqno", value: editRfqsData.rfqno },
    {
      name: "deadline_of_submission",
      value: moment(editRfqsData.deadline_of_submission),
    },
    { name: "subject", value: editRfqsData.subject },
  ];
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = editRfqsData.id;
    const rfqsArray = rfqs.filter((rfq) => rfq.id !== editRfqsData.id);
    handleEditRecord(values, rfqsArray);
  };
  useEffect(() => {
    if (editRfqsSuccess && editRfqsMessage) {
      message.success(editRfqsMessage);
      handleCloseModal(false);
    }
  }, [editRfqsMessage, editRfqsSuccess, handleCloseModal]);
  return (
    <div>
      <Modal
        visible={visible}
        title={`EDIT RFQ FOR REQUISITION FORM- ${editRfqsData.requisition_memo}`}
        footer={null}
        onCancel={closeModal}
      >
        <Form
          fields={fields}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!editRfqsSuccess && editRfqsError && (
            <Alert type="error" showIcon message={editRfqsError} />
          )}
          <Form.Item
            label="RFQ No."
            rules={[{ required: true, message: "Please add an Rfq No." }]}
            name="rfqno"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Deadline Of Submission"
            rules={[{ required: true, message: "Please add an Rfq No." }]}
            name="deadline_of_submission"
          >
            <DatePicker className="w-100" />
          </Form.Item>
          <Form.Item
            label="Subject Of Procurement"
            name="subject"
            rules={[{ required: true, message: "Please add a subject" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={editRfqsLoading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditRfq;
