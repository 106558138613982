import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocalPurchaseOrdersTable from "../../../components/Procurement/localPurchaseOrders/Tables/LocalPurchaseOrdersTables";
import routes from "../../../config/routes/routes";
import localPurchaseOrdersThunks from "../../../config/thunks/procurement/localPurchaseOrders/localPurchaseOrders.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";
import AddLocalPurchaseOrderForm from "../../../components/Procurement/localPurchaseOrders/Forms/AddLocalPurchaseOrders";
import EditLocalPurchaseOrderForm from "../../../components/Procurement/localPurchaseOrders/Forms/EditLocalPurchaseOrder";
import LocalPurchaseOrderDetails from "../../../components/Procurement/localPurchaseOrders/Details/LocalPurchaseOrderDetails";
import AddLocalPurchaseOrderItem from "../../../components/Procurement/localPurchaseOrders/Forms/AddLocaPurchaseOrderItem";
import EditLocalPurchaseOrderItem from "../../../components/Procurement/localPurchaseOrders/Forms/EditLocalPurchaseOrderItem";
import LocalPurchaseOrderItemDetails from "../../../components/Procurement/localPurchaseOrders/Details/LocalPurchaseOrderItemDetails";
import AddProcurementRejections from "../../../components/shared/Rejections/procuremnet/AddProcurementRejections";

const LocalPurchaseOrder = () => {
  //reducer state intialization
  const { localPurchaseOrdersSuccess } = useSelector(
    (state) => state.localPurchaseOrdersState
  );
  const dispatch = useDispatch();
  //ui state
  const [
    addLocalPurchaseOrderModalVisible,
    setAddLocalPurchaseOrderModalVisible,
  ] = useState(false);
  const [
    addLocalPurchaseOrderItemModalVisible,
    setAddLocalPurchaseOrderItemModalVisible,
  ] = useState(false);
  const [
    editLocalPurchaseOrderItemModalVisible,
    setEditLocalPurchaseOrderItemModalVisible,
  ] = useState(false);
  const [
    editLocalPurchaseOrderModalVisible,
    setEditLocalPurchaseOrderModalVisible,
  ] = useState(false);
  const [
    localPurchaseOrderDetailsModalVisible,
    setLocalPurchaseOrderDetailsModalVisible,
  ] = useState(false);
  const [
    localPurchaseOrderItemDetailsModalVisible,
    setLocalPurchaseOrItemderDetailsModalVisible,
  ] = useState(false);
  const [addRejectionModalVisible, setAddRejectionModalVisible] =
    useState(false);
  //thunks
  const refreshLocalPurchaseOrders = () => {
    dispatch(localPurchaseOrdersThunks.getLocalPurchaseOrders());
    dispatch(localPurchaseOrdersThunks.getLocalPurchaseOrdersMetaData());
  };
  const refreshLocalPurchaseOrderItems = (id) => {
    dispatch(localPurchaseOrdersThunks.getLpoItems(id));
  };
  const addLocalPurchaseOrders = (values, localPurchaseOrders) => {
    dispatch(
      localPurchaseOrdersThunks.addLocalPurchaseOrders(
        values,
        localPurchaseOrders
      )
    );
  };
  const addLocalPurchaseOrderItem = (values, localPurchaseOrdersItems) => {
    dispatch(
      localPurchaseOrdersThunks.addLpoItems(values, localPurchaseOrdersItems)
    );
  };
  const editLocalPurchaseOrderItem = (values) => {
    dispatch(localPurchaseOrdersThunks.editLpoItems(values));
  };
  const editLocalPurchaseOrders = (values, localPurchaseOrders) => {
    dispatch(
      localPurchaseOrdersThunks.editLocalPurchaseOrder(
        values,
        localPurchaseOrders
      )
    );
  };
  const deleteLocalPurchaseOrder = (values, localPurchaseOrders) => {
    dispatch(
      localPurchaseOrdersThunks.deleteLocalPurchaseOrders(
        values,
        localPurchaseOrders
      )
    );
  };
  const deleteLocalPurchaseOrderItem = (id, local_purchase_order) => {
    dispatch(
      localPurchaseOrdersThunks.deleteLpoItems(id, local_purchase_order)
    );
  };
  const submitLocalPurchaseOrder = (id) => {
    dispatch(localPurchaseOrdersThunks.submitLocalPurchaseOrders(id));
  };
  const checkLocalPurchaseOrder = (id) => {
    dispatch(localPurchaseOrdersThunks.checkLocalPurchaseOrders(id));
  };

  const approveLocalPurchaseOrder = (id) => {
    dispatch(localPurchaseOrdersThunks.approveLocalPurchaseOrders(id));
  };

  const getLocalPurchaseOrderDetails = (id) => {
    setLocalPurchaseOrderDetailsModalVisible(true);
    dispatch(localPurchaseOrdersThunks.getLpoItems(id));
    dispatch(localPurchaseOrdersThunks.getLocalPurchaseOrdersDetails(id));
  };
  const getLocalPurchaseOrderItemDetails = (id) => {
    setLocalPurchaseOrItemderDetailsModalVisible(true);
    dispatch(localPurchaseOrdersThunks.getLpoItemsDetails(id));
  };
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported Local Purchase Orders`,
        records: "localPurchaseOrders",
      })
    );
  };
  //useEffects
  useEffect(() => {
    document.title = routes.procurement.localPurchaseOrder.title;
    if (!localPurchaseOrdersSuccess) {
      dispatch(localPurchaseOrdersThunks.getLocalPurchaseOrders());
      dispatch(localPurchaseOrdersThunks.getLocalPurchaseOrdersMetaData());
    }
  }, [localPurchaseOrdersSuccess, dispatch]);
  return (
    <div className="p-2 container-fluid">
      <h3>Local Purchase Orders</h3>
      <LocalPurchaseOrdersTable
        handleViewDetails={getLocalPurchaseOrderDetails}
        handleDelete={deleteLocalPurchaseOrder}
        handleExportRecords={exportRecords}
        handleRefreshTable={refreshLocalPurchaseOrders}
        handleOpenAddModal={setAddLocalPurchaseOrderModalVisible}
        handleOpenEditModal={setEditLocalPurchaseOrderModalVisible}
      />
      <AddLocalPurchaseOrderForm
        visible={addLocalPurchaseOrderModalVisible}
        handleCloseModal={setAddLocalPurchaseOrderModalVisible}
        handleAddRecord={addLocalPurchaseOrders}
      />
      <EditLocalPurchaseOrderForm
        handleEditRecord={editLocalPurchaseOrders}
        handleCloseModal={setEditLocalPurchaseOrderModalVisible}
        visible={editLocalPurchaseOrderModalVisible}
      />
      <LocalPurchaseOrderDetails
        handleRejection={setAddRejectionModalVisible}
        handleSubmit={submitLocalPurchaseOrder}
        handleCheck={checkLocalPurchaseOrder}
        handleApprove={approveLocalPurchaseOrder}
        handleViewItemDetails={getLocalPurchaseOrderItemDetails}
        handleOpenEditModal={setEditLocalPurchaseOrderItemModalVisible}
        deleteLocalPurchaseOrderItem={deleteLocalPurchaseOrderItem}
        handleRefreshLpoItems={refreshLocalPurchaseOrderItems}
        handleOpenAddModal={setAddLocalPurchaseOrderItemModalVisible}
        handleCloseModal={setLocalPurchaseOrderDetailsModalVisible}
        visible={localPurchaseOrderDetailsModalVisible}
      />
      <AddLocalPurchaseOrderItem
        handleAddRecord={addLocalPurchaseOrderItem}
        handleCloseModal={setAddLocalPurchaseOrderItemModalVisible}
        visible={addLocalPurchaseOrderItemModalVisible}
      />
      <EditLocalPurchaseOrderItem
        visible={editLocalPurchaseOrderItemModalVisible}
        handleCloseModal={setEditLocalPurchaseOrderItemModalVisible}
        handleEditRecord={editLocalPurchaseOrderItem}
      />
      <LocalPurchaseOrderItemDetails
        handleCloseModal={setLocalPurchaseOrItemderDetailsModalVisible}
        visible={localPurchaseOrderItemDetailsModalVisible}
      />
      <AddProcurementRejections
        handleCloseModal={setAddRejectionModalVisible}
        visible={addRejectionModalVisible}
      />
    </div>
  );
};

export default LocalPurchaseOrder;
