import { Modal, Form, Button, message, Alert, Select } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddBidEvaluationForm = ({
  visible,
  handleAddRecord,
  handleCloseModal,
}) => {
  const {
    addBidEvaluationsLoading,
    addBidEvaluationsMessage,
    addBidEvaluationsSuccess,
    addBidEvaluationsError,
    bidEvaluations,
    bidEvaluationsMetaData,
    bidEvaluationsMetaDataLoading,
  } = useSelector((state) => state.bidEvaluationState);
  const [form] = Form.useForm();

  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values, bidEvaluations);
  };

  useEffect(() => {
    if (addBidEvaluationsSuccess && addBidEvaluationsMessage) {
      message.success(addBidEvaluationsMessage);
      form.resetFields();
    }
  }, [form, addBidEvaluationsSuccess, addBidEvaluationsMessage]);

  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="GENERATE BID EVALUATION FORM"
        visible={visible}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {addBidEvaluationsError && !addBidEvaluationsSuccess && (
            <Alert
              type="error"
              message={addBidEvaluationsError}
              showIcon
              className="m-2"
            />
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a Request For Qoutation No.",
              },
            ]}
            label="Rfq No."
            name="rfq"
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={bidEvaluationsMetaDataLoading}
            >
              {bidEvaluationsMetaData?.rfqs?.map((rfq) => (
                <Option key={rfq.id} value={rfq.id}>
                  {rfq.rfqno}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="evaluator_1"
            label="Evaluator 1"
            rules={[{ required: true, message: "Please Select Evaluator 1" }]}
          >
            <Select
              allowClear
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={bidEvaluationsMetaDataLoading}
            >
              {bidEvaluationsMetaData?.evaluators?.map((user, id) => {
                return (
                  <Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="evaluator_2"
            label="Evaluator 2"
            rules={[{ required: true, message: "Please Select Evaluator 2" }]}
          >
            <Select
              allowClear
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={bidEvaluationsMetaDataLoading}
            >
              {bidEvaluationsMetaData?.evaluators?.map((user, id) => {
                return (
                  <Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="evaluator_3"
            label="Evaluator 3"
            rules={[{ required: true, message: "Please Select Evaluator 3" }]}
          >
            <Select
              allowClear
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={bidEvaluationsMetaDataLoading}
            >
              {bidEvaluationsMetaData?.evaluators?.map((user, id) => {
                return (
                  <Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 6 }}>
            <Button
              loading={addBidEvaluationsLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddBidEvaluationForm;
