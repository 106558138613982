import React from "react";
import { Route, Switch } from "react-router-dom";
import SettingsMenu from "../../components/settings/Menus/SettingsMenu";
import routes from "../../config/routes/routes";
import PageNotFound from "../App/404";

const SettingsLayout = () => {
  return (
    <div className="d-flex">
      <SettingsMenu />
      <Switch>
        <Route
          exact
          path={routes.settings.logDetails.path}
          component={routes.settings.logDetails.component}
        />
        <Route
          exact
          path={routes.settings.roles.path}
          component={routes.settings.roles.component}
        />
        <Route
          exact
          path={routes.settings.permissions.path}
          component={routes.settings.permissions.component}
        />
        <Route
          exact
          path={routes.settings.profile.path}
          component={routes.settings.profile.component}
        />
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default SettingsLayout;
