import apiConfig from "../../../config.json";
import { getAuthToken } from "../../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;

export const fetchAssetsRequest = async () => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/`,
      {
        headers: {
          authorization,
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const addAssetRequest = async (body) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-type": "application/json",
          authorization,
        },
        body: JSON.stringify(body),
      }
    );
    return res.json().then((res) => res);
  } catch (error) {
    console.error(error);
  }
};

export const updateAssetRequest = async (body, id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/${id}`,
      {
        method: "PATCH",
        headers: {
          authorization,
          "Content-type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const deleteAssetRequest = async (id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/${id}`,
      {
        method: "DELETE",
        headers: {
          authorization,
          "Content-type": "application/json",
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const fetchAssetsCategoryRequest = async () => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/categories`,
      {
        headers: {
          authorization,
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const addAssetCategoryRequest = async (body) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/categories`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-type": "application/json",
          authorization,
        },
        body: JSON.stringify(body),
      }
    );
    return res.json().then((res) => res);
  } catch (error) {
    console.error(error);
  }
};

export const updateAssetCategoryRequest = async (body, id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/categories/${id}`,
      {
        method: "PATCH",
        headers: {
          authorization,
          "Content-type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const deleteAssetCategoryRequest = async (id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/categories/${id}`,
      {
        method: "DELETE",
        headers: {
          authorization,
          "Content-type": "application/json",
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const fetchAssetDetailsRequest = async (id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/${id}`,
      {
        headers: {
          authorization,
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};
export const fetchAssetTypesDetailsRequest = async (id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/categories/${id}`,
      {
        headers: {
          authorization,
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const approveAssetRequest = async (id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/approve/${id}`,
      {
        method: "PATCH",
        headers: {
          authorization,
          "content-type": "application/json",
        },
        body: JSON.stringify({ approvedAt: new Date() }),
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const approveAssetTypeRequest = async (id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/categories/approve/${id}`,
      {
        method: "PATCH",
        headers: {
          authorization,
          "content-type": "application/json",
        },
        body: JSON.stringify({ approvedAt: new Date() }),
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const checkAssetTypesRequest = async (id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/categories/check/${id}`,
      {
        method: "PATCH",
        headers: {
          authorization,
          "content-type": "application/json",
        },
        body: JSON.stringify({ checkedAt: new Date() }),
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const checkAssetRequest = async (id) => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/check/${id}`,
      {
        method: "PATCH",
        headers: {
          authorization,
          "content-type": "application/json",
        },
        body: JSON.stringify({ checkedAt: new Date() }),
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const getStaffRecordsRequest = async () => {
  try {
    const res = await fetch(
      `${apiConfig.server_api_url}/administration/assetRegister/metaData`,
      {
        headers: {
          authorization,
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};
