import apiConfig from "../../../config.json";
import { getAuthToken } from "../../../helpers/auth/authToken";
import axios from "axios";
import fileDownload from "js-file-download";

const authorization = `Bearer ${getAuthToken()}`;

const errorResponseObject = {
  success: false,
  error: "Sorry there seems to be an error please try again",
};

const manualsAndPoliciesApiRequests = {
  fetchManualsAndPolicies: async () => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/manualsAndPolicies`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Manuals and policies fetch error", error);
      return errorResponseObject;
    }
  },
  addManualsAndPolicies: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/manualsAndPolicies`,
        {
          method: "POST",
          headers: {
            authorization,
          },
          body: body,
        }
      );
      return res.json();
    } catch (error) {
      console.error("Manuals and policies fetch error", error);
      return errorResponseObject;
    }
  },
  updateManualsAndPolicies: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/manualsAndPolicies/${body.id}`,
        {
          method: "PUT",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Manuals and policies add error", error);
      return errorResponseObject;
    }
  },
  deleteManualsAndPolicies: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/manualsAndPolicies/${id}`,
        {
          method: "DELETE",
          headers: {
            authorization,
          },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Manuals and policies delete error", error);
      return errorResponseObject;
    }
  },
  fetchManualsAndPoliciesDetails: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/manualsAndPolicies/${id}`,
        {
          headers: {
            authorization,
          },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Manuals and policies details error", error);
      return errorResponseObject;
    }
  },
  checkManualAndPolicies: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/manualsAndPolicies/check/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            "content-type": "application/json",
          },
          body: JSON.stringify({ checkedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Manuals and policies check error", error);
      return errorResponseObject;
    }
  },
  approveManualAndPolicies: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/manualsAndPolicies/approve/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            "content-type": "application/json",
          },
          body: JSON.stringify({ approvedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Manuals and policies check error", error);
      return errorResponseObject;
    }
  },
  downloadManualAndPolicies: async (file_path, fileName) => {
    try {
      const res = await axios.get(
        `${apiConfig.server_api_url}/administration/manualsAndPolicies/download/${file_path}`,
        {
          headers: {
            authorization,
          },
          responseType: "blob",
        }
      );
      fileDownload(res.data, fileName);
      if (res.status === 200) {
        return { success: true, message: "Started download" };
      } else return errorResponseObject;
    } catch (error) {
      console.error("Manuals and policies check error", error);
      return errorResponseObject;
    }
  },
};

export default manualsAndPoliciesApiRequests;
