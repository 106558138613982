import { manualsAndPoliciesActions } from "../../../actions/administration/manualsAndPolicies/manualsAndPolicies.actions";
import { appUiActions } from "../../../actions/app/appUi.actions";
import manualsAndPoliciesApiRequests from "../../../api/administration/manualsAndPolicies/manualsAndPolicies.api";

const manualsAndPoliciesThunks = {
  getManualsAndPolicies: () => async (dispatch) => {
    dispatch(manualsAndPoliciesActions.setManualsAndPoliciesRequest());
    const res = await manualsAndPoliciesApiRequests.fetchManualsAndPolicies();
    if (res.success)
      dispatch(
        manualsAndPoliciesActions.setManualsAndPoliciesSuccess(res.data)
      );
    else
      dispatch(manualsAndPoliciesActions.setManualsAndPoliciesError(res.error));
  },
  addManualsAndPolicies: (body) => async (dispatch) => {
    dispatch(manualsAndPoliciesActions.addManualsAndPoliciesRequest());
    const res = await manualsAndPoliciesApiRequests.addManualsAndPolicies(body);
    if (res.success) {
      dispatch(
        manualsAndPoliciesActions.addManualsAndPoliciesSuccess({
          message: res.message,
          data: res.data,
        })
      );
      dispatch(appUiActions.toggleAddManualsAndPoliciesModal(false));
      dispatch(manualsAndPoliciesActions.addManualsAndPoliciesCompleted());
    } else
      dispatch(manualsAndPoliciesActions.addManualsAndPoliciesError(res.error));
  },
  deleteManualsAndPolicies:
    (id, manualAndPoliciesArray) => async (dispatch) => {
      dispatch(manualsAndPoliciesActions.deleteManualsAndPoliciesRequest());
      const res = await manualsAndPoliciesApiRequests.deleteManualsAndPolicies(
        id
      );
      if (res.success)
        dispatch(
          manualsAndPoliciesActions.deleteManualsAndPoliciesSuccess({
            message: res.message,
            data: manualAndPoliciesArray,
          })
        );
      else
        dispatch(
          manualsAndPoliciesActions.deleteManualsAndPoliciesError(res.error)
        );
      dispatch(manualsAndPoliciesActions.deleteManualsAndPoliciesCompleted());
    },
  updateManualsAndPolicies:
    (body, manualAndPoliciesArray) => async (dispatch) => {
      dispatch(manualsAndPoliciesActions.updateManualsAndPoliciesRequest());
      const res = await manualsAndPoliciesApiRequests.updateManualsAndPolicies(
        body
      );
      if (res.success) {
        dispatch(
          manualsAndPoliciesActions.updateManualsAndPoliciesSuccess({
            message: res.message,
            data: [res.data, ...manualAndPoliciesArray],
          })
        );
        dispatch(appUiActions.toggleEditManualsAndPoliciesModal(false));
        dispatch(manualsAndPoliciesActions.updateManualsAndPoliciesCompleted());
      } else
        dispatch(
          manualsAndPoliciesActions.updateManualsAndPoliciesError(res.error)
        );
    },
  approveManualsAndPolicies: (id) => async (dispatch) => {
    dispatch(manualsAndPoliciesActions.approveManualsAndPoliciesRequest());
    const res = await manualsAndPoliciesApiRequests.approveManualAndPolicies(
      id
    );
    if (res.success) {
      dispatch(
        manualsAndPoliciesActions.approveManualsAndPoliciesSuccess(res.message)
      );
      dispatch(manualsAndPoliciesThunks.getManualsAndPoliciesDetails(id));
    } else {
      dispatch(
        manualsAndPoliciesActions.approveManualsAndPoliciesError(res.error)
      );
    }
    dispatch(manualsAndPoliciesActions.approveManualsAndPoliciesCompleted());
  },
  checkManualsAndPolicies: (id) => async (dispatch) => {
    dispatch(manualsAndPoliciesActions.checkManualsAndPoliciesRequest());
    const res = await manualsAndPoliciesApiRequests.checkManualAndPolicies(id);
    if (res.success) {
      dispatch(
        manualsAndPoliciesActions.checkManualsAndPoliciesSuccess(res.message)
      );
      dispatch(manualsAndPoliciesThunks.getManualsAndPoliciesDetails(id));
    } else
      dispatch(
        manualsAndPoliciesActions.checkManualsAndPoliciesError(res.error)
      );
    dispatch(manualsAndPoliciesActions.checkManualsAndPoliciesCompleted());
  },
  downloadManualsAndPolicies: (id, name) => async (dispatch) => {
    dispatch(manualsAndPoliciesActions.downloadManualsAndPoliciesRequest());
    const res = await manualsAndPoliciesApiRequests.downloadManualAndPolicies(
      id,
      name
    );
    if (res.success) {
      dispatch(
        manualsAndPoliciesActions.downloadManualsAndPoliciesSuccess(res.message)
      );
      dispatch(manualsAndPoliciesActions.downloadManualsAndPoliciesCompleted());
    } else
      dispatch(
        manualsAndPoliciesActions.downloadManualsAndPoliciesError(res.error)
      );
  },
  getManualsAndPoliciesDetails: (id) => async (dispatch) => {
    dispatch(manualsAndPoliciesActions.setManualsAndPoliciesDetailsRequest());
    const res =
      await manualsAndPoliciesApiRequests.fetchManualsAndPoliciesDetails(id);
    if (res.success)
      dispatch(
        manualsAndPoliciesActions.setManualsAndPoliciesDetailsSuccess(res.data)
      );
    else
      dispatch(
        manualsAndPoliciesActions.setManualsAndPoliciesDetailsError(res.error)
      );
  },
};

export default manualsAndPoliciesThunks;
