import { Alert, Button, Form, Input, InputNumber, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { chartOfAccountsActions } from "../../../../config/actions/finance/chartOfAccounts/chartOfAccounts.actions";

const EditAccountType = ({ handleEditRecord }) => {
  const {
    editAccountTypeSuccess,
    editAccountTypeError,
    editAccountTypeMessage,
    editAccountTypeLoading: loading,
    editAccountTypeData: data,
  } = useSelector((state) => state.chartOfAccountsState);
  const { editAccountTypesModal: visible } = useSelector(
    (state) => state.AppUi
  );
  const fields = [
    { name: "name", value: data.name },
    { name: "account_number", value: data.account_number },
    { name: "details", value: data.details },
  ];
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleEditChartOfAccountsAccountTypeModal(false));
  };
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = data.id;
    dispatch(chartOfAccountsActions.setEditAccountTypeData(values));
    handleEditRecord(values);
  };
  useEffect(() => {
    if (editAccountTypeSuccess && editAccountTypeMessage) {
      message.success(editAccountTypeMessage);
      dispatch(appUiActions.toggleEditChartOfAccountsAccountTypeModal(false));
    }
  }, [editAccountTypeSuccess, editAccountTypeMessage, dispatch]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        footer={null}
        visible={visible}
        title="EDIT ACCOUNT TYPE"
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={onFinish}
          fields={fields}
        >
          {!editAccountTypeSuccess && editAccountTypeError && (
            <Alert
              className="m-2"
              showIcon
              type="error"
              message={editAccountTypeError}
            />
          )}
          <Form.Item
            name="name"
            label="Account Type Name"
            rules={[{ required: true, message: "Please add an account name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="account_number"
            label="Code"
            rules={[
              { required: true, message: "Please add an account number" },
            ]}
          >
            <InputNumber className="w-100" />
          </Form.Item>
          <Form.Item name="details" label="Details">
            <TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditAccountType;
