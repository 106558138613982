import { Modal, Form, Button, message, Alert, Select, DatePicker } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddNoticeOfBestBidderForm = ({
  visible,
  handleAddRecord,
  handleCloseModal,
}) => {
  const {
    addNoticeOfBestBiddersLoading,
    addNoticeOfBestBiddersMessage,
    addNoticeOfBestBiddersSuccess,
    addNoticeOfBestBiddersError,
    noticeOfBestBidders,
    noticeOfBestBiddersMetaData,
    noticeOfBestBiddersMetaDataLoading,
  } = useSelector((state) => state.noticeOfBestBidderState);
  const [form] = Form.useForm();

  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values, noticeOfBestBidders);
  };

  useEffect(() => {
    if (addNoticeOfBestBiddersSuccess && addNoticeOfBestBiddersMessage) {
      message.success(addNoticeOfBestBiddersMessage);
      form.resetFields();
    }
  }, [form, addNoticeOfBestBiddersSuccess, addNoticeOfBestBiddersMessage]);

  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="GENERATE NOTICE OF BEST BIDDER FORM"
        visible={visible}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {addNoticeOfBestBiddersError && !addNoticeOfBestBiddersSuccess && (
            <Alert
              type="error"
              message={addNoticeOfBestBiddersError}
              showIcon
              className="m-2"
            />
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a Request For Qoutation No.",
              },
            ]}
            label="Rfq No."
            name="rfq"
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={noticeOfBestBiddersMetaDataLoading}
            >
              {noticeOfBestBiddersMetaData?.rfqs?.map((rfq) => (
                <Option key={rfq.id} value={rfq.id}>
                  {rfq.rfqno}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="date_displayed"
            label="Date Displayed"
            rules={[
              { required: true, message: "Please Select Date Displayed" },
            ]}
          >
            <DatePicker className="w-100" />
          </Form.Item>
          <Form.Item
            name="date_removed"
            label="Date Removed 1"
            rules={[{ required: true, message: "Please Select  Date Removed" }]}
          >
            <DatePicker className="w-100" />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 6 }}>
            <Button
              loading={addNoticeOfBestBiddersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddNoticeOfBestBidderForm;
