import { Modal, Form, Select, Button, message, Alert, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requisitionMemosActions } from "../../../../config/actions/finance/requisitionMemo/requsitionMemo.actions";
import appConfig from "../../../../config/config.json";

const { Option } = Select;

const EditRequsitionMemo = ({
  visible,
  handleEditRecord,
  handleCloseModal,
}) => {
  const {
    editRequisitionMemoSuccess,
    editRequisitionMemoLoading,
    editRequisitionMemoError,
    editRequisitionMemoMessage,
    editRequisitionMemoData: data,
    requisitionMemos,
  } = useSelector((state) => state.requisitionMemoState);
  const fields = [
    { value: data.type, name: "type" },
    { name: "details", value: data.details },
  ];
  const { approvedBudgetLoading } = useSelector((state) => state.budgetState);
  const { projectsDetails } = useSelector((state) => state.projectsState);
  const closeModal = () => handleCloseModal(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.project = projectsDetails.id;
    values.budget = projectsDetails.projectBudget;
    values.id = data.id;
    const requisitionMemosArr = requisitionMemos.filter(
      (r) => r.id !== values.id
    );
    dispatch(requisitionMemosActions.editRequisitionMemosData(values));
    handleEditRecord(values, requisitionMemosArr);
  };
  useEffect(() => {
    if (editRequisitionMemoSuccess && editRequisitionMemoMessage) {
      message.success(editRequisitionMemoMessage);
      handleCloseModal(false);
    }
  }, [
    editRequisitionMemoSuccess,
    editRequisitionMemoMessage,
    handleCloseModal,
  ]);

  return (
    <div>
      <Modal
        title="EDIT REQUISITION FORM"
        onCancel={closeModal}
        footer={null}
        visible={visible}
      >
        <Form
          fields={fields}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!editRequisitionMemoSuccess && editRequisitionMemoError && (
            <Alert
              className="m-2"
              showIcon
              type="error"
              message={editRequisitionMemoError}
            />
          )}
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Please select a budget line" }]}
          >
            <Select loading={approvedBudgetLoading} allowClear>
              {appConfig.requisitionMemoTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="details" label="Details">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={editRequisitionMemoLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditRequsitionMemo;
