import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";
import TextArea from "antd/lib/input/TextArea";

const { Option, OptGroup } = Select;

const AddBudgetLine = ({ handleAddRecord }) => {
  const {
    addBudgetLineSuccess,
    addBudgetLineLoading,
    addBudgetLineError,
    addBudgetLineMessage,
    metaData,
    metaDataLoading,
    budgetDetails,
  } = useSelector((state) => state.budgetState);
  const { addBudgetLineModal: visible } = useSelector((state) => state.AppUi);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currency = getCurrency(budgetDetails, "approved_budget");
  const [formListDisplay, setFormListDisplay] = useState("horizontal");

  const closeModal = () => {
    dispatch(appUiActions.toggleAddBudgetLineModal(false));
  };
  const onFinish = (values) => {
    values.created_at = new Date();
    let budgetSubTotal = 0;
    values.activities = values.activitiesArray.map((activity) => {
      budgetSubTotal +=
        parseInt(activity?.units) * parseInt(activity?.unit_cost);
      return [...Object.values(activity), "~+~"];
    });
    values.budget = budgetDetails.id;
    values.budgetSubTotal = budgetSubTotal;
    handleAddRecord(values);
  };

  const budgetLineForm = document.getElementById("budgetLineForm");
  useEffect(() => {
    if (addBudgetLineSuccess && addBudgetLineMessage) {
      message.success(addBudgetLineMessage);
      form.resetFields();
    }
    if (budgetLineForm?.clientWidth > 800) {
      setFormListDisplay("horizontal");
    } else {
      setFormListDisplay("vertical");
    }
  }, [
    addBudgetLineMessage,
    addBudgetLineSuccess,
    form,
    budgetLineForm?.clientWidth,
    formListDisplay,
  ]);
  return (
    <div id="budgetLineForm">
      <Modal
        className="w-100"
        visible={visible}
        onCancel={closeModal}
        footer={null}
        title="ADD  BUDGET LINE"
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          {!addBudgetLineSuccess && addBudgetLineError && (
            <Alert
              className="my-2"
              showIcon
              type="error"
              message={addBudgetLineError}
            />
          )}
          <Form.Item
            name="name"
            label="Budget Line Name"
            rules={[
              { required: true, message: "Please add a budget line name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="expense_code"
            label="Expense Code"
            rules={[{ required: true, message: "Please add an expense code" }]}
          >
            <InputNumber precision={0} className="w-100" />
          </Form.Item>
          <Form.List name="activitiesArray">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    direction={formListDisplay}
                    className="w-100"
                  >
                    <Form.Item
                      name={[name, "name"]}
                      label="Cost Driver"
                      rules={[
                        {
                          required: true,
                          message: "Please add a budget activity name",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                    <Form.Item
                      className=""
                      label="Account"
                      {...restField}
                      name={[name, "chart_of_accounts_account"]}
                      fieldKey={[fieldKey, "chart_of_accounts_account"]}
                      rules={[
                        { required: true, message: "Chart Of Accounts " },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.key
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        loading={metaDataLoading}
                        className="w-100"
                        allowClear
                        placeholder="Chart Of Accounts Account"
                      >
                        {metaData?.chartOfAccountsAccounts?.map(
                          (accountType) => {
                            return (
                              <OptGroup
                                label={accountType.name}
                                key={`${accountType.name} - ${accountType.account_number}`}
                              >
                                {accountType.accounts?.map((account) => {
                                  const shortenedName =
                                    account.name?.slice(0, 30) + "...";
                                  return (
                                    <Option
                                      key={`${account.name} - ${account.account_number}`}
                                      value={account.id}
                                    >
                                      {account.name?.length > 30
                                        ? `${shortenedName} - ${account.account_number}`
                                        : `${account.name} - ${account.account_number}`}
                                    </Option>
                                  );
                                })}
                              </OptGroup>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Units"
                      name={[name, "units"]}
                      fieldKey={[fieldKey, "units"]}
                      hasFeedback
                      rules={[{ required: true, message: "Missing Units" }]}
                    >
                      <InputNumber precision={0} placeholder="Units" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Unit Cost"
                      name={[name, "unit_cost"]}
                      fieldKey={[fieldKey, "unit_cost"]}
                      hasFeedback
                      rules={[
                        { required: true, message: "Missing Unit Cost" },
                        { pattern: "^[0-9]*$", message: "Only add numbers" },
                      ]}
                    >
                      <Input placeholder="Unit Cost" />
                    </Form.Item>
                    <Form.Item label="Total" shouldUpdate>
                      {({ getFieldValue }) => {
                        const getAllActivities =
                          getFieldValue("activitiesArray");
                        const activity = getAllActivities[fieldKey];
                        const total_cost =
                          parseInt(activity?.units) *
                          parseInt(activity?.unit_cost);
                        return (
                          <Input
                            readOnly
                            value={
                              !isNaN(total_cost)
                                ? `${
                                    currency === "D" ? "USD" : "UGX"
                                  } ${numberWithCommas(total_cost)}`
                                : 0
                            }
                          ></Input>
                        );
                      }}
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Budget Notes"
                      name={[name, "budget_notes"]}
                      fieldKey={[fieldKey, "budget_notes"]}
                    >
                      <Input placeholder="Budget Notes" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Cost Driver
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const allActivities = getFieldValue("activitiesArray");
              let subTotal = 0;
              allActivities?.forEach((activity) => {
                subTotal +=
                  parseInt(activity?.units) * parseInt(activity?.unit_cost);
              });

              return (
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>SubTotal</td>
                      <td>
                        {!isNaN(subTotal)
                          ? `${
                              currency === "D" ? "USD" : "UGX"
                            } ${numberWithCommas(subTotal)}`
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            }}
          </Form.Item>

          <Form.Item>
            <Button
              loading={addBudgetLineLoading}
              type="primary"
              htmlType="submit"
              className="w-100"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddBudgetLine;
