import { Alert, Modal, Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const BiddersDetails = ({ visible, handleCloseModal }) => {
  const {
    biddersDetails: data,
    biddersDetailsSuccess,
    biddersDetailsLoading,
    biddersDetailsError,
  } = useSelector((state) => state.recordOfBiddersState);
  const closeModal = () => handleCloseModal(false);
  return (
    <div>
      <Modal
        title="BIDDER DETAILS"
        onCancel={closeModal}
        visible={visible}
        footer={null}
      >
        {biddersDetailsLoading ? (
          <div className="d-flex justify-content-center m-5">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {!biddersDetailsSuccess ? (
              <Alert showIcon message={biddersDetailsError} type="error" />
            ) : (
              <div className="details_div">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Bidder's Name</td>
                      <td>{data.name}</td>
                    </tr>
                    <tr>
                      <td>Date Of Submission</td>
                      <td>
                        {new Date(data.time_of_submission).toDateString()}
                      </td>
                    </tr>
                    <tr>
                      <td>Time Of Submission</td>
                      <td>
                        {new Date(data.time_of_submission).toLocaleTimeString()}
                      </td>
                    </tr>
                    <tr>
                      <td>No. Of Envelopes</td>
                      <td>{data.no_of_envelopes}</td>
                    </tr>
                    <tr>
                      <td>Comments</td>
                      <td>{data.comments}</td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BiddersDetails;
