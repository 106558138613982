import React, { useState } from "react";
import { Menu } from "antd";
import {
  FcBusiness,
  FcGenealogy,
  FcMoneyTransfer,
  FcRules,
  FcTodoList,
} from "react-icons/fc";
import { Link } from "react-router-dom";
import routes from "../../../config/routes/routes";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const FinanceMenu = () => {
  const [collapsed, setcollapsed] = useState(true);
  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);

  const toggleCollapsed = () => {
    setcollapsed(!collapsed);
  };
  return (
    <div className="d-none d-md-block">
      <Menu
        className="py-2"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="0" className="text-start" onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Menu.Item>
        {viewPermissions?.Chart_of_accounts && (
          <Menu.Item key="1" icon={<FcGenealogy />}>
            <Link to={routes.finance.chartOfAccounts.path}>
              Chart Of Accounts
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Budgets && (
          <Menu.Item key="2" icon={<FcBusiness />}>
            <Link to={routes.finance.budget.path}>Budgets</Link>
          </Menu.Item>
        )}
        {viewPermissions?.Requisition_memo && (
          <Menu.Item key="3" icon={<FcRules />}>
            <Link to={routes.finance.requistionMemos.path}>
              Requisition Form
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Advanced_accountability_form && (
          <Menu.Item key="4" icon={<FcTodoList />}>
            <Link to={routes.finance.advanceAccountabilityForm.path}>
              Payment Voucher
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Payment_voucher && (
          <Menu.Item key="5" icon={<FcMoneyTransfer />}>
            <Link to={routes.finance.paymentVoucher.path}>Funds Advance/Withdraw Formr</Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default FinanceMenu;
