import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import moment from "moment";
import { donorsActions } from "../../../../config/actions/businesDevAndPartnerships/donors/donors.actions";

const { Option } = Select;

const EditDonor = ({ handleEditRecord }) => {
  //state,variable, decalrations and initialization
  const {
    success,
    error,
    message: successMessage,
    loading,
    data,
  } = useSelector((state) => state.donorsState.updateDonor);
  const { editDonorsModal: visible } = useSelector((state) => state.AppUi);
  const fields = [
    { name: "name", value: data.name },
    { name: "email", value: data.email },
    { name: "contact", value: data.contact },
    { name: "focus", value: data.focus },
    { name: "budget", value: parseInt(data?.budget) },
    { name: "currency", value: getCurrency(data) },
    { name: "link", value: data.link },
    {
      name: "deadline_of_submission",
      value: moment(data.deadline_of_submission),
    },
    { name: "eligibility_status", value: data.eligibility_status },
    { name: "thematic_area", value: data.thematic_area },
  ];
  const thematicAreas = [
    "Education",
    "Child Protection And Safeguarding",
    "Child, Adolescent and Maternal Health",
    "Socioeconomic Empowerment",
  ];
  const dispatch = useDispatch();
  /** functions */
  const closeModal = () => dispatch(appUiActions.toggleEditDonorsModal(false));
  function getCurrency(data) {
    try {
      return data?.budget[data?.budget?.length - 1];
    } catch (error) {
      console.error(error);
      return "";
    }
  }
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = data.id;
    values.budget = values.budget + values.currency;
    dispatch(donorsActions.setEditData(values));
    handleEditRecord(values);
  };
  /** use Effects */
  useEffect(() => {
    if (success && successMessage) {
      message.success(successMessage);
      dispatch(appUiActions.toggleEditDonorsModal(false));
    }
  }, [success, successMessage, dispatch]);
  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="EDIT DONOR"
        visible={visible}
        width={800}
      >
        <Form
          fields={fields}
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={onFinish}
        >
          {!success && error && <Alert showIcon type="error" message={error} />}
          <div className="d-lg-flex">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="name"
                label="Donor"
                rules={[
                  { required: true, message: "Please add a donor name " },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please add a donor email " },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="contact"
                label="Contact"
                rules={[
                  { required: true, message: "Please add a donor contact " },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="budget"
                label="Budget (Upper limit)"
                rules={[
                  {
                    required: true,
                    message: "Please a upper limit for the budget",
                  },
                ]}
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: "Please select a currency",
                  },
                ]}
              >
                <Select style={{ width: "30%" }}>
                  <Option value="D">USD</Option>
                  <Option value="U">UGX</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="w-50 w-sm-100">
              <Form.Item
                name="focus"
                label="Focus Of Intervention"
                rules={[
                  {
                    required: true,
                    message: "Please add a focus of intervention ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="deadline_of_submission"
                label="Deadline of submission"
                rules={[
                  {
                    required: true,
                    message: "Please enter a deadline of submission",
                  },
                ]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
              <Form.Item
                name="link"
                label="Link to documents "
                rules={[
                  {
                    required: true,
                    message: "Please a link to application documents",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="eligibility_status"
                label="Eligibility status/0-10"
                rules={[
                  {
                    required: true,
                    message: "Please an Eligibility status/0-10",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="thematic_area"
                label="Thematic Area"
                rules={[
                  {
                    required: true,
                    message: "Please a Thematic Area",
                  },
                ]}
              >
                <Select allowClear>
                  {thematicAreas.map((i) => (
                    <Option value={i} key={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 5, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditDonor;
