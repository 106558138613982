import React from "react";
import { Button, Popover, Tooltip } from "antd";
import { FcMenu } from "react-icons/fc";
import { Link } from "react-router-dom";
import minLogo from "../../../assets/logo/favicon.png";
import CurrentUserInfo from "../../users/CurrentUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../config/actions/app/appUi.actions";
import appConfig from "../../../config/config.json";

const Navbar = () => {
  const dispatch = useDispatch();
  const openAppDrawer = () => {
    dispatch(appUiActions.toggleAppDrawer(true));
  };
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );
  return (
    <nav className="navbar shadow-sm navbar-expand-lg border-bottom navbar-light bg-light">
      <div className="container-fluid">
        <Tooltip title="Menu" placement="bottom">
          <Button onClick={openAppDrawer} type="text" size="large">
            <FcMenu />
          </Button>
        </Tooltip>
        <Link className="fw-bold" to="/">
          <div className="d-flex flex-row align-items-center">
            <img
              className="m-1"
              src={minLogo}
              width="40"
              height="40"
              alt="mini logo"
            />
            <h5 className="d-none d-lg-block m-0 fw-bold ">
              Amani Initiative Finance/Administration Manangement Information
              System
            </h5>
            <h3 className="d-lg-none m-0 mx-2 fw-bold">AIFMIS</h3>
          </div>
        </Link>
        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <div className="navbar-text d-flex flex-row align-items-center">
          <Tooltip placement="bottom" title="Your Account Info">
            <Popover
              content={CurrentUserInfo}
              className="w-25"
              placement="bottomRight"
              trigger="click"
            >
              <Button size="large" type="text">
                <img
                  className="rounded-circle"
                  src={appConfig.server_url + currentUserInfo.img_url}
                  alt=""
                  width="30"
                  height="30"
                />
              </Button>
            </Popover>
          </Tooltip>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
