import { Button, Modal, Space, Spin, Tag } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { undefinedRecordNames } from "../../../../config/helpers/app/undefinedRecords";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import appConfig from "../../../../config/config.json";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";

const AssetTypesDetails = ({ handleApprove, handleCheck }) => {
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.assetsRegister.viewAssetTypeDetails
  );
  const dispatch = useDispatch();
  const { loading, success, error, data } = useSelector(
    (state) => state.assetRegisterState.assetTypesDetails
  );
  const { loading: approveLoading } = useSelector(
    (state) => state.assetRegisterState.approveAssetType
  );
  const { loading: checkLoading } = useSelector(
    (state) => state.assetRegisterState.checkAssetType
  );
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);
  const closeModal = () => {
    dispatch(appUiActions.toggleAssetTypesDetailsModal(false));
  };
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: data?.created_by,
          item_application: "Asset Types",
          item_id: data?.id,
          itemName: data.name,
        },
      })
    );
  };
  return (
    <div>
      <Modal
        footer={[
          data.status !== appConfig.status.approved && (
            <Space>
              <Button
                onClick={approveRecord}
                loading={approveLoading}
                type="primary"
              >
                Approve
              </Button>
              {data.status !== appConfig.status.checked && (
                <Button onClick={checkRecord} loading={checkLoading}>
                  Check
                </Button>
              )}
              <Button onClick={reject} danger>
                Reject
              </Button>
            </Space>
          ),
        ]}
        onCancel={closeModal}
        visible={visible}
        title="ASSET TYPE DETAILS"
      >
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {!success && error ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <div className="details_div">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{data.name}</td>
                    </tr>
                    <tr>
                      <td>Depreciation</td>
                      <td>{data.depreciation}%</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {data.status === appConfig.status.approved && (
                          <Tag icon={<CheckCircleOutlined />} color="success">
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.checked && (
                          <Tag
                            icon={<MinusCircleOutlined />}
                            color="processing"
                          >
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.created && (
                          <Tag
                            icon={<ExclamationCircleOutlined />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${undefinedRecordNames(
                        data,
                        "creator",
                        "first_name"
                      )} ${undefinedRecordNames(
                        data,
                        "creator",
                        "other_names"
                      )}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {`${undefinedRecordNames(
                          data,
                          "updator",
                          "first_name"
                        )} ${undefinedRecordNames(
                          data,
                          "updator",
                          "other_names"
                        )}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked By</td>
                      <td>{`${undefinedRecordNames(
                        data,
                        "checker",
                        "first_name"
                      )} ${undefinedRecordNames(
                        data,
                        "checker",
                        "other_names"
                      )}`}</td>
                    </tr>
                    <tr>
                      <td>Checked At</td>
                      <td>
                        {data.checked_at &&
                          `${new Date(
                            data.checked_at
                          ).toDateString()} ${new Date(
                            data.checked_at
                          ).toLocaleTimeString()}
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved By</td>
                      <td>{`${undefinedRecordNames(
                        data,
                        "approver",
                        "first_name"
                      )} ${undefinedRecordNames(
                        data,
                        "approver",
                        "other_names"
                      )}`}</td>
                    </tr>
                    <tr>
                      <td>Approved At</td>
                      <td>
                        {data.aprroved_at &&
                          `${new Date(
                            data.aprroved_at
                          ).toDateString()} ${new Date(
                            data.aprroved_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default AssetTypesDetails;
