import React from "react";
import { Form, Button, Modal, Upload, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { InboxOutlined } from "@ant-design/icons";

const UploadStaffSignature = ({ handleSubmit }) => {
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.staffRecords.signatureModal
  );
  const {
    uploadStaffSignatureLoading,
    uploadStaffSignatureSuccess,
    uploadStaffSignatureError,
  } = useSelector((state) => state.staffRecordsState);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleSignatureModal(false));
  };
  const onFinish = (values) => {
    const formData = new FormData();
    formData.append("signature", values.image.file.originFileObj);
    handleSubmit(formData);
  };

  return (
    <div>
      <Modal
        title="UPLOAD STAFF SIGNATURE"
        visible={visible}
        footer={null}
        onCancel={closeModal}
      >
        {!uploadStaffSignatureSuccess && uploadStaffSignatureError && (
          <Alert
            showIcon
            message={uploadStaffSignatureError}
            className="my-1"
            type="error"
          />
        )}
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            rules={[{ required: true, message: "Please upload a file" }]}
            name="image"
          >
            <Upload.Dragger name="file" listType="picture" maxCount={1}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag signature image to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item>
            <Button
              loading={uploadStaffSignatureLoading}
              className="w-100"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UploadStaffSignature;
