import { Alert, Button, Form, message, Modal, Popconfirm, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import rejectionsThunks from "../../../../config/thunks/rejections/rejections.thunks";

const AddProcurementRejections = ({ handleCloseModal, visible }) => {
  const {
    addProcurementRejectionsSuccess,
    addProcurementRejectionsLoading,
    addProcurementRejectionsError,
    addProcurementRejectionsMessage,
    addProcurementRejectionsData,
  } = useSelector((state) => state.rejectionsState);
  const {
    success,
    error,
    message: successMessage,
    loading,
  } = useSelector((state) => state.rejectionsState.addRejection);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  //functions
  const closeModal = () => {
    handleCloseModal(false);
  };
  const onChangeReason = (e) => {
    setReason(e.target.value);
  };
  const rejectThisOnly = () => {
    const created_at = new Date();
    if (reason)
      dispatch(
        rejectionsThunks.addRejection({
          created_at,
          reason,
          ...addProcurementRejectionsData,
        })
      );
    else message.error("Please add a reason for your rejection");
  };
  const rejectEntireProcurementProcess = () => {
    const created_at = new Date();
    if (reason)
      dispatch(
        rejectionsThunks.addProcurementRejection({
          created_at,
          reason,
          ...addProcurementRejectionsData,
        })
      );
    else message.error("Please add a reason for your rejection");
  };
  //useEffects
  useEffect(() => {
    if (addProcurementRejectionsSuccess && addProcurementRejectionsMessage) {
      message.success(addProcurementRejectionsMessage);
      form.resetFields();
      handleCloseModal(false);
    }
  }, [
    addProcurementRejectionsSuccess,
    addProcurementRejectionsError,
    addProcurementRejectionsMessage,
    form,
    handleCloseModal,
  ]);

  useEffect(() => {
    if (success && successMessage) {
      message.success(successMessage);
      handleCloseModal(false);
      form.resetFields();
    }
  }, [success, error, successMessage, form, handleCloseModal]);
  return (
    <div className="">
      <Modal
        zIndex={1000}
        footer={[
          <Space>
            <Popconfirm
              okText="Yes"
              cancelText="No"
              placement="top"
              title="Are You Sure ?"
              onConfirm={rejectEntireProcurementProcess}
            >
              <Button
                danger
                htmlType="submit"
                type="primary"
                loading={addProcurementRejectionsLoading}
              >
                Reject Entire Procurement Process
              </Button>
            </Popconfirm>
            <Button
              onClick={rejectThisOnly}
              danger
              htmlType="submit"
              loading={loading}
            >
              Reject This Only
            </Button>
          </Space>,
        ]}
        onCancel={closeModal}
        title="PROCUREMENT REJECTIONS"
        visible={visible}
      >
        {!addProcurementRejectionsSuccess && addProcurementRejectionsError && (
          <Alert
            showIcon
            type="error"
            message={addProcurementRejectionsError}
          />
        )}
        {!success && error && <Alert showIcon type="error" message={error} />}
        <Form form={form} layout="vertical" validateTrigger="onBlur">
          <Form.Item
            label="Reason"
            rules={[{ required: true, message: "Please add a reason" }]}
          >
            <TextArea onChange={onChangeReason} value={reason} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddProcurementRejections;
