// services/reportGenerator.js

import jsPDF from "jspdf";
import "jspdf-autotable";
import { numberWithCommas } from "../helpers/app/numberFormater";
import image from "../../assets/logo/authLogo.png";
import { centrePdfText } from "../helpers/app/pdf";
import appConfig from "../config.json";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const generatePaymentVoucherPdf = (data, currency) => {
  const doc = new jsPDF();
  doc.setFontSize(10);
  doc.addImage(image, "PNG", 60, 15, 80, 25); //base64 image, format, x-coordinate, y-coordinate, width, height
  centrePdfText(doc, "PO Box 11406, Kampala (U)", 40);
  centrePdfText(
    doc,
    "Administration and Partnership Office- Plot 9A Ntinda Crescent View Road, Kampala ",
    45
  );
  centrePdfText(
    doc,
    "Arua Field Office- Arua Youth One Stop Centre, Enyau Road",
    50
  );
  centrePdfText(
    doc,
    "Maracha Field Office- Driver's Corner Building Maracha Town Council. ",

    55
  );
  centrePdfText(
    doc,
    "Tel: +256 794728054/ +256 703154084 | Mob: +256 782206830",
    60
  );
  centrePdfText(
    doc,
    "Web: www.amaniinitiative.org  | Email: info.amaniinitiative@gmail.com  ",
    65
  );
  centrePdfText(doc, "AMANI INITIATIVE FUNDS ADVANCE/WITHDRAW FORM", 75);
  doc.text(`DATE: ${new Date(data.created_at).toDateString()} `, 14, 85);
  doc.text(`FUNDS ADVANCE/WITHDRAW FORM NUMBER: ${data.id} `, 115, 85);
  doc.text(`DONOR: ${data.projectDetails?.donor_name} `, 14, 90);
  doc.text(`PROJECT: ${data.projectDetails?.project_name} `, 14, 95);
  doc.text(
    `REQUISITION FORM NUMBER: ${data.requisitionMemoDetails?.memo_number}`,
    14,
    100
  );

  doc.text(`BANK: ${data.bank}`, 14, 105);
  doc.text(`CHEQUE NO.: ${data.cheque_number}`, 115, 105);
  doc.text(
    `PAYEE: ${data?.payee?.first_name} ${data?.payee?.other_names}`,
    14,
    110
  );
  doc.text(`DETAILS: ${data.requisitionMemoDetails?.details}`, 14, 115);
  const pdfColumns = ["Particulars", "Units", "Unit Cost", "Total Amount"];
  let pdfRows = [];
  let total = 0;
  data.requisitionMemoDetails?.activities?.forEach((activity) => {
    total += activity.total_cost;
    pdfRows.push([
      activity.name,
      numberWithCommas(activity.units),
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        activity.unit_cost
      )}`,
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        activity.total_cost
      )}`,
    ]);
  });
  pdfRows.push([
    "Total",
    "",
    "",
    `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(total)}`,
  ]);
  doc.autoTable(pdfColumns, pdfRows, { startY: 125 });
  let { finalY } = doc.lastAutoTable;
  doc.text(
    `Prepared By: ${data?.creator?.first_name} ${data?.creator?.other_names} `,
    14,
    finalY + 10
  );
  doc.text(`Role: ${data?.creator?.role} `, 14, finalY + 20);
  doc.text(
    `Date Prepared: ${new Date(data.created_at).toDateString()}  `,
    117,
    finalY + 10
  );
  doc.text(`Signature: `, 125, finalY + 20);
  if (data?.creator?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.creator?.signature}`,
      "PNG",
      150,
      finalY + 12,
      30,
      15
    );
  }
  doc.text(
    `Checked By: ${data?.checker?.first_name} ${data?.checker?.other_names}`,
    14,
    finalY + 35
  );
  doc.text(`Role: ${data?.checker?.role}`, 14, finalY + 45);
  doc.text(
    `Date Checked: ${new Date(data.checked_at).toDateString()}  `,
    117,
    finalY + 35
  );
  doc.text(`Signature: `, 125, finalY + 45);
  if (data?.checker?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.checker?.signature}`,
      "PNG",
      150,
      finalY + 37,
      30,
      15
    );
  }
  doc.text(
    `Approved By: ${data?.approver?.first_name} ${data?.approver?.other_names}`,
    14,
    finalY + 60
  );
  doc.text(`Role: ${data?.approver?.role}`, 14, finalY + 70);
  doc.text(
    `Date Approved: ${new Date(data.approved_at).toDateString()}  `,
    117,
    finalY + 60
  );
  doc.text(`Signature: `, 125, finalY + 70);
  if (data?.approver?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.approver?.signature}`,
      "PNG",
      150,
      finalY + 63,
      30,
      15
    );
  }
  doc.save("AMANI INITIATIVE FUNDS ADVANCE/WITHDRAW FORM");
};

export default generatePaymentVoucherPdf;
