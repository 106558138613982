import appConfig from "../../config.json";
import { getAuthToken } from "../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;

export const fetchPermissionsRequest = async () => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/settings/permissions/`,
      {
        headers: {
          authorization,
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const createPermissionsRequest = async (body) => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/settings/permissions/`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization,
        },
        body: JSON.stringify(body),
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const updatePermissionsRequest = async (body, id) => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/settings/permissions/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          authorization,
        },
        body: JSON.stringify(body),
      }
    );
    return res.json();
  } catch (error) {
    console.error("Update permissions request", error);
  }
};

export const deletePermissionsRequest = async (id) => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/settings/permissions/${id}`,
      {
        method: "DELETE",
        headers: {
          authorization,
        },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const fetchRolesRequest = async () => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/settings/permissions/permissionRolesForm`,
      {
        headers: { authorization },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const fetchPermissionRequest = async (id) => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/settings/permissions/${id}`,
      {
        headers: { authorization },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};
