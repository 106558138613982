import { Tag } from "antd";
import React from "react";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import appConfig from "../../../../config/config.json";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";

const ProjectInfoDetails = ({ data }) => {
  const getCurrency = (data) => {
    try {
      return data?.approved_budget[data?.approved_budget?.length - 1];
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  const currency = getCurrency(data);
  return (
    <div>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Column</th>
            <th scope="col">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{data.name}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              {data.status === appConfig.status.approved && (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {data.status}
                </Tag>
              )}
              {data.status === appConfig.status.checked && (
                <Tag icon={<MinusCircleOutlined />} color="processing">
                  {data.status}
                </Tag>
              )}
              {data.status === appConfig.status.created && (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                  Pending
                </Tag>
              )}
            </td>
          </tr>
          <tr>
            <td>Donor</td>
            <td>{data.donor}</td>
          </tr>
          <tr>
            <td>Start Date</td>
            <td>{new Date(data.start_date).toDateString()}</td>
          </tr>
          <tr>
            <td>End Date</td>
            <td>{new Date(data.end_date).toDateString()}</td>
          </tr>
          <tr>
            <td>Person Incharge</td>
            <td>{data.personInchargeName}</td>
          </tr>
          <tr>
            <td>Project Supervisor</td>
            <td>{data.supervisorName}</td>
          </tr>
          <tr>
            <td>Project Accountant</td>
            <td>{data.projectAccountantName}</td>
          </tr>
          <tr>
            <td>Bank</td>
            <td>{data.bank}</td>
          </tr>
          <tr>
            <td>Account Number</td>
            <td>{data.account_number}</td>
          </tr>
          <tr>
            <td>Approved Budget</td>
            <td>{`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
              parseInt(data.approved_budget)
            )}`}</td>
          </tr>
          <tr>
            <td>Created By</td>
            <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
          </tr>
          <tr>
            <td>Created At</td>
            <td>
              {`${new Date(data.created_at).toDateString()} ${new Date(
                data.created_at
              ).toLocaleTimeString()}
                        `}
            </td>
          </tr>
          <tr>
            <td>Last Updated By</td>
            <td>
              {data.updator &&
                `${data?.updator?.first_name} ${data?.updator?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Last Updated At</td>
            <td>
              {data.updated_at &&
                `${new Date(data.updated_at).toDateString()} ${new Date(
                  data.updated_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
          <tr>
            <td>Checked By</td>
            <td>
              {data.checker &&
                `${data?.checker?.first_name} ${data?.checker?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Checked At</td>
            <td>
              {data.checked_at &&
                `${new Date(data.checked_at).toDateString()} ${new Date(
                  data.checked_at
                ).toLocaleTimeString()}
                        `}
            </td>
          </tr>
          <tr>
            <td>Approved By</td>
            <td>
              {data.approver &&
                `${data?.approver?.first_name} ${data?.approver?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Approved At</td>
            <td>
              {data.approved_at &&
                `${new Date(data.approved_at).toDateString()} ${new Date(
                  data.approved_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProjectInfoDetails;
