import apiConfig from "../../config.json";
import { getAuthToken } from "../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;

export const getAllRolesRequest = async () => {
  try {
    const res = await fetch(`${apiConfig.server_api_url}/roles/`, {
      headers: {
        authorization,
      },
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const addNewRoleRequest = async (body) => {
  try {
    const res = await fetch(`${apiConfig.server_api_url}/roles/create`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-type": "application/json",
        authorization,
      },
      body: JSON.stringify(body),
    });
    return res.json().then((res) => res);
  } catch (error) {
    console.error(error);
  }
};

export const updateNewRoleRequest = async (body, id) => {
  try {
    const res = await fetch(`${apiConfig.server_api_url}/roles/update/${id}`, {
      method: "PATCH",
      headers: {
        authorization,
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const deleteRoleRequest = async (id) => {
  try {
    const res = await fetch(`${apiConfig.server_api_url}/roles/delete/${id}`, {
      method: "DELETE",
      headers: {
        authorization,
        "Content-type": "application/json",
      },
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const getRolesDetailsRequest = async (id) => {
  try {
    const res = await fetch(`${apiConfig.server_api_url}/roles/${id}`, {
      headers: { authorization },
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const approveRolesRequest = async (id) => {
  try {
    const res = await fetch(`${apiConfig.server_api_url}/roles/approve/${id}`, {
      method: "PATCH",
      headers: { authorization, "content-type": "application/json" },
      body: JSON.stringify({ approvedAt: new Date() }),
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const checkRolesRequest = async (id) => {
  try {
    const res = await fetch(`${apiConfig.server_api_url}/roles/check/${id}`, {
      method: "PATCH",
      headers: { authorization, "content-type": "application/json" },
      body: JSON.stringify({ checkedAt: new Date() }),
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};
