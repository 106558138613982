import { Alert, Button, Modal, Space, Spin, Tag } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import appConfig from "../../../../config/config.json";

const RolesDetails = ({ handleApprove, handleCheck }) => {
  const visible = useSelector(
    (state) => state.AppUi.settings.rolesDetailsModal
  );
  const dispatch = useDispatch();
  const { loading, success, error, data } = useSelector(
    (state) => state.rolesState.getSingleRole
  );
  const { loading: approveLoading } = useSelector(
    (state) => state.rolesState.approveRoles
  );
  const { loading: checkLoading } = useSelector(
    (state) => state.rolesState.checkRoles
  );
  const closeModal = () => {
    dispatch(appUiActions.toggleViewRolesModal(false));
  };
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);
  return (
    <div>
      <Modal
        footer={[
          data.status !== appConfig.status.approved && (
            <Space>
              <Button
                onClick={approveRecord}
                loading={approveLoading}
                type="primary"
              >
                Approve
              </Button>
              {data.status !== appConfig.status.checked && (
                <Button onClick={checkRecord} loading={checkLoading}>
                  Check
                </Button>
              )}
              <Button danger>Reject</Button>
            </Space>
          ),
        ]}
        onCancel={closeModal}
        title="Role Details"
        visible={visible}
      >
        {loading ? (
          <div>
            <Spin size="large" />
          </div>
        ) : (
          <div className="details_div">
            {!success && error ? (
              <Alert showIcon type="error" message={error} />
            ) : (
              <div className="details_div">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{data.role}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {data.status === appConfig.status.approved && (
                          <Tag icon={<CheckCircleOutlined />} color="success">
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.checked && (
                          <Tag
                            icon={<MinusCircleOutlined />}
                            color="processing"
                          >
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.created && (
                          <Tag
                            icon={<ExclamationCircleOutlined />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked By</td>
                      <td>
                        {data.checker &&
                          `${data?.checker?.first_name} ${data?.checker?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked At</td>
                      <td>
                        {data.checked_at &&
                          `${new Date(
                            data.checked_at
                          ).toDateString()} ${new Date(
                            data.checked_at
                          ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved By</td>
                      <td>
                        {data.approver &&
                          `${data?.approver?.first_name} ${data?.approver?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved At</td>
                      <td>
                        {data.approved_at &&
                          `${new Date(
                            data.approved_at
                          ).toDateString()} ${new Date(
                            data.approved_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RolesDetails;
