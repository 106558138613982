import { Button, message, Popconfirm, Popover, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { staffRecordsActions } from "../../../../config/actions/administration/staffRecords/staffRecords.actions";
import generatePDF from "../../../../config/services/generatePdf";
import TableTitle from "../../../shared/Tables/TableTitle";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />
);

const StaffRecordsTable = ({
  handleDeleteStaffRecords,
  handleRefreshTable,
  handleViewDetails,
  handleExportRecords,
}) => {
  /** variable declarations  */
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const deleteLoading = useSelector(
    (state) => state.staffRecordsState.deleteStaffRecords.loading
  );
  const { loading, data } = useSelector(
    (state) => state.staffRecordsState.staffRecords
  );
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);

  const dispatch = useDispatch();
  /** functions */
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => handleDeleteStaffRecords(deleteId);
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };
  const refreshTable = () => handleRefreshTable();
  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(staffRecordsActions.setUpdateStaffData(updateRecordArray[0]));
    dispatch(appUiActions.toggleEditStaffRecordsModal(true));
  };
  const showTotal = (total) => `Total: ${total}`;
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const openAddModal = () =>
    dispatch(appUiActions.toggleAddStaffRecordsModal(true));
  const generateRecordsPdf = () => {
    const pdfColumns = [
      "Name",
      "Gender",
      "Contact",
      "Role",
      "Email",
      "Department",
    ];
    let pdfRows = [];
    data.forEach((record) => {
      const row = [
        `${record.first_name} ${record.other_names}`,
        record.gender,
        record.contact,
        record.role,
        record.email,
        record.department,
      ];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Initaive Staff Records - ${new Date().toLocaleDateString()}`,
      "Amani Intiative Staff Records.pdf"
    );
  };
  useEffect(() => {
    if (logDetailsSuccess && records === "staffRecords") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetailsSuccess, logDetailsError]);
  const viewDetails = (e) => handleViewDetails(e.target.id);
  const columns = [
    {
      title: "First Name",
      key: "name",
      render: (text) => (
        <div>
          <span className="d-md-none">First Name:</span> {text.first_name}{" "}
          <br />
          <div className="d-md-none">
            Other Names: {text.other_names} <br />
            Role: {text.role} <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() => buttonActions(text)}
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Other Names",
      dataIndex: "other_names",
      key: "other_names",
      responsive: ["md"],
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() => buttonActions(text)}
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];

  const buttonActions = (text) => (
    <Space direction="vertical">
      <button onClick={viewDetails} id={text?.id} className="w-100 ant-btn">
        More
      </button>
      <button
        onClick={updateRecord}
        id={text?.id}
        className="ant-btn ant-btn-primary w-100"
      >
        Edit
      </button>
      <Popconfirm
        onConfirm={confirmDelete}
        title="Are you sure you want to delete this ?"
      >
        <button
          className="ant-btn ant-btn-primary ant-btn-dangerous"
          onClick={deleteRecord}
          id={text?.id}
        >
          {deleteLoading && deleteId === text?.id && (
            <Spin indicator={antIcon} />
          )}
          &nbsp; Delete
        </button>
      </Popconfirm>
    </Space>
  );
  return (
    <div className="">
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default StaffRecordsTable;
