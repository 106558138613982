import React from "react";
import { Spin, Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const ChartOfAccountsTree = ({
  handleViewAccountTypeDetails,
  handleViewAccountDetails,
}) => {
  const dispatch = useDispatch();
  const { metaData, metaDataLoading } = useSelector(
    (state) => state.chartOfAccountsState
  );
  const onSelect = (selectedKeys, info) => {
    if (info.node.isAccountType) {
      handleViewAccountTypeDetails(info.node.id);
      dispatch(appUiActions.toggleChartOfAccountsAccountTypeDetailsModal(true));
    } else if (info.node.id !== "coa" && !info.node.isAccountType) {
      dispatch(appUiActions.toggleChartOfAccountsAccountDetailsModal(true));
      handleViewAccountDetails(info.node.id);
    }
  };

  const generateTreeData = (arr) => {
    return arr?.map((e) => {
      e.title = e.name;
      e.key = e.id;
      e.isAccountType = true;
      e.children = e.accounts?.map((account) => {
        account.title = account.name;
        account.key = account.id;
        account.isAccountType = false;
        return account;
      });
      return e;
    });
  };
  const chartOfAccountsAccountTypes = generateTreeData(metaData.accountTypes);
  const treeData = [
    {
      title: "Chart Of Accounts",
      key: "chartOfAccounts",
      id: "coa",
      children: [...(chartOfAccountsAccountTypes || [])],
    },
  ];
  return (
    <div className="w-25 d-none d-lg-block">
      <div>
        {metaDataLoading ? (
          <div className="d-flex justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <Tree
            height={1000}
            className="bg-light mx-1"
            showLine="true"
            defaultExpandedKeys={["chartOfAccounts"]}
            switcherIcon={<DownOutlined />}
            onSelect={onSelect}
            treeData={treeData}
          />
        )}
      </div>
    </div>
  );
};

export default ChartOfAccountsTree;
