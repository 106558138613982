import {
  Modal,
  Form,
  message,
  Alert,
  Button,
  Input,
  DatePicker,
  InputNumber,
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const AddBidder = ({ visible, handleAddRecord, handleCloseModal }) => {
  const [form] = Form.useForm();
  const {
    addBiddersLoading,
    addBiddersMessage,
    addBiddersSuccess,
    addBiddersError,
    bidders,
    recordOfBiddersDetails,
  } = useSelector((state) => state.recordOfBiddersState);

  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    values.record_of_bidders = recordOfBiddersDetails.id;
    handleAddRecord(values, bidders);
  };

  useEffect(() => {
    if (addBiddersSuccess && addBiddersMessage) {
      message.success(addBiddersMessage);
      form.resetFields();
    }
  }, [addBiddersMessage, addBiddersSuccess, form]);
  return (
    <div>
      <Modal
        visible={visible}
        onCancel={closeModal}
        footer={null}
        title="ADD NEW BIDDER"
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!addBiddersSuccess && addBiddersError && (
            <Alert type="error" showIcon message={addBiddersError} />
          )}
          <Form.Item
            name="name"
            label="Bidder's Name"
            rules={[{ required: true, message: "Please add the bidders name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="time_of_submission"
            label="Date Of Submission"
            rules={[
              {
                required: true,
                message: "Please select a date of submission name",
              },
            ]}
          >
            <DatePicker className="w-100" showTime />
          </Form.Item>
          <Form.Item name="no_of_envelopes" label="No. Of Envelopes">
            <InputNumber precision={0} className="w-100" />
          </Form.Item>
          <Form.Item name="comments" label="Comment (If Any)">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={addBiddersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddBidder;
