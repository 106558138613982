import { Alert, Button, message, Modal, Space, Spin, Tag } from "antd";
import React, { useEffect } from "react";
import appConfig from "../../../../config/config.json";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";
import generatePaymentVoucherPdf from "../../../../config/services/paymentVoucherPdf";

const PaymentVoucherDetails = ({
  visible,
  handleCloseModal,
  handleApprove,
  handleCheck,
}) => {
  //state and initialization
  const {
    paymentVouchersDetails: data,
    paymentVouchersDetailsSuccess,
    paymentVouchersDetailsLoading,
    paymentVouchersDetailsError,
    approvePaymentVouchersLoading,
    approvePaymentVouchersError,
    approvePaymentVouchersSuccess,
    approvePaymentVouchersMessage,
    checkPaymentVouchersLoading,
    checkPaymentVouchersSuccess,
    checkPaymentVouchersMessage,
    checkPaymentVouchersError,
  } = useSelector((state) => state.paymentVouchersState);
  const dispatch = useDispatch();
  const currency =
    data?.projectDetails?.approved_budget[
      data?.projectDetails?.approved_budget.length - 1
    ];
  //functions
  const closeModal = () => handleCloseModal(false);
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: data?.created_by,
          item_application: "Funds Advance/Withdraw Form",
          item_id: data?.id,
          itemName: data.id,
        },
      })
    );
  };
  const generatePdf = () => {
    data.status === appConfig.status.approved
      ? generatePaymentVoucherPdf(data, currency)
      : message.info("Funds Advance/Withdraw Form has not yet been approved");
  };
  //use effect
  useEffect(() => {
    if (approvePaymentVouchersSuccess && approvePaymentVouchersMessage)
      message.success(approvePaymentVouchersMessage);
    else if (!approvePaymentVouchersSuccess && approvePaymentVouchersError)
      message.error(approvePaymentVouchersError);
    if (checkPaymentVouchersSuccess && checkPaymentVouchersMessage)
      message.success(checkPaymentVouchersMessage);
    else if (!checkPaymentVouchersSuccess && checkPaymentVouchersError)
      message.error(checkPaymentVouchersError);
  }, [
    approvePaymentVouchersError,
    approvePaymentVouchersMessage,
    approvePaymentVouchersSuccess,
    checkPaymentVouchersError,
    checkPaymentVouchersMessage,
    checkPaymentVouchersSuccess,
  ]);
  return (
    <div>
      <Modal
        footer={[
          data.status !== appConfig.status.approved &&
            paymentVouchersDetailsSuccess && (
              <Space>
                <Button
                  onClick={approveRecord}
                  loading={approvePaymentVouchersLoading}
                  type="primary"
                >
                  Approve
                </Button>
                {data.status !== appConfig.status.checked && (
                  <Button
                    onClick={checkRecord}
                    loading={checkPaymentVouchersLoading}
                  >
                    Check
                  </Button>
                )}
                <Button onClick={reject} danger>
                  Reject
                </Button>
              </Space>
            ),
          data.status === appConfig.status.approved && (
            <Button onClick={generatePdf}>Export</Button>
          ),
        ]}
        width={800}
        onCancel={closeModal}
        visible={visible}
        title={"FUNDS ADVANCE/WITHDRAW FORM DETAILS"}
      >
        {paymentVouchersDetailsLoading ? (
          <div className="d-flex justify-content-center m-5">
            <Spin size="large" />
          </div>
        ) : (
          <div className="details_div">
            {!paymentVouchersDetailsSuccess ? (
              <Alert
                type="error"
                showIcon
                message={paymentVouchersDetailsError}
              />
            ) : (
              <div>
                <table
                  id="paymentVoucherDetails"
                  className="table table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Funds Advance/Withdraw Form Number</td>
                      <td>{data.id}</td>
                    </tr>
                    <tr>
                      <td>Check Number</td>
                      <td>{data.cheque_number}</td>
                    </tr>
                    <tr>
                      <td>Bank </td>
                      <td>{data.bank}</td>
                    </tr>
                    <tr>
                      <td>Source Of Funds</td>
                      <td>{data.projectDetails?.donor_name}</td>
                    </tr>
                    <tr>
                      <td>Project</td>
                      <td>{data.projectDetails?.project_name}</td>
                    </tr>
                    <tr>
                      <td>Budget Line</td>
                      <td>{data.budgetLineDetails?.name}</td>
                    </tr>
                    <tr>
                      <td>Expense Code</td>
                      <td>{data.budgetLineDetails?.expense_code}</td>
                    </tr>
                    <tr>
                      <td>Requisition Form Number</td>
                      <td>{data.requisitionMemoDetails?.memo_number}</td>
                    </tr>
                    <tr>
                      <td>Requisition Form Cost Drivers</td>
                      <td></td>
                    </tr>
                    {data.requisitionMemoDetails?.activities?.map(
                      (activity) => (
                        <tr key={activity.id}>
                          <td>{activity.name}</td>
                          <td>{`${
                            currency === "D" ? "USD" : "UGX"
                          } ${numberWithCommas(activity.total_cost)}`}</td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td className="text-warning">Sub Total</td>
                      <td className="text-warning">
                        {`${
                          currency === "D" ? "USD" : "UGX"
                        } ${numberWithCommas(
                          data.requisitionMemoDetails
                            ?.requisitionMemoActivitiesTotal
                        )}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {data.status === appConfig.status.approved && (
                          <Tag icon={<CheckCircleOutlined />} color="success">
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.checked && (
                          <Tag
                            icon={<MinusCircleOutlined />}
                            color="processing"
                          >
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.created && (
                          <Tag
                            icon={<ExclamationCircleOutlined />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                        {data.status === appConfig.status.pending && (
                          <Tag
                            icon={<ExclamationCircleOutlined />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Payee</td>
                      <td>{`${data?.payee?.first_name} ${data?.payee?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked By</td>
                      <td>
                        {data.checker &&
                          `${data?.checker?.first_name} ${data?.checker?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked At</td>
                      <td>
                        {data.checked_at &&
                          `${new Date(
                            data.checked_at
                          ).toDateString()} ${new Date(
                            data.checked_at
                          ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved By</td>
                      <td>
                        {data.approver &&
                          `${data?.approver?.first_name} ${data?.approver?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved At</td>
                      <td>
                        {data.approved_at &&
                          `${new Date(
                            data.approved_at
                          ).toDateString()} ${new Date(
                            data.approved_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PaymentVoucherDetails;
