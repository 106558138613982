import { Modal, Form, Button, message, Alert, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { advanceAccountabilityFormActions } from "../../../../config/actions/finance/advanceAccountabilityForm/advanceAccountabilityForm.actions";

const EditAdvanceAccountabilityForm = ({
  visible,
  handleCloseModal,
  handleEditRecord,
}) => {
  const {
    editAdvanceAccountabilityFormsLoading,
    editAdvanceAccountabilityFormsSuccess,
    editAdvanceAccountabilityFormsError,
    editAdvanceAccountabilityFormsMessage,
    advanceAccountabilityForms,
    editAdvanceAccountabilityFormsFormData: data,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const closeModal = () => handleCloseModal(false);
  const fields = [{ name: "details", value: data.details }];

  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = data.id;
    values.project = data.project;
    dispatch(
      advanceAccountabilityFormActions.editAdvanceAccountabilityFormsData(
        values
      )
    );
    handleEditRecord(values, advanceAccountabilityForms);
  };
  useEffect(() => {
    if (
      editAdvanceAccountabilityFormsSuccess &&
      editAdvanceAccountabilityFormsMessage
    ) {
      message.success(editAdvanceAccountabilityFormsMessage);
      handleCloseModal(false);
    }
  }, [
    editAdvanceAccountabilityFormsMessage,
    editAdvanceAccountabilityFormsSuccess,
    handleCloseModal,
  ]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        visible={visible}
        footer={null}
        title="UPDATE PAYMENT VOUCHER"
      >
        <Form fields={fields} form={form} layout="vertical" onFinish={onFinish}>
          {!editAdvanceAccountabilityFormsSuccess &&
            editAdvanceAccountabilityFormsError && (
              <Alert
                type="error"
                showIcon
                message={editAdvanceAccountabilityFormsError}
              />
            )}
          <Form.Item
            label="Details"
            name="details"
            rules={[{ required: true, message: "Please add details" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              loading={editAdvanceAccountabilityFormsLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditAdvanceAccountabilityForm;
