import React, { useEffect } from "react";
import { Alert, Button, message, Modal, Space, Spin, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AcountTypeRecordDetails from "./AcountTypeRecordDetails";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import appConfig from "../../../../config/config.json";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";
import SubAccountsTable from "../Tables/SubAccountsTable";
const { TabPane } = Tabs;

const AccountDetails = ({ handleApproveRecord, handleCheckRecord }) => {
  const {
    accountDetails,
    accountDetailsLoading,
    accountDetailsSuccess,
    accountDetailsError,
    approveAccountLoading,
    approveAccountSuccess,
    approveAccountMessage,
    approveAccountError,
    checkAccountLoading,
    checkAccountError,
    checkAccountSuccess,
    checkAccountMessage,
  } = useSelector((state) => state.chartOfAccountsState);
  const dispatch = useDispatch();
  const { accountDetailsModal: visible } = useSelector((state) => state.AppUi);
  const closeModal = () =>
    dispatch(appUiActions.toggleChartOfAccountsAccountDetailsModal(false));
  const checkRecord = () => handleCheckRecord(accountDetails.id);
  const approveRecord = () => handleApproveRecord(accountDetails.id);
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: accountDetails?.created_by,
          item_application: "Chart Of Accounts Account",
          item_id: accountDetails?.id,
          itemName: accountDetails.name,
        },
      })
    );
  };

  /** useEffect */
  useEffect(() => {
    if (approveAccountSuccess && approveAccountMessage)
      message.success(approveAccountMessage);
    else if (approveAccountError && !approveAccountSuccess)
      message.error(approveAccountError);

    if (checkAccountSuccess && checkAccountMessage)
      message.success(checkAccountMessage);
    else if (checkAccountError && !checkAccountSuccess)
      message.error(checkAccountError);
  }, [
    approveAccountError,
    approveAccountMessage,
    approveAccountSuccess,
    checkAccountError,
    checkAccountMessage,
    checkAccountSuccess,
  ]);
  return (
    <div>
      <Modal
        footer={[
          accountDetails.status !== appConfig.status.approved &&
            !accountDetailsLoading && (
              <Space>
                <Button
                  onClick={approveRecord}
                  loading={approveAccountLoading}
                  type="primary"
                >
                  Approve
                </Button>
                {accountDetails.status !== appConfig.status.checked && (
                  <Button onClick={checkRecord} loading={checkAccountLoading}>
                    Check
                  </Button>
                )}
                <Button onClick={reject} danger>
                  Reject
                </Button>
              </Space>
            ),
        ]}
        onCancel={closeModal}
        visible={visible}
        zIndex={500}
        width={800}
        title={
          accountDetailsSuccess && !accountDetailsLoading
            ? accountDetails?.name
            : "ACCOUNT  DETAILS"
        }
      >
        <div>
          {accountDetailsLoading ? (
            <div className="m-5 d-flex justify-content-center">
              <Spin size="large" />
            </div>
          ) : (
            <div>
              {!accountDetailsSuccess && accountDetailsError ? (
                <Alert
                  className="my-2"
                  showIcon
                  type="error"
                  message={accountDetailsError}
                />
              ) : (
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Details" key="1">
                    <AcountTypeRecordDetails data={accountDetails} />
                  </TabPane>
                  <TabPane tab="Accounts" key="2">
                    <SubAccountsTable data={accountDetails?.subAccounts} />
                  </TabPane>
                </Tabs>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AccountDetails;
