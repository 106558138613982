import React from "react";
import { Route, Switch } from "react-router-dom";
import AdministrationMenu from "../../components/administration/Menus/AdministrationMenu";
import routes from "../../config/routes/routes";
import PageNotFound from "../App/404";

const AdmininstrationLayout = () => {
  return (
    <div className="d-flex">
      <AdministrationMenu />
      <Switch>
        <Route
          exact
          path={routes.administration.assetRegister.assets.path}
          component={routes.administration.assetRegister.assets.component}
        />
        <Route
          exact
          path={routes.administration.staffRecords.path}
          component={routes.administration.staffRecords.component}
        />
        <Route
          exact
          path={routes.administration.storesRegister.path}
          component={routes.administration.storesRegister.component}
        />
        <Route
          exact
          path={routes.administration.manualsAndPolicies.path}
          component={routes.administration.manualsAndPolicies.component}
        />
        <Route
          exact
          path={routes.administration.staffRecords.staffDetails.path}
          component={routes.administration.staffRecords.staffDetails.component}
        />
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default AdmininstrationLayout;
