import { Modal, Form, Button, message, Alert, Select, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localPurchaseOrdersActions } from "../../../../config/actions/procument/localPurchaseOrder/localPuchaseOrder.actions";

const { Option } = Select;

const EditLocalPurchaseOrderForm = ({
  visible,
  handleEditRecord,
  handleCloseModal,
}) => {
  const {
    editLocalPurchaseOrdersLoading,
    editLocalPurchaseOrdersMessage,
    editLocalPurchaseOrdersSuccess,
    editLocalPurchaseOrdersError,
    localPurchaseOrders,
    localPurchaseOrdersMetaData,
    localPurchaseOrdersMetaDataLoading,
    editLocalPurchaseOrdersData,
  } = useSelector((state) => state.localPurchaseOrdersState);
  const [form] = Form.useForm();

  const fields = [
    { name: "rfq", value: editLocalPurchaseOrdersData.rfqno },
    {
      name: "discount",
      value: editLocalPurchaseOrdersData.discount,
    },
    {
      name: "goods_recieved_note_no",
      value: editLocalPurchaseOrdersData.goods_recieved_note_no,
    },
  ];

  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.rfqno = editLocalPurchaseOrdersData.rfqno;
    values.id = editLocalPurchaseOrdersData.id;
    dispatch(localPurchaseOrdersActions.editLocalPurchaseOrdersData(values));
    const updateLocalPurchaseOrdersArray = localPurchaseOrders.filter(
      (lpo) => lpo.id !== editLocalPurchaseOrdersData.id
    );
    handleEditRecord(values, updateLocalPurchaseOrdersArray);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (editLocalPurchaseOrdersSuccess && editLocalPurchaseOrdersMessage) {
      message.success(editLocalPurchaseOrdersMessage);
      handleCloseModal(false);
    }
  }, [
    handleCloseModal,
    editLocalPurchaseOrdersSuccess,
    editLocalPurchaseOrdersMessage,
  ]);

  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="EDIT LOCAL PURCHASE ORDER"
        visible={visible}
      >
        <Form
          fields={fields}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {editLocalPurchaseOrdersError && !editLocalPurchaseOrdersSuccess && (
            <Alert
              type="error"
              message={editLocalPurchaseOrdersError}
              showIcon
              className="m-2"
            />
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a Request For Qoutation No.",
              },
            ]}
            label="Rfq No."
            name="rfq"
          >
            <Select
              disabled
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={localPurchaseOrdersMetaDataLoading}
            >
              {localPurchaseOrdersMetaData?.rfqs?.map((rfq) => (
                <Option key={rfq.id} value={rfq.id}>
                  {rfq.rfqno}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="discount"
            label="Discount"
            rules={[
              { required: true, message: "Please Select Date Displayed" },
            ]}
          >
            <InputNumber precision={0} min={0} max={100} className="w-100" />
          </Form.Item>
          <Form.Item
            name="goods_recieved_note_no"
            label="Goods Received Notes No."
            rules={[
              { required: true, message: "Please Select Date Displayed" },
            ]}
          >
            <InputNumber precision={0} min={0} max={100} className="w-100" />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 10 }}>
            <Button
              loading={editLocalPurchaseOrdersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditLocalPurchaseOrderForm;
