import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import routes from "../../../config/routes/routes";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import {
  FcDiploma2,
  FcPhone,
  FcTemplate,
  FcTimeline,
  FcViewDetails,
} from "react-icons/fc";
import { useSelector } from "react-redux";

const ProcumentMenu = () => {
  const [collapsed, setcollapsed] = useState(true);
  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);

  const toggleCollapsed = () => {
    setcollapsed(!collapsed);
  };
  return (
    <div className="d-none d-md-block">
      <Menu
        className="py-2"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="0" className="text-start" onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Menu.Item>
        {viewPermissions?.Request_For_Quotation && (
          <Menu.Item key="1" icon={<FcDiploma2 />}>
            <Link to={routes.procurement.rfq.path}>Request For Quotation</Link>
          </Menu.Item>
        )}
        {viewPermissions?.Receiving_of_bids && (
          <Menu.Item key="2" icon={<FcTemplate />}>
            <Link to={routes.procurement.recordOfBidders.path}>
              Receiving Of Bidders
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Bids_evaluation && (
          <Menu.Item key="3" icon={<FcTimeline />}>
            <Link to={routes.procurement.bidEvaluation.path}>
              Bids Evaluation
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Notice_of_best_bidder && (
          <Menu.Item key="4" icon={<FcPhone />}>
            <Link to={routes.procurement.noticeOfBestBidder.path}>
              Notice Of The Best Bidder
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Local_purchase_order && (
          <Menu.Item key="5" icon={<FcViewDetails />}>
            <Link to={routes.procurement.localPurchaseOrder.path}>
              Local Purchase Order
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default ProcumentMenu;
