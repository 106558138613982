import { Modal, Form, Select, Button, message, Alert, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentVoucherActions } from "../../../../config/actions/finance/paymentVouchers/paymentVouchers.actions";
const { Option } = Select;

const EditPaymentVoucher = ({
  visible,
  handleCloseModal,
  handleEditRecord,
}) => {
  const {
    editPaymentVouchersLoading,
    editPaymentVouchersSuccess,
    editPaymentVouchersError,
    editPaymentVouchersMessage,
    paymentVouchersMetaDataLoading,
    paymentVouchersMetaData,
    paymentVouchers,
    editPaymentVouchersData,
  } = useSelector((state) => state.paymentVouchersState);
  const dispatch = useDispatch();
  const fields = [
    { name: "requisition_memo", value: editPaymentVouchersData.memo_number },
    { name: "bank", value: editPaymentVouchersData.bank },
    { name: "cheque_number", value: editPaymentVouchersData.cheque_number },
  ];

  const [form] = Form.useForm();
  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    values.id = editPaymentVouchersData.id;
    dispatch(paymentVoucherActions.editPaymentVouchersData(values));
    handleEditRecord(values, paymentVouchers);
  };

  useEffect(() => {
    if (editPaymentVouchersSuccess && editPaymentVouchersMessage) {
      message.success(editPaymentVouchersMessage);
      handleCloseModal(false);
    }
  }, [
    editPaymentVouchersMessage,
    editPaymentVouchersSuccess,
    handleCloseModal,
  ]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        visible={visible}
        footer={null}
        title={`UPDATE FUNDS ADVANCE/WITHDRAW FORM ${editPaymentVouchersData.id}`}
      >
        <Form fields={fields} form={form} layout="vertical" onFinish={onFinish}>
          {!editPaymentVouchersSuccess && editPaymentVouchersError && (
            <Alert type="error" showIcon message={editPaymentVouchersError} />
          )}
          <Form.Item
            rules={[
              { required: true, message: "Please select a requsition form" },
            ]}
            label="Requisition Form"
            name="requisition_memo"
          >
            <Select
              disabled
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={paymentVouchersMetaDataLoading}
            >
              {paymentVouchersMetaData?.requisitionMemos?.map((memo) => (
                <Option key={memo.id} value={memo.id}>
                  {memo.memo_number}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="bank" label="Bank">
            <Input />
          </Form.Item>
          <Form.Item name="cheque_number" label="Cheque Number">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              loading={editPaymentVouchersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditPaymentVoucher;
