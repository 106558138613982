import { Alert, Modal, Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";

const RequisitionMemoActivityDetails = ({ visible, handleCloseModal }) => {
  const closeModal = () => handleCloseModal(false);
  const { budgetDetails } = useSelector((state) => state.budgetState);
  const {
    requisitionMemosActivityDetailsLoading,
    requisitionMemosActivityDetailsSuccess,
    requisitionMemosActivityDetailsError,
    requisitionMemosActivityDetails: data,
  } = useSelector((state) => state.requisitionMemoState);
  const currency = getCurrency(budgetDetails, "approved_budget");
  return (
    <div>
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={data.name || "REQUISTION MEMO COST DRIVER DETAILS"}
        footer={null}
      >
        {requisitionMemosActivityDetailsLoading ? (
          <div className="m-5 d-flex justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {!requisitionMemosActivityDetailsSuccess ? (
              <Alert
                showIcon
                message={requisitionMemosActivityDetailsError}
                className="m-2"
                type="error"
              />
            ) : (
              <div className="details_div">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Units</td>
                      <td>{numberWithCommas(data.units)}</td>
                    </tr>
                    <tr>
                      <td>Unit Cost</td>
                      <td>{`${
                        currency === "D" ? "USD" : "UGX"
                      } ${numberWithCommas(parseInt(data.unit_cost))}`}</td>
                    </tr>
                    <tr>
                      <td>Total Cost</td>
                      <td>{`${
                        currency === "D" ? "USD" : "UGX"
                      } ${numberWithCommas(
                        parseInt(data.unit_cost) * parseInt(data.units)
                      )}`}</td>
                    </tr>
                    <tr>
                      <td>Details</td>
                      <td>{data.details}</td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RequisitionMemoActivityDetails;
