/* eslint-disable jsx-a11y/iframe-has-title */
import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../config/actions/app/appUi.actions";

const FileViewer = () => {
  const { fileModalOpen: visible, fileSrc } = useSelector(
    (state) => state.AppUi
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleFileView({ visible: false, src: "" }));
  };
  return (
    <div>
      <Modal
        width={800}
        onCancel={closeModal}
        onOk={closeModal}
        visible={visible}
        title="FILE VIEWER"
      >
        <iframe
          className="w-100 iframe details_div"
          src={fileSrc}
          frameborder="0"
        ></iframe>
      </Modal>
    </div>
  );
};

export default FileViewer;
