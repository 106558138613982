import { Alert, Button, Form, Input, InputNumber, message, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bidEvaluationsActions } from "../../../../../config/actions/procument/bidEvaluations/bidEvaluations.actions";

const FinancialBidderEvaluation = ({
  visible,
  handleCloseModal,
  handleEvaluation,
}) => {
  const {
    editFinancialEvaluationsSuccess,
    editFinancialEvaluationsLoading,
    editFinancialEvaluationsData,
    editFinancialEvaluationsError,
    editFinancialEvaluationsMessage,
    bidEvaluationsDetails,
    removeBidderFromEvaluationSuccess,
    removeBidderFromEvaluationMessage,
    removeBidderFromEvaluationError,
    financialEvaluationItemData,
  } = useSelector((state) => state.bidEvaluationState);
  const closeModal = () => handleCloseModal(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const fields = [
    { name: "bidder", value: editFinancialEvaluationsData.name },
    { name: "complaince", value: editFinancialEvaluationsData.complaince },
    { name: "units", value: editFinancialEvaluationsData.units },
    { name: "unit_cost", value: editFinancialEvaluationsData.unit_cost },
    { name: "reason", value: editFinancialEvaluationsData.reason },
  ];
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.bid_evaluation = bidEvaluationsDetails.id;
    values.bidder = editFinancialEvaluationsData.bidder;
    values.id = editFinancialEvaluationsData.id;
    values.name = editFinancialEvaluationsData.name;
    values.item = financialEvaluationItemData.item;
    dispatch(bidEvaluationsActions.editFinancialEvaluationsData(values));
    handleEvaluation(values);
  };

  useEffect(() => {
    if (editFinancialEvaluationsSuccess && editFinancialEvaluationsMessage) {
      message.success(editFinancialEvaluationsMessage);
      handleCloseModal(false);
    }

    if (
      removeBidderFromEvaluationSuccess &&
      removeBidderFromEvaluationMessage
    ) {
      message.success(removeBidderFromEvaluationMessage);
    } else if (
      !removeBidderFromEvaluationSuccess &&
      removeBidderFromEvaluationError
    ) {
      message.error(removeBidderFromEvaluationError);
    }
  }, [
    handleCloseModal,
    editFinancialEvaluationsSuccess,
    editFinancialEvaluationsMessage,
    removeBidderFromEvaluationSuccess,
    removeBidderFromEvaluationMessage,
    removeBidderFromEvaluationError,
    editFinancialEvaluationsData,
  ]);

  return (
    <div>
      <Modal
        title={`${editFinancialEvaluationsData.name} Evaluation`.toUpperCase()}
        footer={null}
        onCancel={closeModal}
        visible={visible}
      >
        <Form form={form} layout="vertical" onFinish={onFinish} fields={fields}>
          {editFinancialEvaluationsError &&
            !editFinancialEvaluationsSuccess && (
              <Alert
                type="error"
                message={editFinancialEvaluationsError}
                showIcon
                className="m-2"
              />
            )}
          <Form.Item
            name="bidder"
            label="Bidder"
            rules={[{ required: true, message: "Please select a bidder" }]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Units"
            name={"units"}
            fieldKey={"units"}
            hasFeedback
            rules={[{ required: true, message: "Please add Units" }]}
          >
            <InputNumber precision={0} className="w-100" placeholder="Units" />
          </Form.Item>
          <Form.Item
            label="Unit Cost"
            name={"unit_cost"}
            fieldKey={"unit_cost"}
            hasFeedback
            rules={[{ required: true, message: "Please add a Unit Cost" }]}
          >
            <InputNumber
              precision={0}
              className="w-100"
              placeholder="Unit Cost"
            />
          </Form.Item>
          <Form.Item label="Total" shouldUpdate>
            {({ getFieldValue }) => {
              const unitCost = getFieldValue("unit_cost");
              const units = getFieldValue("units");
              const total_cost = parseInt(unitCost) * parseInt(units);
              return <Input readOnly value={total_cost}></Input>;
            }}
          </Form.Item>
          <Form.Item label="Coments If Any" name="reason">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"}>
            <Button
              loading={editFinancialEvaluationsLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FinancialBidderEvaluation;
