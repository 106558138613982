import apiConfig from "../../../config.json";
import { getAuthToken } from "../../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;

const errorResponseObject = {
  success: false,
  error: "Sorry there seems to be an error",
};

const projectsApiRequests = {
  fetchProjects: async () => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/projects`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch Projects error:", error);
      return errorResponseObject;
    }
  },
  fetchProjectDetails: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/projects/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch Project details error:", error);
      return errorResponseObject;
    }
  },
  addProject: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/projects`,
        {
          method: "POST",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Add Project details error:", error);
      return errorResponseObject;
    }
  },
  editProject: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/projects/${body.id}`,
        {
          method: "PUT",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Edit Project details error:", error);
      return errorResponseObject;
    }
  },
  deleteProject: async (id, name) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/projects/${name}/${id}`,
        {
          method: "DELETE",
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Delete Project details error:", error);
      return errorResponseObject;
    }
  },
  checkProject: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/projects/check/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ checkedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Check Project details error:", error);
      return errorResponseObject;
    }
  },
  approveProject: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/projects/approve/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ approvedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Approve Project details error:", error);
      return errorResponseObject;
    }
  },
  projectsMetaData: async () => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/businessDevelopmentAndPartnerships/projects/metaData`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Projects Meta Data error", error);
    }
  },
};

export default projectsApiRequests;
