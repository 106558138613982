import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import budgetThunks from "../../../config/thunks/finance/budgets/budgets.thunks";
import { message } from "antd";
import BudgetsTable from "../../../components/Finance/Budget/Tables/BudgetsTable";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";
import AddBudget from "../../../components/Finance/Budget/Forms/AddBudget";
import routes from "../../../config/routes/routes";

const Budget = () => {
  const dispatch = useDispatch();
  const { budgetsSuccess, budgetsError } = useSelector(
    (state) => state.budgetState
  );
  /** fucntions */
  const addBudget = (values) => dispatch(budgetThunks.addBudget(values));
  const deleteRecord = (id, project, budgets) => {
    dispatch(budgetThunks.deleteBudget(id, project, budgets));
  };
  const refreshData = () => {
    dispatch(budgetThunks.getBudget());
    dispatch(budgetThunks.metaData());
    if (budgetsError && !budgetsSuccess) message.error(budgetsError);
  };
  const exportData = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Budgets Records",
        records: "budgets",
      })
    );
  };
  useEffect(() => {
    document.title = routes.finance.budget.title;
    if (!budgetsSuccess) {
      dispatch(budgetThunks.getBudget());
      dispatch(budgetThunks.metaData());
    } else if (budgetsError && !budgetsSuccess) message.error(budgetsError);
  }, [budgetsError, dispatch, budgetsSuccess]);
  return (
    <div className="container-fluid p-2">
      <h3>Budgets</h3>
      <BudgetsTable
        handleDelete={deleteRecord}
        handleExportRecords={exportData}
        handleRefreshTable={refreshData}
      />
      <AddBudget handleAddRecord={addBudget} />
    </div>
  );
};

export default Budget;
