import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BiddersDetails from "../../../components/Procurement/recordOfBidders/Details/BiddersDetails";
import RecordOfBiddersDetails from "../../../components/Procurement/recordOfBidders/Details/RecordOfBiddersDetails";
import AddBidder from "../../../components/Procurement/recordOfBidders/Forms/AddBidder";
import AddRecordOfBidders from "../../../components/Procurement/recordOfBidders/Forms/AddRecordOfBidders";
import EditBidder from "../../../components/Procurement/recordOfBidders/Forms/EditBidder";
import RecordOfBiddersTable from "../../../components/Procurement/recordOfBidders/Tables/RecordOfBiddersTable";
import AddProcurementRejections from "../../../components/shared/Rejections/procuremnet/AddProcurementRejections";
import routes from "../../../config/routes/routes";
import recordOfBiddersThunks from "../../../config/thunks/procurement/recordOfBidders/recordOfBidders.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const RecordOfBidders = () => {
  /* state initialization variables */
  const dispatch = useDispatch();
  const { recordOfBiddersSuccess } = useSelector(
    (state) => state.recordOfBiddersState
  );
  /* ui state */
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [addBiddersModalVisible, setAddBiddersModalVisible] = useState(false);
  const [editBiddersModalVisible, setEditBiddersModalVisible] = useState(false);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [biddersDetailsModalVisible, setBiddersDetailsModalVisible] =
    useState(false);
  const [addRejectionModalVisible, setAddRejectionModalVisible] =
    useState(false);
  /* thunks */
  const refreshData = () => {
    dispatch(recordOfBiddersThunks.getRecordOfBidders());
    dispatch(recordOfBiddersThunks.recordOfBiddersMetaData());
  };
  const addRecordOfBidders = (values, recordOfBidders) =>
    dispatch(recordOfBiddersThunks.addRecordOfBidders(values, recordOfBidders));
  const deleteRecordOfBidders = (id, recordOfBidders) =>
    dispatch(recordOfBiddersThunks.deleteRecordOfBidders(id, recordOfBidders));
  const getDetails = (id) => {
    dispatch(recordOfBiddersThunks.getRecordOfBiddersDetails(id));
    dispatch(recordOfBiddersThunks.getBidders(id));
    setDetailsModalVisible(true);
  };
  const deleteBidder = (id, bidders) =>
    dispatch(recordOfBiddersThunks.deleteBidders(id, bidders));
  const addBidder = (values, bidders) =>
    dispatch(recordOfBiddersThunks.addBidders(values, bidders));
  const editBidder = (values, bidders) =>
    dispatch(recordOfBiddersThunks.editBidders(values, bidders));
  const getBidderDetails = (id) => {
    setBiddersDetailsModalVisible(true);
    dispatch(recordOfBiddersThunks.getBiddersDetails(id));
  };
  const submitRecordOfBidders = (id) =>
    dispatch(recordOfBiddersThunks.submitRecordOfBidders(id));
  const approveRecordOfBidders = (id) =>
    dispatch(recordOfBiddersThunks.approveRecordOfBidders(id));
  const checkRecordOfBidders = (id) =>
    dispatch(recordOfBiddersThunks.checkRecordOfBidders(id));
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported  Record Of Bidders `,
        records: "recordOfBidders",
      })
    );
  };
  /* useeffect */
  useEffect(() => {
    document.title = routes.procurement.recordOfBidders.title;
    if (!recordOfBiddersSuccess) {
      dispatch(recordOfBiddersThunks.getRecordOfBidders());
      dispatch(recordOfBiddersThunks.recordOfBiddersMetaData());
    }
  }, [recordOfBiddersSuccess, dispatch]);
  return (
    <div className="container-fluid p-2">
      <h3>Receiving Of Bids</h3>
      <RecordOfBiddersTable
        handleExportRecords={exportRecords}
        handleViewDetails={getDetails}
        handleDelete={deleteRecordOfBidders}
        handleOpenAddModal={setAddModalVisible}
        handleRefreshTable={refreshData}
      />
      <AddRecordOfBidders
        handleAddRecord={addRecordOfBidders}
        visible={addModalVisible}
        handleCloseModal={setAddModalVisible}
      />
      <RecordOfBiddersDetails
        handleRejection={setAddRejectionModalVisible}
        handleCheck={checkRecordOfBidders}
        handleApprove={approveRecordOfBidders}
        handleSubmit={submitRecordOfBidders}
        handleGetBidderDetails={getBidderDetails}
        handleOpenEditModal={setEditBiddersModalVisible}
        handleRefreshData={getDetails}
        handleDeleteBidder={deleteBidder}
        handleOpenAddModal={setAddBiddersModalVisible}
        handleCloseModal={setDetailsModalVisible}
        visible={detailsModalVisible}
      />
      <AddBidder
        handleAddRecord={addBidder}
        visible={addBiddersModalVisible}
        handleCloseModal={setAddBiddersModalVisible}
      />
      <EditBidder
        handleEditRecord={editBidder}
        handleCloseModal={setEditBiddersModalVisible}
        visible={editBiddersModalVisible}
      />
      <BiddersDetails
        handleCloseModal={setBiddersDetailsModalVisible}
        visible={biddersDetailsModalVisible}
      />
      <AddProcurementRejections
        handleCloseModal={setAddRejectionModalVisible}
        visible={addRejectionModalVisible}
      />
    </div>
  );
};

export default RecordOfBidders;
