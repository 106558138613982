import { Modal, Form, Button, message, Alert, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bidEvaluationsActions } from "../../../../config/actions/procument/bidEvaluations/bidEvaluations.actions";

const { Option } = Select;

const EditBidEvaluationForm = ({
  visible,
  handleEditRecord,
  handleCloseModal,
}) => {
  const {
    editBidEvaluationsLoading,
    editBidEvaluationsMessage,
    editBidEvaluationsSuccess,
    editBidEvaluationsError,
    bidEvaluations,
    bidEvaluationsMetaData,
    bidEvaluationsMetaDataLoading,
    editBidEvaluationsData,
  } = useSelector((state) => state.bidEvaluationState);

  const fields = [
    { name: "evaluator_1", value: editBidEvaluationsData.evaluator_1 },
    { name: "evaluator_2", value: editBidEvaluationsData.evaluator_2 },
    { name: "evaluator_3", value: editBidEvaluationsData.evaluator_3 },
  ];
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = editBidEvaluationsData.id;
    const updateBidEvaluationsArray = bidEvaluations.filter(
      (form) => form.id !== editBidEvaluationsData.id
    );
    dispatch(bidEvaluationsActions.editBidEvaluationsData(values));
    handleEditRecord(values, updateBidEvaluationsArray);
  };

  useEffect(() => {
    if (editBidEvaluationsSuccess && editBidEvaluationsMessage) {
      message.success(editBidEvaluationsMessage);
      handleCloseModal(false);
    }
  }, [handleCloseModal, editBidEvaluationsSuccess, editBidEvaluationsMessage]);

  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="GENERATE BID EVALUATION FORM"
        visible={visible}
      >
        <Form
          fields={fields}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {editBidEvaluationsError && !editBidEvaluationsSuccess && (
            <Alert
              type="error"
              message={editBidEvaluationsError}
              showIcon
              className="m-2"
            />
          )}
          <Form.Item
            name="evaluator_1"
            label="Evaluator 1"
            rules={[{ required: true, message: "Please Select Evaluator 1" }]}
          >
            <Select
              allowClear
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={bidEvaluationsMetaDataLoading}
            >
              {bidEvaluationsMetaData?.evaluators?.map((user, id) => {
                return (
                  <Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="evaluator_2"
            label="Evaluator 2"
            rules={[{ required: true, message: "Please Select Evaluator 2" }]}
          >
            <Select
              allowClear
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={bidEvaluationsMetaDataLoading}
            >
              {bidEvaluationsMetaData?.evaluators?.map((user, id) => {
                return (
                  <Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="evaluator_3"
            label="Evaluator 3"
            rules={[{ required: true, message: "Please Select Evaluator 3" }]}
          >
            <Select
              allowClear
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={bidEvaluationsMetaDataLoading}
            >
              {bidEvaluationsMetaData?.evaluators?.map((user, id) => {
                return (
                  <Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 6 }}>
            <Button
              loading={editBidEvaluationsLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditBidEvaluationForm;
