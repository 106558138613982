import React, { useEffect } from "react";
import { Alert, Button, message, Modal, Space, Spin, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AcountTypeRecordDetails from "./AcountTypeRecordDetails";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import appConfig from "../../../../config/config.json";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";
import SubAccountsTable from "../Tables/SubAccountsTable";
const { TabPane } = Tabs;

const AccountTypeDetails = ({ handleApproveRecord, handleCheckRecord }) => {
  const {
    accountTypeDetails,
    accountTypeDetailsLoading,
    accountTypeDetailsSuccess,
    accountTypeDetailsError,
    approveAccountTypeLoading,
    approveAccountTypeSuccess,
    approveAccountTypeMessage,
    approveAccountTypeError,
    checkAccountTypeLoading,
    checkAccountTypeError,
    checkAccountTypeSuccess,
    checkAccountTypeMessage,
  } = useSelector((state) => state.chartOfAccountsState);
  const dispatch = useDispatch();
  const { accountTypeDetailsModal: visible } = useSelector(
    (state) => state.AppUi
  );
  const closeModal = () =>
    dispatch(appUiActions.toggleChartOfAccountsAccountTypeDetailsModal(false));
  const checkRecord = () => handleCheckRecord(accountTypeDetails.id);
  const approveRecord = () => handleApproveRecord(accountTypeDetails.id);
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: accountTypeDetails?.created_by,
          item_application: "Chart Of Accounts Account Types",
          item_id: accountTypeDetails?.id,
          itemName: accountTypeDetails.name,
        },
      })
    );
  };

  /** useEffect */
  useEffect(() => {
    if (approveAccountTypeSuccess && approveAccountTypeMessage)
      message.success(approveAccountTypeMessage);
    else if (approveAccountTypeError && !approveAccountTypeSuccess)
      message.error(approveAccountTypeError);

    if (checkAccountTypeSuccess && checkAccountTypeMessage)
      message.success(checkAccountTypeMessage);
    else if (checkAccountTypeError && !checkAccountTypeSuccess)
      message.error(checkAccountTypeError);
  }, [
    approveAccountTypeError,
    approveAccountTypeMessage,
    approveAccountTypeSuccess,
    checkAccountTypeError,
    checkAccountTypeMessage,
    checkAccountTypeSuccess,
  ]);
  return (
    <div>
      <Modal
        footer={[
          accountTypeDetails.status !== appConfig.status.approved &&
            !accountTypeDetailsLoading && (
              <Space>
                <Button
                  onClick={approveRecord}
                  loading={approveAccountTypeLoading}
                  type="primary"
                >
                  Approve
                </Button>
                {accountTypeDetails.status !== appConfig.status.checked && (
                  <Button
                    onClick={checkRecord}
                    loading={checkAccountTypeLoading}
                  >
                    Check
                  </Button>
                )}
                <Button onClick={reject} danger>
                  Reject
                </Button>
              </Space>
            ),
        ]}
        onCancel={closeModal}
        visible={visible}
        zIndex={500}
        width={800}
        title={
          accountTypeDetailsSuccess && !accountTypeDetailsLoading
            ? accountTypeDetails?.name
            : "ACCOUNT TYPE DETAILS"
        }
      >
        <div>
          {accountTypeDetailsLoading ? (
            <div className="m-5 d-flex justify-content-center">
              <Spin size="large" />
            </div>
          ) : (
            <div>
              {!accountTypeDetailsSuccess && accountTypeDetailsError ? (
                <Alert
                  className="my-2"
                  showIcon
                  type="error"
                  message={accountTypeDetailsError}
                />
              ) : (
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Details" key="1">
                    <AcountTypeRecordDetails data={accountTypeDetails} />
                  </TabPane>
                  <TabPane tab="Accounts" key="2">
                    <SubAccountsTable data={accountTypeDetails?.accounts} />
                  </TabPane>
                </Tabs>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AccountTypeDetails;
