import apiConfig from "../../../config.json";
import { getAuthToken } from "../../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;
const errorResponseObject = {
  success: false,
  error: "Sorry there seems to be an error",
};

const chartOfAccountApiRequests = {
  fetchChartOfAccounts: async () => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch chart of accounts error", error);
      return errorResponseObject;
    }
  },
  fetchMetaData: async () => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/metaData`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch chart of accounts meta data error", error);
      return errorResponseObject;
    }
  },
  fetchAccountDetails: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accounts/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch chart of accounts meta data error", error);
      return errorResponseObject;
    }
  },
  fetchAccountTypeDetails: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accountTypes/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch chart of accounts meta data error", error);
      return errorResponseObject;
    }
  },
  addAccount: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accounts`,
        {
          method: "POST",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Add Account error meta data error", error);
      return errorResponseObject;
    }
  },
  addAccountType: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accountTypes`,
        {
          method: "POST",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Add Account Type  error", error);
      return errorResponseObject;
    }
  },
  editAccount: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accounts/${body.id}`,
        {
          method: "PUT",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Fetch chart of accounts meta data error", error);
      return errorResponseObject;
    }
  },
  editAccountType: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accountTypes/${body.id}`,
        {
          method: "PUT",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Edit account type error", error);
      return errorResponseObject;
    }
  },
  deleteAccount: async (id, name) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accounts/${name}/${id}`,
        {
          method: "DELETE",
          headers: {
            authorization,
          },
        }
      );
      return res.json();
    } catch (error) {
      console.error("delete accounts meta data error", error);
      return errorResponseObject;
    }
  },
  deleteAccountType: async (id, name) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accountTypes/${name}/${id}`,
        {
          method: "DELETE",
          headers: {
            authorization,
          },
        }
      );
      return res.json();
    } catch (error) {
      console.error("delete accounts types  error", error);
      return errorResponseObject;
    }
  },
  checkAccount: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accounts/check/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ checkedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("check accounts meta data error", error);
      return errorResponseObject;
    }
  },
  checkAccountType: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accountTypes/check/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ checkedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("check accounts meta data error", error);
      return errorResponseObject;
    }
  },
  approveAccountType: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accountTypes/approve/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ approvedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("APPROVE accounts types error", error);
      return errorResponseObject;
    }
  },
  approveAccount: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/finance/chartOfAccounts/accounts/approve/${id}`,
        {
          method: "PATCH",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ approvedAt: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("APPROVE accounts error", error);
      return errorResponseObject;
    }
  },
};

export default chartOfAccountApiRequests;
