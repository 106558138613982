// services/reportGenerator.js

import jsPDF from "jspdf";
import "jspdf-autotable";
import { centrePdfText } from "../helpers/app/pdf";
import image from "../../assets/logo/authLogo.png";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const generatePDF = (tableRows, tableColumns, tableTitle, fileName) => {
  // initialize jsPDF
  const doc = new jsPDF();
  // for each data object pass all its data into an array
  doc.setFontSize(10);
  doc.addImage(image, "PNG", 61, 10, 80, 25); //base64 image, format, x-coordinate, y-coordinate, width, height
  centrePdfText(doc, "PO Box 11406, Kampala (U)", 40);
  centrePdfText(
    doc,
    "Administration and Partnership Office- Plot 9A Ntinda Crescent View Road, Kampala ",
    45
  );
  centrePdfText(
    doc,
    "Arua Field Office- Arua Youth One Stop Centre, Enyau Road",
    50
  );
  centrePdfText(
    doc,
    "Maracha Field Office- Driver's Corner Building Maracha Town Council. ",

    55
  );
  centrePdfText(
    doc,
    "Tel: +256 794728054/ +256 703154084 | Mob: +256 782206830",
    60
  );
  centrePdfText(
    doc,
    "Web: www.amaniinitiative.org  | Email: info.amaniinitiative@gmail.com  ",
    65
  );
  doc.setFontSize(13);
  // document title and margin-top + margin-left
  centrePdfText(doc, tableTitle, 75);
  // startY is basically margin-top
  doc.autoTable(tableColumns, tableRows, { startY: 85 });
  // we define the name of our PDF file.
  doc.save(fileName);
};

export default generatePDF;
