import Avatar from "antd/lib/avatar/avatar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../config/routes/routes";
import userThunks from "../../config/thunks/users/users.thunks";
import appConfig from "../../config/config.json";
import { Button, Input, message, Space, Spin } from "antd";
import { FcLock } from "react-icons/fc";
import ChangePassword from "../../components/settings/Profile/ChangePassword";
import { appUiActions } from "../../config/actions/app/appUi.actions";

const Profile = () => {
  const { data, success, error, loading } = useSelector(
    (state) => state.usersState.profile
  );
  const {
    first_name,
    other_names,
    gender,
    email,
    img_url,
    role,
    contact,
    department,
  } = data.userProfile;
  const { success: changePasswordSuccess, message: changePasswordMessage } =
    useSelector((state) => state.usersState.changePassword);
  const dispatch = useDispatch();

  const openChangePasswordModal = () => {
    dispatch(appUiActions.toggleChangePasswordModal(true));
  };
  const changePassword = (values) => {
    dispatch(userThunks.changePassword(values));
  };
  useEffect(() => {
    document.title = routes.settings.profile.title;
    if (!success) dispatch(userThunks.getUserProfile());
  }, [dispatch, success]);
  useEffect(() => {
    if (changePasswordSuccess) message.success(changePasswordMessage);
  }, [changePasswordSuccess, changePasswordMessage]);
  return (
    <div className="container fluid p-2">
      {loading ? (
        <div className="m-5 d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {" "}
          {!success ? (
            <div className="alert alert-danger">
              {error || "Sorry there seems to be an error "}
            </div>
          ) : (
            <>
              <div className="profile-container container-fluid ">
                <div className="">
                  <div className="profile-image mb-2">
                    <Avatar
                      size={90}
                      src={appConfig.server_url + img_url}
                    ></Avatar>
                  </div>
                  <div className="d-flex flex-row mb-2">
                    <div className="details m-1 w-50">
                      <p className="form-label">First Name</p>
                      <Input size="large" readOnly value={first_name} />
                    </div>
                    <div className="details m-1 w-50">
                      <p className="form-label">Other Names</p>
                      <Input size="large" readOnly value={other_names} />
                    </div>
                  </div>
                  <div className="d-flex flex-row mb-2">
                    <div className="details m-1 w-50">
                      <p className="form-label">Role</p>
                      <Input size="large" readOnly value={role} />
                    </div>
                    <div className="details  mb-2 m-1 w-50">
                      <p className="form-label">Gender</p>
                      <Input size="large" readOnly value={gender} />
                    </div>
                  </div>
                  <div className="details mb-2 d-block">
                    <p className="form-label">Email</p>
                    <Input size="large" readOnly value={email} />
                  </div>
                  <div className="details mb-2 d-block">
                    <p className="form-label">Contact</p>
                    <Input size="large" readOnly value={contact} />
                  </div>
                  <div className="details mb-2 d-block">
                    <p className="form-label">Department</p>
                    <Input size="large" readOnly value={department} />
                  </div>
                  <Button
                    onClick={openChangePasswordModal}
                    className="my-3"
                    type="primary"
                    size="large"
                  >
                    <Space>
                      <FcLock />
                      Change Password
                    </Space>
                  </Button>
                </div>
              </div>
              <ChangePassword handleChangePassword={changePassword} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
