import { Button, Modal, Space, Spin, Tag } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import appConfig from "../../../../config/config.json";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import storesRegisterThunks from "../../../../config/thunks/administration/storesRegister/storesRegister.thunks";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";

const ReceiptDetails = ({ handleApprove, handleCheck, setItemId }) => {
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.storesRegister.viewReceiptDetails
  );
  const { success, error, data, loading } = useSelector(
    (state) => state.storesRegisterState.storesRegisterReceiptDetails
  );
  const { loading: approveLoading, success: approveSuccess } = useSelector(
    (state) => state.storesRegisterState.approveStoresRegisterReceipt
  );
  const { loading: checkLoading, success: checkSuccess } = useSelector(
    (state) => state.storesRegisterState.checkStoresRegisterReceipt
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleStoresRegisterReceptDetailsModal(false));
  };
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);
  const undefinedRecords = (records, firstKey, secondKey) => {
    try {
      return records[firstKey][secondKey];
    } catch (error) {
      return "";
    }
  };
  const issueItem = () => {
    setItemId(data);
    dispatch(appUiActions.toggleAddStoresRegisterIssuesModal(true));
  };
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: data?.created_by,
          item_application: "Stores Register",
          item_id: data?.id,
          itemName: data?.name,
        },
      })
    );
  };
  //useEffect
  useEffect(() => {
    if (approveSuccess)
      dispatch(storesRegisterThunks.storesRegisterReceiptDetails(data.id));
    if (checkSuccess)
      dispatch(storesRegisterThunks.storesRegisterReceiptDetails(data.id));
  }, [checkSuccess, dispatch, data.id, approveSuccess]);
  return (
    <div>
      <Modal
        zIndex={800}
        footer={[
          data.status !== appConfig.status.approved && (
            <Space>
              <Button
                onClick={approveRecord}
                loading={approveLoading}
                type="primary"
              >
                Approve
              </Button>
              {data.status !== appConfig.status.checked && (
                <Button onClick={checkRecord} loading={checkLoading}>
                  Check
                </Button>
              )}
              <Button danger onClick={reject}>
                Reject
              </Button>
            </Space>
          ),
          data.status === appConfig.status.approved && (
            <Button onClick={issueItem} type="primary" size="large">
              Issue
            </Button>
          ),
        ]}
        title="Details"
        onCancel={closeModal}
        visible={visible}
      >
        {loading ? (
          <div className="d-flex justify-content-center m-5">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {!success ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <>
                <div className="details_div">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Column</th>
                        <th scope="col">Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{data.name}</td>
                      </tr>
                      <tr>
                        <td>Project</td>
                        <td>{data.projectDetails?.project}</td>
                      </tr>
                      <tr>
                        <td>Source Of Funds</td>
                        <td>{data.projectDetails?.donor}</td>
                      </tr>
                      <tr>
                        <td>Issue Date</td>
                        <td>{new Date(data.issue_date).toDateString()}</td>
                      </tr>
                      <tr>
                        <td>Rate</td>
                        <td>{data.rate}</td>
                      </tr>
                      <tr>
                        <td>Quantity</td>
                        <td>{data.quantity}</td>
                      </tr>
                      <tr>
                        <td>Total Price</td>
                        <td>{data.total_price}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>
                          {data.status === appConfig.status.approved && (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                              {data.status}
                            </Tag>
                          )}
                          {data.status === appConfig.status.checked && (
                            <Tag
                              icon={<MinusCircleOutlined />}
                              color="processing"
                            >
                              {data.status}
                            </Tag>
                          )}
                          {data.status === appConfig.status.created && (
                            <Tag
                              icon={<ExclamationCircleOutlined />}
                              color="warning"
                            >
                              Pending
                            </Tag>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Created By</td>
                        <td>{`${data.creator.first_name} ${data.creator.other_names}`}</td>
                      </tr>
                      <tr>
                        <td>Created At</td>
                        <td>
                          {`${new Date(
                            data.created_at
                          ).toDateString()} ${new Date(
                            data.created_at
                          ).toLocaleTimeString()}
                        `}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Updated By</td>
                        <td>
                          {`${undefinedRecords(
                            data,
                            "updator",
                            "first_name"
                          )} ${undefinedRecords(
                            data,
                            "updator",
                            "other_names"
                          )}`}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Updated At</td>
                        <td>
                          {data.updated_at &&
                            `${new Date(
                              data.updated_at
                            ).toDateString()} ${new Date(
                              data.updated_at
                            ).toLocaleTimeString()}`}
                        </td>
                      </tr>
                      <tr>
                        <td>Checked By</td>
                        <td>{`${undefinedRecords(
                          data,
                          "checker",
                          "first_name"
                        )} ${undefinedRecords(
                          data,
                          "checker",
                          "other_names"
                        )}`}</td>
                      </tr>
                      <tr>
                        <td>Checked At</td>
                        <td>
                          {data.checked_at &&
                            `${new Date(
                              data.checked_at
                            ).toDateString()} ${new Date(
                              data.checked_at
                            ).toLocaleTimeString()}
                        `}
                        </td>
                      </tr>
                      <tr>
                        <td>Approved By</td>
                        <td>{`${undefinedRecords(
                          data,
                          "approver",
                          "first_name"
                        )} ${undefinedRecords(
                          data,
                          "approver",
                          "other_names"
                        )}`}</td>
                      </tr>
                      <tr>
                        <td>Approved At</td>
                        <td>
                          {data.aprroved_at &&
                            `${new Date(
                              data.aprroved_at
                            ).toDateString()} ${new Date(
                              data.aprroved_at
                            ).toLocaleTimeString()}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default ReceiptDetails;
