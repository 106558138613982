import { Modal, Form, Select, Button, message, Alert } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
const { Option } = Select;

const AddRecordOfBidders = ({ visible, handleCloseModal, handleAddRecord }) => {
  const {
    addRecordOfBiddersLoading,
    addRecordOfBiddersSuccess,
    addRecordOfBiddersError,
    addRecordOfBiddersMessage,
    recordOfBiddersMetaDataLoading,
    recordOfBiddersMetaData,
    recordOfBidders,
  } = useSelector((state) => state.recordOfBiddersState);
  const [form] = Form.useForm();
  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values, recordOfBidders);
  };
  useEffect(() => {
    if (addRecordOfBiddersSuccess && addRecordOfBiddersMessage) {
      message.success(addRecordOfBiddersMessage);
      form.resetFields();
    }
  }, [addRecordOfBiddersMessage, addRecordOfBiddersSuccess, form]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        visible={visible}
        footer={null}
        title="GENERATE RECORD OF BIDDERS FORM"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {!addRecordOfBiddersSuccess && addRecordOfBiddersError && (
            <Alert type="error" showIcon message={addRecordOfBiddersError} />
          )}
          <Form.Item
            rules={[
              { required: true, message: "Please select a requsition memo" },
            ]}
            label="Rfq No."
            name="rfq"
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={recordOfBiddersMetaDataLoading}
            >
              {recordOfBiddersMetaData?.rfqs?.map((memo) => (
                <Option key={memo.id} value={memo.id}>
                  {memo.rfqno}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              loading={addRecordOfBiddersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddRecordOfBidders;
