import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyProjectsTable from "../../components/myProjects/Tables/MyProjectsTable";
import routes from "../../config/routes/routes";
import projectThunks from "../../config/thunks/businessDevAndPartnerships/projects/projects.thunks";
import logDetailThunks from "../../config/thunks/settings/logDetails.thunks";

const MyProjects = () => {
  //varibles and initializations
  const dispatch = useDispatch();
  const { myProjectsSuccess } = useSelector((state) => state.projectsState);
  //ui
  //thunks
  const refreshData = () => dispatch(projectThunks.getMyProjects());
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported My Projects Records",
        records: "myProjects",
      })
    );
  };
  //use effects
  useEffect(() => {
    document.title = routes.myProjects.title;
    if (!myProjectsSuccess) dispatch(projectThunks.getMyProjects());
  }, [myProjectsSuccess, dispatch]);
  return (
    <div className="container-fluid p-2">
      <h3>My Projects</h3>
      <MyProjectsTable
        handleRefreshTable={refreshData}
        handleExportRecords={exportRecords}
      />
    </div>
  );
};

export default MyProjects;
