import { Alert, Button, Space, Spin, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineArrowLeft, AiOutlinePrinter } from "react-icons/ai";
import { FcRefresh } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import BidEvaluationOtherDetails from "../../../components/Procurement/bidEvaluations/Details/BidEvaluationOtherDetails";
import FinancialEvaluationItemData from "../../../components/Procurement/bidEvaluations/Details/FinancialEvaluationsItemData";
import ReasonForNonComplaiceForm from "../../../components/Procurement/bidEvaluations/Forms/ReasonForNonComplaince";
import FinancialBidderEvaluation from "../../../components/Procurement/bidEvaluations/Forms/financial/FinancialBidderEvaluation";
import SubmitBidEvaluationForm from "../../../components/Procurement/bidEvaluations/Forms/SubmitBidEvaluationForm";
import EligibilityEvaluationsTable from "../../../components/Procurement/bidEvaluations/Tables/EligibilityEvaluationsTable";
import FinancialEvaluationsTable from "../../../components/Procurement/bidEvaluations/Tables/FinancialEvaluationItemsTable";
import TechnicalEvaluationsTable from "../../../components/Procurement/bidEvaluations/Tables/TechnicalEvaluationTable";
import { getIdFromPath } from "../../../config/helpers/app/url";
import routes from "../../../config/routes/routes";
import bidEvaluationThunks from "../../../config/thunks/procurement/bidEvaluation/bidEvaluation.thunks";
import AddProcurementRejections from "../../../components/shared/Rejections/procuremnet/AddProcurementRejections";
import PrintBidEvaluations from "../../../components/Procurement/bidEvaluations/Details/PrintBidEvaluations";
import { useReactToPrint } from "react-to-print";

const BidEvaluationForm = () => {
  //reducer state variables and initialization
  const {
    bidEvaluationsDetails,
    bidEvaluationsDetailsSuccess,
    bidEvaluationsDetailsLoading,
    bidEvaluationsDetailsError,
    eligibilityEvaluations,
    financialEvaluationsItems,
    evaluationItems,
    financialEvaluationItemData,
    financialEvaluations,
  } = useSelector((state) => state.bidEvaluationState);

  const history = useHistory();
  const bidEvaluationFormId = getIdFromPath(history.location.pathname);
  const dispatch = useDispatch();

  //ui state
  const [tabsPosition, setTabsPosition] = useState("left");
  const tabsContainer = document.getElementById("evaluationTabsContainer");
  const [
    financialEvaluationsModalVisible,
    setFinancialEvaluationsModalVisible,
  ] = useState(false);
  const [
    editFinancialEvaluationsModalVisible,
    setEditFinancialEvaluationsModalVisible,
  ] = useState(false);
  const [addRejectionModalVisible, setAddRejectionModalVisible] =
    useState(false);

  const [evaluationModalVisible, setEvaluationModalVisble] = useState(false);
  // thunk functions
  const submitBidEvaluation = (values) => {
    dispatch(bidEvaluationThunks.submitBidEvaluationForm(values));
  };
  const checkBidEvaluation = (id) => {
    dispatch(bidEvaluationThunks.checkBidEvaluationForm(id));
  };
  const approveBidEvaluation = (id) => {
    dispatch(bidEvaluationThunks.approveBidEvaluationForm(id));
  };
  /* elgibility criteria */
  const refreshEligibilityEvaluations = () =>
    dispatch(
      bidEvaluationThunks.getEligibilityEvaluations(bidEvaluationFormId)
    );
  const reloadBidEvaluationDetails = () => {
    dispatch(
      bidEvaluationThunks.getBidEvaluationsMetaData(bidEvaluationFormId)
    );
    dispatch(bidEvaluationThunks.getBidEvaluationDetails(bidEvaluationFormId));
  };

  const editBidderEligibilityEvaluation = (values) => {
    values.complaince === "Non Compliant" &&
      values.reason &&
      dispatch(bidEvaluationThunks.removeBidderFromEvaluation(values));
    dispatch(bidEvaluationThunks.editEligibilityEvaluation(values));
  };
  /* technical evaluations */
  const refreshTechnicalEvaluations = () =>
    dispatch(bidEvaluationThunks.getTechnicalEvaluations(bidEvaluationFormId));
  const editTechnicalEvaluation = (values) => {
    values.complaince === "Non Compliant" &&
      values.reason &&
      dispatch(bidEvaluationThunks.removeBidderFromEvaluation(values));
    dispatch(bidEvaluationThunks.editTechnicalEvaluation(values));
  };
  /* Financial Evaluations */
  const getFinancialItemData = (id) => {
    setFinancialEvaluationsModalVisible(true);
    dispatch(bidEvaluationThunks.getFinancialEvaluationItemData(id));
  };
  const editFinancialEvaluation = (values) => {
    values.reason &&
      dispatch(bidEvaluationThunks.removeBidderFromEvaluation(values));
    dispatch(bidEvaluationThunks.editFinancialEvaluation(values));
  };
  const refreshFinancialEvaluations = () =>
    dispatch(
      bidEvaluationThunks.getFinancialEvaluationItems(bidEvaluationFormId)
    );

  //useEffect
  useEffect(() => {
    document.title = routes.procurement.bidEvaluation.bidEvaluationForm.title;
    dispatch(bidEvaluationThunks.getBidEvaluationDetails(bidEvaluationFormId));
    dispatch(
      bidEvaluationThunks.getEligibilityEvaluations(bidEvaluationFormId)
    );
    dispatch(bidEvaluationThunks.getTechnicalEvaluations(bidEvaluationFormId));
    dispatch(
      bidEvaluationThunks.getFinancialEvaluationItems(bidEvaluationFormId)
    );
    dispatch(
      bidEvaluationThunks.getBidEvaluationsMetaData(bidEvaluationFormId)
    );
  }, [dispatch, bidEvaluationFormId]);

  useEffect(() => {
    if (tabsContainer?.clientWidth < 500) setTabsPosition("top");
    else setTabsPosition("left");
  }, [tabsContainer?.clientWidth]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="evaluationTabsContainer" className="container-fluid p-2">
      <h3>Bid Evaluation Form {bidEvaluationFormId}</h3>
      {bidEvaluationsDetailsLoading ? (
        <div className="m-5 d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {bidEvaluationsDetailsSuccess ? (
            <div>
              <Space>
                <Button onClick={reloadBidEvaluationDetails}>
                  <Space>
                    <FcRefresh /> <span>Reload </span>
                  </Space>
                </Button>
                <Button onClick={handlePrint} type="primary">
                  <Space>
                    <AiOutlinePrinter /> <span>Print </span>
                  </Space>
                </Button>
                <Button onClick={() => history.goBack()} danger>
                  <Space>
                    <AiOutlineArrowLeft /> <span>Return </span>
                  </Space>
                </Button>
              </Space>
              <div className="d-none">
                <div className="p-2" ref={componentRef}>
                  <PrintBidEvaluations
                    data={{
                      ...bidEvaluationsDetails,
                      eligibilityEvaluations: eligibilityEvaluations,
                      evaluationItems,
                      financialEvaluationsItems,
                      financialEvaluationItemData,
                      financialEvaluations,
                    }}
                  />
                </div>
              </div>
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane key="1" tab="Form Evaluation">
                  <Tabs
                    tabPosition={tabsPosition}
                    type="card"
                    defaultActiveKey="a"
                  >
                    <Tabs.TabPane
                      disabled={true}
                      tab={<h6 className="m-0  fw-bold">Evaluations</h6>}
                    ></Tabs.TabPane>
                    <Tabs.TabPane key="a" tab="Eligibility Evaluation">
                      <h6>Eligibly Evaluations</h6>
                      <EligibilityEvaluationsTable
                        handleRefreshTable={refreshEligibilityEvaluations}
                        handleOpenEditModal={setEvaluationModalVisble}
                        handleAddComplaince={editBidderEligibilityEvaluation}
                      />
                      <ReasonForNonComplaiceForm
                        handleEvaluation={editBidderEligibilityEvaluation}
                        handleCloseModal={setEvaluationModalVisble}
                        visible={evaluationModalVisible}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="b" tab="Technical Evaluation">
                      <h6>Technical Evaluations</h6>
                      <TechnicalEvaluationsTable
                        handleOpenEditModal={setEvaluationModalVisble}
                        handleRefreshTable={refreshTechnicalEvaluations}
                        handleAddComplaince={editTechnicalEvaluation}
                      />
                      <ReasonForNonComplaiceForm
                        handleEvaluation={editTechnicalEvaluation}
                        handleCloseModal={setEvaluationModalVisble}
                        visible={evaluationModalVisible}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="c" tab="Financial Evaluation">
                      <h6>Financial Evaluation</h6>
                      <FinancialEvaluationsTable
                        handleViewDetails={getFinancialItemData}
                        handleRefreshTable={refreshFinancialEvaluations}
                      />
                      <FinancialEvaluationItemData
                        visible={financialEvaluationsModalVisible}
                        handleCloseModal={setFinancialEvaluationsModalVisible}
                        handleOpenEvaluationModal={
                          setEditFinancialEvaluationsModalVisible
                        }
                      />
                      <FinancialBidderEvaluation
                        handleCloseModal={
                          setEditFinancialEvaluationsModalVisible
                        }
                        handleEvaluation={editFinancialEvaluation}
                        visible={editFinancialEvaluationsModalVisible}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      key="d"
                      tab={
                        bidEvaluationsDetails.submitted
                          ? "Form Check/Approval"
                          : "Submit Form"
                      }
                    >
                      <SubmitBidEvaluationForm
                        handleRejection={setAddRejectionModalVisible}
                        handleCheck={checkBidEvaluation}
                        handleApprove={approveBidEvaluation}
                        handleSubmit={submitBidEvaluation}
                      />
                      <AddProcurementRejections
                        handleCloseModal={setAddRejectionModalVisible}
                        visible={addRejectionModalVisible}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab="Form Details">
                  <BidEvaluationOtherDetails data={bidEvaluationsDetails} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          ) : (
            <Alert type="error" showIcon message={bidEvaluationsDetailsError} />
          )}
        </div>
      )}
    </div>
  );
};

export default BidEvaluationForm;
