import React, { useState } from "react";
import { Menu } from "antd";
import { FcAbout, FcKindle, FcReadingEbook } from "react-icons/fc";
import { Link } from "react-router-dom";
import routes from "../../../config/routes/routes";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { AiOutlineUser } from "react-icons/ai";
import { useSelector } from "react-redux";

const SettingsMenu = () => {
  const [collapsed, setcollapsed] = useState(true);
  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);

  const toggleCollapsed = () => {
    setcollapsed(!collapsed);
  };
  return (
    <div className="d-none d-md-block">
      <Menu
        className="py-2"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="0" className="text-start" onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Menu.Item>
        {viewPermissions?.Roles && (
          <Menu.Item key="1" icon={<FcReadingEbook />}>
            <Link to={routes.settings.roles.path}>System Roles</Link>
          </Menu.Item>
        )}
        {viewPermissions?.Permissions && (
          <Menu.Item key="2" icon={<FcKindle />}>
            <Link to={routes.settings.permissions.path}>
              System Permissions
            </Link>
          </Menu.Item>
        )}
        {viewPermissions?.Roles && (
          <Menu.Item key="3" icon={<FcAbout />}>
            <Link to={routes.settings.logDetails.path}>System Log details</Link>
          </Menu.Item>
        )}{" "}
        <Menu.Item key="4" icon={<AiOutlineUser />}>
          <Link to={routes.settings.profile.path}>My profile</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SettingsMenu;
