import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;
const AddAssetForm = ({ handledAddAsset, visible, handleCloseModal }) => {
  const [form] = Form.useForm();
  const { error, success, loading } = useSelector(
    (state) => state.assetRegisterState.addAsset
  );
  const { loading: metaDataLoading, data: metaData } = useSelector(
    (state) => state.assetRegisterState.metaData
  );
  const { loading: assetTypesLoading, data: assetTypes } = useSelector(
    (state) => state.assetRegisterState.fetchAssetCategory
  );
  const onFinish = (values) => {
    values.createdAt = new Date();
    handledAddAsset(values);
  };
  const closeAddRoleModal = () => {
    handleCloseModal(false);
  };

  useEffect(() => {
    if (success) form.resetFields();
  }, [success, form]);

  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        visible={visible}
        title="ADD NEW ASSET"
        footer={null}
        validateTrigger="onBlur"
        width={800}
      >
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 18 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!success && error && <Alert type="error" showIcon message={error} />}
          <div className="d-md-flex w-100 ">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="name"
                label="Asset Description"
                rules={[
                  {
                    required: true,
                    message: "Please add an asset Description",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="procurement_date"
                label="Acquisition Date"
                rules={[
                  { required: true, message: "Please add an Acqusition Date" },
                ]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
              <Form.Item
                label="Location"
                name="location"
                rules={[{ required: true, message: "Please add a Location" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Staff With Asset"
                name="staff_with_asset"
                rules={[
                  {
                    required: true,
                    message: "Please the staff currently with the asset",
                  },
                ]}
              >
                <Select
                  showSearch
                  loading={metaDataLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {metaData?.users?.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {`${user.first_name} ${user.other_names}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Souce of funds"
                name="source_of_funds"
                rules={[
                  { required: true, message: "Please add a source of funds" },
                ]}
              >
                <Select
                  showSearch
                  loading={metaDataLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {metaData?.donors?.map((donor) => (
                    <Option key={donor.id} value={donor.id}>
                      {donor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="w-50 w-sm-100">
              <Form.Item
                label="Purchase Amount"
                name="purchase_ammount"
                rules={[
                  { required: true, message: "Please add a Purchase Amount" },
                ]}
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                label="Code"
                name="code"
                rules={[{ required: true, message: "Please add a code" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Financial Year"
                name="financial_year"
                rules={[{ required: true, message: "Please add a code" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please select an asset type" },
                ]}
                name="category_id"
                label="Asset Type"
              >
                <Select loading={assetTypesLoading}>
                  {assetTypes.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button
              className="w-25"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAssetForm;
