import { Button, Input, Modal, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { chartOfAccountsActions } from "../../../../config/actions/finance/chartOfAccounts/chartOfAccounts.actions";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";

const SearchChartOfAccounts = ({
  handleDeleteAccountType,
  handleDeleteAccount,
  handleViewAccountTypeDetails,
  handleViewAccountDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [toBeSearched, setToBeSearched] = useState([]);
  const [search, setSearch] = useState([]);
  const dispatch = useDispatch();
  const { searchChartOfAccountsModal: visible } = useSelector(
    (state) => state.AppUi
  );
  const {
    metaData: { accounts },
    deleteAccountTypeLoading,
    deleteAccountLoading,
    chartOfAccounts,
    chartOfAccountsSuccess,
    metaDataLoading,
  } = useSelector((state) => state.chartOfAccountsState);
  const closeModal = () => {
    dispatch(appUiActions.toggleSearchChartOfAccountsModal(false));
  };
  const onChange = (e) => {
    if (!e.target.value) setSearch([]);
  };
  const onSearch = (value) => {
    const searchResult = toBeSearched.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    if (value.length < 1) setSearch([]);
    else setSearch(searchResult);
  };
  const generateSearchArray = () => {
    accounts?.map((account) => {
      account.isAccount = true;
      return account;
    });
    setToBeSearched([...(accounts || []), ...(chartOfAccounts || [])]);
  };
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const deleteObjectArray = search.filter((r) => r.id === deleteId);
    if (deleteObjectArray[0].isAccount)
      handleDeleteAccountType(deleteId, deleteObjectArray[0]?.name);
    else handleDeleteAccount(deleteId, deleteObjectArray[0].name);
  };
  const updateRecord = (e) => {
    const updateRecordArray = search.filter((r) => r.id === e.target.id);
    if (updateRecordArray[0].isAccount) {
      //chartOfAccounts
      dispatch(appUiActions.toggleEditChartOfAccountsAccountModal(true));
      dispatch(chartOfAccountsActions.setEditAccountData(updateRecordArray[0]));
    } else {
      const updateRecordArray = chartOfAccounts.filter(
        (r) => r.id === e.target.id
      );
      dispatch(
        chartOfAccountsActions.setEditAccountTypeData(updateRecordArray[0])
      );
      dispatch(appUiActions.toggleEditChartOfAccountsAccountTypeModal(true));
    }
  };
  const viewDetails = (e) => {
    const accountDetailsArray = search.filter((r) => r.id === e.target.id);
    if (!accountDetailsArray[0].isAccount) {
      handleViewAccountTypeDetails(e.target.id);
      dispatch(appUiActions.toggleChartOfAccountsAccountTypeDetailsModal(true));
    } else {
      dispatch(appUiActions.toggleChartOfAccountsAccountDetailsModal(true));
      handleViewAccountDetails(e.target.id);
    }
  };

  useEffect(() => {
    if (!metaDataLoading && chartOfAccountsSuccess) generateSearchArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartOfAccountsSuccess, metaDataLoading]);
  const columns = [
    { title: "Account", dataIndex: "name", key: "name" },
    {
      title: "Action",
      key: "action",
      render: (text) => {
        let deleteLoading = "";
        text.isAccount
          ? (deleteLoading = deleteAccountLoading)
          : (deleteLoading = deleteAccountTypeLoading);
        return (
          <Popover
            trigger="click"
            placement="bottom"
            content={() =>
              tableButtonActions(text, {
                viewDetails,
                updateRecord,
                confirmDelete,
                deleteRecord,
                deleteLoading,
                deleteId,
              })
            }
          >
            <Button>Actions</Button>
          </Popover>
        );
      },
    },
  ];
  return (
    <div>
      <Modal
        onCancel={closeModal}
        footer={null}
        zIndex={400}
        width={800}
        title="SEARCH"
        visible={visible}
      >
        <Input.Search
          onChange={onChange}
          onSearch={onSearch}
          enterButton
          placeholder="input search text...."
        />
        <div className="results w-100">
          <Table rowKey={(r) => r.id} columns={columns} dataSource={search} />
        </div>
      </Modal>
    </div>
  );
};

export default SearchChartOfAccounts;
