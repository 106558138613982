import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddAssetForm from "../../../components/administration/assetRegister/Forms/AddAssetForm";
import EditAssetForm from "../../../components/administration/assetRegister/Forms/EditAssetRegister";
import AssetsTable from "../../../components/administration/assetRegister/Tables/AssetTable";
import AssetTypesTable from "../../../components/administration/assetRegister/Tables/AssetTypesTable";
import routes from "../../../config/routes/routes";
import assetRegisterThunk from "../../../config/thunks/administration/assetsRegister/assetsRegister.thunks";
import AddAssetTypeForm from "../../../components/administration/assetRegister/Forms/AddAssetTypesForm";
import EditAssetTypeForm from "../../../components/administration/assetRegister/Forms/EditAssetTypes";
import AssetsDetails from "../../../components/administration/assetRegister/Details/AssetsDetails";
import AssetTypesDetails from "../../../components/administration/assetRegister/Details/AssetTypesDetails";
import { appUiActions } from "../../../config/actions/app/appUi.actions";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";
import AddRejections from "../../../components/shared/Rejections/AddRejections";

const Assets = () => {
  const {
    error: fetchAssetsError,
    success: fetchAssestsSuccess,
    data: assetsArray,
  } = useSelector((state) => state.assetRegisterState.fetchAssets);
  const { success: metaDataSuccess } = useSelector(
    (state) => state.assetRegisterState.metaData
  );
  const {
    error: checkAssetsError,
    success: checkAssestsSuccess,
    message: checkAssetMessage,
  } = useSelector((state) => state.assetRegisterState.checkAsset);
  const {
    error: checkAssetsTypesError,
    success: checkAssetsTypesSuccess,
    message: checkAssetTypesMessage,
  } = useSelector((state) => state.assetRegisterState.checkAssetType);
  const {
    error: approveAssetsError,
    success: approveAssetsSuccess,
    message: approveAssetMessage,
  } = useSelector((state) => state.assetRegisterState.approveAsset);
  const {
    error: approveAssetsTypesError,
    success: approveAssetsTypesSuccess,
    message: approveAssetTypesMessage,
  } = useSelector((state) => state.assetRegisterState.approveAssetType);
  const { success: addAssetSuccess } = useSelector(
    (state) => state.assetRegisterState.addAsset
  );
  const { success: addAssetTypesSuccess } = useSelector(
    (state) => state.assetRegisterState.addAssetTypes
  );
  const { success: deleteAssetSuccess, error: deleteAssetError } = useSelector(
    (state) => state.assetRegisterState.deleteAsset
  );
  const { success: deleteAssetTypesSuccess, error: deleteAssetTypesError } =
    useSelector((state) => state.assetRegisterState.deleteAssetTypes);
  const { success: editAssetSuccess } = useSelector(
    (state) => state.assetRegisterState.editAsset
  );
  const { success: editAssetTypesSuccess } = useSelector(
    (state) => state.assetRegisterState.editAssetTypes
  );
  const {
    success: fetchAssetTypeSuccess,
    error: fetchAssetTypeError,
    data: assetsTypesArray,
  } = useSelector((state) => state.assetRegisterState.fetchAssetCategory);
  const [addAssetModalVisible, setAddAssetModalModalVisble] = useState(false);
  const dispatch = useDispatch();
  /** Functions */
  //add records
  const addAsset = (values) => {
    dispatch(assetRegisterThunk.addAsset(values));
  };
  const addAssetTypes = (values) => {
    dispatch(assetRegisterThunk.addAssetCategory(values));
  };
  //delete records functions
  const deleteAsset = (id) => {
    const assets = assetsArray.filter((e) => e.id !== id);
    dispatch(assetRegisterThunk.deleteAsset(id, assets));
  };
  const deleteAssetTypes = (id) => {
    const assetTypes = assetsTypesArray.filter((e) => e.id !== id);
    dispatch(assetRegisterThunk.deleteAssetCategory(id, assetTypes));
  };
  //refresh tables
  const refreshAssetsTable = () => {
    dispatch(assetRegisterThunk.fetchAssets());
    dispatch(assetRegisterThunk.getMetaData());
    if (!fetchAssestsSuccess && fetchAssetsError)
      message.error(fetchAssetsError);
  };
  const refreshAssetTypesTable = () => {
    dispatch(assetRegisterThunk.fetchAssetRegisterCategories());
    if (!fetchAssetTypeSuccess && fetchAssetTypeError)
      message.error(fetchAssetTypeError);
  };
  //edit records
  const editAsset = (values) => {
    const filteredArray = assetsArray.filter((e) => e.id !== values.id);
    dispatch(assetRegisterThunk.updateAsset(values, values.id, filteredArray));
  };
  const editAssetTypes = (values) => {
    const filteredArray = assetsTypesArray.filter((e) => e.id !== values.id);

    dispatch(
      assetRegisterThunk.updateAssetCategory(values, values.id, filteredArray)
    );
  };
  //get asset register  details
  const assetTypesDetails = (id) => {
    dispatch(appUiActions.toggleAssetTypesDetailsModal(true));
    dispatch(assetRegisterThunk.getAssetTypesDetails(id));
  };
  const assetDetails = (id) => {
    dispatch(appUiActions.toggleAssetsDetailsModal(true));
    dispatch(assetRegisterThunk.getAssetDetails(id));
  };
  const approveAsset = (id) => dispatch(assetRegisterThunk.approveAsset(id));
  const approveAssetType = (id) =>
    dispatch(assetRegisterThunk.approveAssetType(id));
  const checkAsset = (id) => dispatch(assetRegisterThunk.checkAsset(id));
  const checkAssetType = (id) =>
    dispatch(assetRegisterThunk.checkAssetType(id));
  //export records
  const exportAssetRegister = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Asset Register",
        records: "assetRegister",
      })
    );
  };
  const exportAssetTypes = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Asset Types",
        records: "assetTypes",
      })
    );
  };
  // get all assets and asset types
  useEffect(() => {
    document.title = routes.administration.assetRegister.assets.title;
    if (!fetchAssestsSuccess) dispatch(assetRegisterThunk.fetchAssets());
    if (!fetchAssetTypeSuccess)
      dispatch(assetRegisterThunk.fetchAssetRegisterCategories());
    if (!fetchAssestsSuccess && fetchAssetTypeError)
      message.error(fetchAssetTypeError);
    if (!metaDataSuccess) dispatch(assetRegisterThunk.getMetaData());
  }, [
    fetchAssetsError,
    dispatch,
    fetchAssestsSuccess,
    fetchAssetTypeSuccess,
    fetchAssetTypeError,
    metaDataSuccess,
  ]);
  //add an asset
  useEffect(() => {
    if (addAssetSuccess)
      message.success("Asset has successfully been added to asset register");
    if (addAssetTypesSuccess)
      message.success("Asset Type has successfully been added");
  }, [addAssetSuccess, addAssetTypesSuccess]);
  //delete an asset
  useEffect(() => {
    if (deleteAssetSuccess) message.success("Asset has been deleted");
    else if (!deleteAssetSuccess && deleteAssetError)
      message.error(deleteAssetError);

    if (deleteAssetTypesSuccess) message.success("Asset Type has been deleted");
    else if (!deleteAssetTypesSuccess && deleteAssetTypesError)
      message.error(deleteAssetTypesError);
  }, [
    deleteAssetError,
    deleteAssetSuccess,
    deleteAssetTypesSuccess,
    deleteAssetTypesError,
  ]);
  //update asset
  useEffect(() => {
    if (editAssetSuccess) message.success("Asset has been updated");
    if (editAssetTypesSuccess) message.success("Asset Type has been updated");
  }, [editAssetSuccess, editAssetTypesSuccess]);
  //check Asset and asset types
  useEffect(() => {
    if (checkAssestsSuccess) message.success(checkAssetMessage);
    else if (!checkAssestsSuccess && checkAssetsError)
      message.error(checkAssetsError);

    if (checkAssetsTypesSuccess) message.success(checkAssetTypesMessage);
    else if (!checkAssetsTypesSuccess && checkAssetsTypesError)
      message.error(checkAssetsTypesError);
  }, [
    checkAssestsSuccess,
    checkAssetMessage,
    checkAssetTypesMessage,
    checkAssetsError,
    checkAssetsTypesError,
    checkAssetsTypesSuccess,
  ]);
  //approve asset and asset types
  useEffect(() => {
    if (approveAssetsSuccess) message.success(approveAssetMessage);
    else if (!approveAssetsSuccess && approveAssetsError)
      message.error(approveAssetsError);

    if (approveAssetsTypesSuccess) message.success(approveAssetTypesMessage);
    else if (!approveAssetsTypesSuccess && approveAssetsTypesError)
      message.error(approveAssetsTypesError);
  }, [
    approveAssetMessage,
    approveAssetTypesMessage,
    approveAssetsError,
    approveAssetsSuccess,
    approveAssetsTypesError,
    approveAssetsTypesSuccess,
  ]);
  return (
    <div className="container-fluid p-2">
      <h3>Asset Register</h3>
      <AssetsTable
        handleOpenAddModal={setAddAssetModalModalVisble}
        handleViewDetails={assetDetails}
        handleDeleteAsset={deleteAsset}
        handleRefreshTable={refreshAssetsTable}
        handleExportRecords={exportAssetRegister}
      />
      <AddRejections />
      <AssetTypesTable
        handleViewDetails={assetTypesDetails}
        handleDeleteAssetTypes={deleteAssetTypes}
        handleRefreshTable={refreshAssetTypesTable}
        handleExportRecords={exportAssetTypes}
      />
      <AddAssetForm
        handleCloseModal={setAddAssetModalModalVisble}
        visible={addAssetModalVisible}
        handledAddAsset={addAsset}
      />
      <EditAssetForm handleEditAsset={editAsset} />
      <AddAssetTypeForm handledAddAssetType={addAssetTypes} />
      <EditAssetTypeForm handledEditAssetType={editAssetTypes} />
      <AssetsDetails handleApprove={approveAsset} handleCheck={checkAsset} />
      <AssetTypesDetails
        handleCheck={checkAssetType}
        handleApprove={approveAssetType}
      />
    </div>
  );
};

export default Assets;
