import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const AddReceiptForm = ({ handleAddRecord }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //use selector to get state from redux state
  const { error, success, loading } = useSelector(
    (state) => state.storesRegisterState.addStoresRegisterReceipt
  );
  const { loading: metaDataLoading, data: metaData } = useSelector(
    (state) => state.assetRegisterState.metaData
  );

  const visible = useSelector(
    (state) =>
      state.AppUi.admisnistration.storesRegister.addStoresRegisterReceiptModal
  );
  //submit form
  const onFinish = (values) => {
    values.createdAt = new Date();
    handleAddRecord(values);
  };
  //close modal
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleAddStoresRegisterReceiptModal(false));
  };
  //use effect
  useEffect(() => {
    if (success) form.resetFields();
  }, [form, success]);

  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        visible={visible}
        title="ADD NEW ITEM"
        footer={null}
        width={800}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!success && error && (
            <Alert className="m-2" showIcon type="error" message={error} />
          )}
          <div className="d-md-flex">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="name"
                label="Item Name"
                rules={[{ required: true, message: "Please add a first name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="source_of_funds"
                label="Source Of Funds"
                rules={[
                  { required: true, message: "Please add a source funds" },
                ]}
              >
                <Select
                  showSearch
                  loading={metaDataLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {metaData?.donors?.map((donors) => (
                    <Select.Option key={donors.id} value={donors.id}>
                      {donors.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Project"
                name="project"
                rules={[{ required: true, message: "Please add a project" }]}
              >
                <Select
                  showSearch
                  loading={metaDataLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {metaData?.projects?.map((project) => (
                    <Select.Option key={project.id} value={project.id}>
                      {project.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Issue Date"
                name="issue_date"
                rules={[
                  {
                    required: true,
                    message: "Please  add an issue date",
                  },
                ]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
            </div>
            <div className="w-50 w-sm-100">
              <Form.Item
                label="Rate"
                name="rate"
                rules={[{ required: true, message: "Please add a rate" }]}
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Please add a quantity" }]}
                name="quantity"
                label="Quantity"
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Please add remarks" }]}
                name="remarks"
                label={"Remarks"}
              >
                <TextArea />
              </Form.Item>
            </div>
          </div>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              className="w-25"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddReceiptForm;
