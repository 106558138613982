import { Button, message, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { projectActions } from "../../../../config/actions/businesDevAndPartnerships/projects/projects.actions";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";
import routes from "../../../../config/routes/routes";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";

const ProjectsTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
}) => {
  //state,variable, decalrations and initialization
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    deleteProjectsLoading: deleteLoading,
    deleteProjectsSuccess: deleteSuccess,
    deleteProjectError: deleteError,
    deleteProjectsMessage: deleteMessage,
    projects: data,
    projectsLoading: loading,
    projectsSuccess: success,
    projectsError: error,
  } = useSelector((state) => state.projectsState);
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const columns = [
    {
      title: "Project",
      key: "projects",
      render: (text) => (
        <div>
          <span className="d-md-none">Project:</span> {text.name} <br />
          <div className="d-md-none">
            Approved Budget: {numberWithCommas(parseInt(text.approved_budget))}{" "}
            <br />
            Donor: {text.donorName} <br />
            Person Incharge: {text.personInchargeName} <br />
            Project Supervisor: {text.supervisorName} <br />
            Start Date: {`${new Date(text.start_date).toDateString()}`} <br />
            End Date: {`${new Date(text.end_date).toDateString()}`} <br />
            Bank: {text.bank} <br />
            Account Number: {text.account_number} <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => `${new Date(text).toDateString()}`,
      responsive: ["md"],
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (text) => `${new Date(text).toDateString()}`,
      responsive: ["md"],
    },
    {
      title: "Person Incharge",
      dataIndex: "personInchargeName",
      key: "personInchargeName",
      responsive: ["lg"],
    },
    {
      title: "Approved Budget",
      dataIndex: "approved_budget",
      key: "approved_budget",
      responsive: ["lg"],
      render: (text) => {
        try {
          let currency = text[text?.length - 1];
          return (
            <>{`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
              parseInt(text)
            )}`}</>
          );
        } catch (error) {
          return "";
        }
      },
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["lg"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  /** functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const deleteObjectArray = data.filter((r) => r.id === deleteId);
    const donorsArray = data.filter((e) => e.id !== deleteId);
    handleDelete(deleteId, deleteObjectArray[0]?.name, donorsArray);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const viewDetails = (e) => {
    history.push(
      `${routes.businesDevelopmentAndPartnerships.projects.path}/${e.target.id}`
    );
  };
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(projectActions.editProjectData(updateRecordArray[0]));
    dispatch(appUiActions.toggleEditProjectsModal(true));
  };
  const openAddModal = () =>
    dispatch(appUiActions.toggleAddProjectsModal(true));
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = [
      "Projects",
      "Approved Budget",
      "Start Date",
      "End Date",
      "Bank",
      "Person Incharge",
      "Status",
    ];
    let pdfRows = [];
    data?.forEach((record) => {
      let currency =
        record?.approved_budget[record?.approved_budget?.length - 1];
      const row = [
        record.name,
        `${currency === "D" ? "USD" : "UGX"} ${parseInt(
          record?.approved_budget
        )}`,
        new Date(record.start_date).toDateString(),
        new Date(record.end_date).toDateString(),
        record.bank,
        record.personInchargeName,
        record.status,
      ];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Intiative Projects - ${new Date().toLocaleDateString()}`,
      "Amani Intiatice Projects.pdf"
    );
  };
  /** use Effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "projects") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);

  return (
    <div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default ProjectsTable;
