import {
  Modal,
  Form,
  Button,
  Alert,
  message,
  Input,
  InputNumber,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";

const { Option, OptGroup } = Select;

const AddBudgetActivity = ({
  visible,
  handleCloseModal,
  handleAddActivity,
}) => {
  const {
    addBudgetActivitySuccess,
    addBudgetActitvityError,
    addBudgetactivityLoading,
    addBudgetActivityMessage,
    budgetDetails,
    budgetLineDetails,
    metaData,
    metaDataLoading,
  } = useSelector((state) => state.budgetState);
  const [form] = Form.useForm();
  const currency = getCurrency(budgetDetails, "approved_budget");
  //fucntions
  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    values.budget = budgetDetails.id;
    values.budget_line = budgetLineDetails.id;
    values.total_cost = values.unit_cost * values.units;
    handleAddActivity(values);
  };
  /* uese effect */
  useEffect(() => {
    if (addBudgetActivitySuccess && addBudgetActivityMessage) {
      message.success(addBudgetActivityMessage);
      form.resetFields();
    }
  }, [addBudgetActivityMessage, addBudgetActivitySuccess, form]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        footer={null}
        title="ADD BUDGET COST DRIVER"
        visible={visible}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!addBudgetActivitySuccess && addBudgetActitvityError && (
            <Alert
              className="m-2"
              showIcon
              type="error"
              message={addBudgetActitvityError}
            />
          )}
          <Form.Item
            rules={[
              { required: true, message: "Please add a budget activitity" },
            ]}
            label="Cost Driver"
            name="name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Account"
            name={"chart_of_accounts_account"}
            rules={[{ required: true, message: "Chart Of Accounts " }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={metaDataLoading}
              className="w-100"
              allowClear
              placeholder="Chart Of Accounts Account"
            >
              {metaData?.chartOfAccountsAccounts?.map((accountType) => {
                return (
                  <OptGroup
                    label={accountType.name}
                    key={`${accountType.name} - ${accountType.account_number}`}
                  >
                    {accountType.accounts?.map((account) => {
                      const shortenedName = account.name?.slice(0, 30) + "...";
                      return (
                        <Option
                          key={`${account.name} - ${account.account_number}`}
                          value={account.id}
                        >
                          {account.name?.length > 30
                            ? `${shortenedName} - ${account.account_number}`
                            : `${account.name} - ${account.account_number}`}
                        </Option>
                      );
                    })}
                  </OptGroup>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Units"
            name={"units"}
            fieldKey={"units"}
            hasFeedback
            rules={[{ required: true, message: "Please add Units" }]}
          >
            <InputNumber precision={0} className="w-100" placeholder="Units" />
          </Form.Item>
          <Form.Item
            label="Unit Cost"
            name={"unit_cost"}
            fieldKey={"unit_cost"}
            hasFeedback
            rules={[{ required: true, message: "Please add a Unit Cost" }]}
          >
            <InputNumber
              precision={0}
              className="w-100"
              placeholder="Unit Cost"
            />
          </Form.Item>
          <Form.Item label="Total" shouldUpdate>
            {({ getFieldValue }) => {
              const unitCost = getFieldValue("unit_cost");
              const units = getFieldValue("units");
              const total_cost = parseInt(unitCost) * parseInt(units);
              return (
                <Input
                  readOnly
                  value={
                    !isNaN(total_cost)
                      ? `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
                          total_cost
                        )}`
                      : 0
                  }
                ></Input>
              );
            }}
          </Form.Item>
          <Form.Item
            label="Budget Notes"
            name={"budget_notes"}
            fieldKey={"budget_notes"}
          >
            <Input.TextArea placeholder="Budget Notes" />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={addBudgetactivityLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddBudgetActivity;
