import apiConfig from "../../../config.json";
import { getAuthToken } from "../../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;

const staffRecordsApiRequests = {
  fetchStaffRecords: async () => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/staffRecords`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  addStaffRecords: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/staffRecords`,
        {
          method: "POST",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  updateStaffRecord: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/staffRecords/${body.id}`,
        {
          method: "PUT",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  deleteStaffRecord: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/staffRecords/${id}`,
        {
          method: "DELETE",
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  fetchStaffDetails: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/staffRecords/${id}`,
        {
          headers: { authorization },
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  activateAccount: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/staffRecords/acitvateAccount/${id}`,
        {
          method: "PATCH",
          headers: { authorization, "content-type": "application/json" },
          body: JSON.stringify({ activationTime: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  deactivateAccount: async (id) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/staffRecords/deacitvateAccount/${id}`,
        {
          method: "PATCH",
          headers: { authorization, "content-type": "application/json" },
          body: JSON.stringify({ deactivationTime: new Date() }),
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  updateProfilePicture: async (id, body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/administration/staffRecords/profilePicture/${id}`,
        {
          method: "PATCH",
          headers: { authorization },
          body,
        }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  generateNewConfirmToken: async (body) => {
    try {
      const res = await fetch(
        `${apiConfig.server_api_url}/users/auth/generateNewConfirmToken`,
        {
          method: "POST",
          headers: {
            authorization,
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: body }),
        }
      );
      return res.json();
    } catch (error) {
      console.error("Generate new toke request error", error);
      return { success: false, error: "Sorry there seems to be an error" };
    }
  },
};

export default staffRecordsApiRequests;
