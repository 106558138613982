import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import projectThunks from "../../config/thunks/businessDevAndPartnerships/projects/projects.thunks";
import ProjectsActualExpenditureGraph from "../../components/Home/ProjectsActualExpenditureGraph";
import ProjectsPieChart from "../../components/Home/ProjectPieChart";
import { Alert, Spin } from "antd";
import dashboardThunks from "../../config/thunks/dashboard/dashboard.thunks";
import DashBoardListItems from "../../components/Home/dashboard/DashBoardListItems";
import PendingStoresRegisterIssues from "../../components/Home/dashboard/PendingStoresRegisterIssues";
import StaffContacts from "../../components/Home/dashboard/StaffContacts";

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    projectsGraphDataLoading,
    projectsGraphDataError,
    projectsGraphDataSuccess,
  } = useSelector((state) => state.projectsState);
  const {
    dashboardDataLoading,
    dashboardDataSuccess,
    dashboardDataError,
    dashboardData,
  } = useSelector((state) => state.dashboardState);
  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);

  useEffect(() => {
    document.title = "DASHBOARD";
    if (!projectsGraphDataSuccess)
      dispatch(projectThunks.getProjectsGraphData());
    if (!dashboardDataSuccess) dispatch(dashboardThunks.getDashBoardData());
  }, [dispatch, projectsGraphDataSuccess, dashboardDataSuccess]);

  return (
    <div className="container-fluid">
      <h3 className="my-2">DashBoard</h3>
      {dashboardDataLoading ? (
        <div className="d-flex justify-content-center m-5">
          <Spin size="large" />
        </div>
      ) : (
        <div className="d-flex">
          {dashboardDataSuccess ? (
            <div className="w-100">
              <div>
                {projectsGraphDataLoading ? (
                  <div className="d-flex justify-content-center m-5">
                    <Spin size="large" />
                  </div>
                ) : (
                  <div>
                    {projectsGraphDataSuccess ? (
                      <div className="d-lg-flex w-100 bg-white">
                        <ProjectsActualExpenditureGraph />
                        <ProjectsPieChart />
                      </div>
                    ) : (
                      <Alert
                        showIcon
                        type="error"
                        message={projectsGraphDataError}
                      />
                    )}
                  </div>
                )}
                <div className="w-100 my-2">
                  <DashBoardListItems data={dashboardData} />
                </div>
                {viewPermissions?.Stores_Register && (
                  <PendingStoresRegisterIssues data={dashboardData} />
                )}
                <StaffContacts data={dashboardData} />
              </div>
            </div>
          ) : (
            <Alert showIcon message={dashboardDataError} type="error" />
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
