import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import routes from "../../../config/routes/routes";

const DashBoardListItems = ({ data }) => {
  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);
  return (
    <div className="row mx-1">
      {viewPermissions?.Donors && (
        <ul class="list-group col-sm-6  p-0 shadow">
          <Link to={routes.businesDevelopmentAndPartnerships.donors.path}>
            <li class="list-group-item">
              <h5>BUSINESS GROWTH AND PARTNERSHIP STATUS</h5>
            </li>
            <li class="list-group-item">
              <div class="ms-2 me-auto">
                <h4 class="text-primary">{data.donors?.totalDonors}</h4>
                Project/Donors oppotunities identified
              </div>
            </li>
            <li class="list-group-item">
              <div class="ms-2 me-auto">
                <h4 class="text-warning">
                  {data.donors?.proposalStatusPending}
                </h4>
                Project/Donors opportunities submitted
              </div>
            </li>
          </Link>
          <Link to={routes.businesDevelopmentAndPartnerships.projects.path}>
            <li class="list-group-item">
              <div class="ms-2 me-auto">
                <h4 class="text-success">{data.projects?.approvedProjects}</h4>
                Project/Donors opportunities approved
              </div>
            </li>
          </Link>
        </ul>
      )}
      {viewPermissions?.Requisition_memo && (
        <ul class="list-group col-sm-6 p-0 shadow">
          <li class="list-group-item">
            <h5>FINANCE STATUS</h5>
          </li>
          <Link to={routes.finance.requistionMemos.path}>
            <li class="list-group-item">
              <div class="ms-2 me-auto">
                <h4 class="text-primary">
                  {data.requisitionMemos?.totalRequisitionMemos}
                </h4>
                Requisition Forms Prepared
              </div>
            </li>
          </Link>
          <Link to={routes.finance.paymentVoucher.path}>
            <li class="list-group-item">
              <div class="ms-2 me-auto">
                <h4 class="text-warning">
                  {data.paymentVouchers?.totalPaymentVouchers}
                </h4>
                Funds Advance/Withdraw Forms prepared
              </div>
            </li>
          </Link>
          <Link to={routes.finance.advanceAccountabilityForm.path}>
            <li class="list-group-item">
              <div class="ms-2 me-auto">
                <h4 class="text-warning">
                  {
                    data.advanceAccountabilityForms
                      ?.totalAdvanceAccountabilityForms
                  }
                </h4>
                Payment Vouchers Prepared
              </div>
            </li>
          </Link>
        </ul>
      )}
    </div>
  );
};

export default DashBoardListItems;
